'use client';

import type { ReactNode } from 'react';
import { LuInfo } from 'react-icons/lu';

import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/ui/popover';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../shadcn/ui/tooltip';
import { cn } from 'utils/shadcn';

interface InfoTooltipProps {
  text: ReactNode;
  iconSize?: number;
  side?: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
  children?: ReactNode;
  iconClassName?: string;
  iconColor?: string;
  disableHoverableContent?: boolean;
  delayDuration?: number;
}

export const InfoTooltip = ({
  children,
  text,
  iconSize = 20,
  iconColor = 'text-muted hover:text-muted-foreground',
  side = 'top',
  className = '',
  iconClassName = '',
  disableHoverableContent,
  delayDuration,
}: InfoTooltipProps) => {
  const iconStyle = children
    ? {}
    : {
        display: 'inline-block',
        width: iconSize,
        height: iconSize,
      };

  const triggerButton = children ?? (
    <div>
      <LuInfo className={cn('cursor-pointer text-lg', iconClassName, iconColor)} size={iconSize} />
    </div>
  );

  return (
    <>
      <div className="contents md:hidden">
        <Popover>
          <PopoverTrigger style={iconStyle} asChild>
            {triggerButton}
          </PopoverTrigger>
          <PopoverContent className={cn('text-foreground bg-card text-sm', className)}>{text}</PopoverContent>
        </Popover>
      </div>
      <div className="hidden md:contents">
        <TooltipProvider disableHoverableContent={disableHoverableContent} delayDuration={delayDuration}>
          <Tooltip delayDuration={50}>
            <TooltipTrigger asChild style={iconStyle}>
              {triggerButton}
            </TooltipTrigger>

            <TooltipContent className={cn('max-w-[200px] h-auto', className)} side={side}>
              {text}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </>
  );
};
