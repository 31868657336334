import { Text } from 'components/core/Text';
import { Button as ButtonNew } from 'components/shadcn/ui/button';
import { Label } from 'components/shadcn/ui/label';
import { Textarea } from 'components/shadcn/ui/textarea';
import { DERIVED_KEY_PURPOSES } from 'constants/DerivedKeysConstants';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { LuXCircle } from 'react-icons/lu';
import { openfund } from 'services';
import { useToast } from '../../hooks/useToast';
import { ProposalPostData } from '../../services/Openfund';
import { Input } from '../core/Input';

function getDefaultProposalSettings(
  ProjectPubkeyBase58check: string,
  ProposerPubkeyBase58check: string,
): ProposalPostData {
  return {
    DaoPubkeyBase58check: ProjectPubkeyBase58check,
    ProposerPubkeyBase58check,
    DerivedPublicKeyBase58Check: '',
    PollOptions: [],
    PollDescription: '',
    PollTitle: '',
  };
}

interface ActivityFeedCreateProposalProps {
  projectOwnerPkid: string;
  projectOwnerUsername: string;
  className: string;
  hideCreateProposals: Function;
  getAllProposals: Function;
}

export function ActivityFeedCreateProposal({
  projectOwnerPkid,
  projectOwnerUsername,
  className,
  hideCreateProposals,
  getAllProposals,
}: ActivityFeedCreateProposalProps) {
  const [newProposalOption, setNewProposalOption] = useState('');
  const toast = useToast();
  const { currentUser } = useContext(OpenfundContext);
  const [formModel, setFormModel] = useState<ProposalPostData>(
    getDefaultProposalSettings(projectOwnerPkid, currentUser?.PublicKeyBase58Check || ''),
  );
  const derivedKey = useRef('');

  function addOptionToPoll(): void {
    const pollOptions = formModel.PollOptions;
    pollOptions.push(newProposalOption);
    setFormModel({ ...formModel, PollOptions: pollOptions });
    setNewProposalOption('');
  }

  function resetNewProposalOption(): void {
    setNewProposalOption('');
  }

  function removeProposalOption(index: number): void {
    const pollOptions = formModel.PollOptions;
    pollOptions.splice(index, 1);
    setFormModel({ ...formModel, PollOptions: pollOptions });
  }

  useEffect(() => {
    if (currentUser) {
      openfund
        .createDerivedKey(currentUser.PublicKeyBase58Check, DERIVED_KEY_PURPOSES.SOCIAL)
        .then(({ DerivedPublicKeyBase58Check }) => {
          derivedKey.current = DerivedPublicKeyBase58Check;
        });
    }
  }, [currentUser]);

  return (
    <div className={'p-8 border border-border-light rounded-2xl m-4' + className}>
      <form
        id="submit-proposal-form"
        className="flex flex-col gap-4"
        onSubmit={async (ev) => {
          ev.preventDefault();
          const pollOptions = formModel.PollOptions;
          if (newProposalOption !== '') {
            pollOptions.push(newProposalOption);
          }
          openfund.createProposal({ ...formModel, PollOptions: pollOptions }, derivedKey.current).then(() => {
            toast.show({
              message: 'Proposal created successfully!',
              type: 'success',
              sticky: false,
            });
            getAllProposals();
            setFormModel(getDefaultProposalSettings(projectOwnerPkid, currentUser?.PublicKeyBase58Check || ''));
            hideCreateProposals();
          });
        }}
      >
        <div>
          <Label className="mb-2">Create a proposal for ${projectOwnerUsername}</Label>
          <Input
            labelText=""
            placeholder="Give your proposal a title"
            containerClasses="mb-4"
            className="w-full"
            required={false}
            value={formModel.PollTitle}
            onChange={(ev) => {
              setFormModel({ ...formModel, PollTitle: ev.currentTarget.value });
            }}
          />
          <Textarea
            name="longDesc"
            className="w-full"
            placeholder="Describe your proposal"
            minHeight="160px"
            maxHeight="500px"
            onChange={(ev) => {
              const value = ev.currentTarget.value;
              setFormModel({ ...formModel, PollDescription: value });
            }}
            value={formModel.PollDescription}
          />
        </div>
        <div className="w-full">
          <Label className="mb-2">Add Poll Options</Label>
          {formModel.PollOptions.map((pollOption, i) => (
            <div className="flex w-full items-center gap-4">
              <Text className="pr-3">{i + 1}.</Text>
              <Input
                labelText=""
                placeholder={`Option ${i + 1}`}
                containerClasses=" w-full"
                className="w-full"
                required={false}
                value={pollOption}
                onChange={(ev) => {
                  const newPollOption = ev.currentTarget.value;
                  const pollOptions = formModel.PollOptions;
                  pollOptions[i] = newPollOption;
                  setFormModel({ ...formModel, PollOptions: pollOptions });
                }}
              />

              <LuXCircle
                onClick={() => removeProposalOption(i)}
                className="text-4xl text-muted hover:text-muted-foreground cursor-pointer text-muted"
              />
            </div>
          ))}
          <div className="flex w-full items-center gap-4">
            <Text className="pr-3">{formModel.PollOptions.length + 1}.</Text>
            <Input
              labelText=""
              placeholder={`Option ${formModel.PollOptions.length + 1}`}
              containerClasses="w-full"
              className="w-full"
              required={false}
              value={newProposalOption}
              onChange={(ev) => {
                const newPollOption = ev.currentTarget.value;
                setNewProposalOption(newPollOption);
              }}
            />
            <LuXCircle
              onClick={() => removeProposalOption(formModel.PollOptions.length)}
              className="text-4xl text-muted hover:text-muted-foreground cursor-pointer text-muted"
            />
          </div>
          <div className="flex justify-end">
            <ButtonNew variant="ghost" onClick={addOptionToPoll}>
              + Add another option
            </ButtonNew>
          </div>
          <div className="flex items-center gap-4">
            <ButtonNew type="submit" variant="default">
              Submit Proposal
            </ButtonNew>
            <ButtonNew variant="outline" onClick={() => hideCreateProposals()}>
              Cancel
            </ButtonNew>
          </div>
        </div>
      </form>
    </div>
  );
}
