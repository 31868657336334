import { IS_TESTNET } from '../constants/AppConstants';
import { useLazyQuery } from '@apollo/client';
import { TransactionByTransactionHashDocument } from '../graphql/codegen/graphql';
import { client } from '../graphql/client';

export const useWaitForTransaction = () => {
  const [fetchTransactionLazy] = useLazyQuery(TransactionByTransactionHashDocument, {
    client,
  });

  // Wait at most 10 seconds, polling every 0.05 seconds before refreshing order book or until we see the transaction has hit the state syncer.
  const waitForTxn = async (transactionHash: string, maxAttempts: number = 200): Promise<void> => {
    if (IS_TESTNET) {
      return Promise.resolve();
    }
    let attempts = 0;
    const pollInterval = 50; // Milliseconds between each poll

    while (attempts < maxAttempts) {
      const res = await fetchTransactionLazy({
        variables: {
          transactionHash,
        },
        fetchPolicy: 'network-only',
      });

      if (res.data?.transactionByTransactionHash) {
        return Promise.resolve();
      }

      await new Promise((resolve) => setTimeout(resolve, pollInterval));
      attempts++;
    }

    // If the transaction is not confirmed after maxAttempts, you can handle it here
    console.error('Transaction not confirmed after maximum attempts.');
    return Promise.resolve();
  };

  return {
    waitForTxn,
  };
};
