import { FeaturedImage } from 'components/app-ui/FeaturedImage';
import { FollowButton } from 'components/app-ui/FollowButton';
import { FullPageError } from 'components/app-ui/FullPageError';
import { NotFound } from 'components/app-ui/NotFound';
import { Avatar } from 'components/core/Avatar';
import { Button } from 'components/shadcn/ui/button';
import { DiamondLogo } from 'components/core/DiamondLogo';
import { ExternalLink } from 'components/core/ExternalLink';
import { Spinner } from 'components/core/Spinner';
import { Text } from 'components/core/Text';
import { BalanceEntryResponse, getExchangeRates, HodlersSortType, ProfileEntryResponse } from 'deso-protocol';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useIsMounted } from 'hooks/useIsMounted';
import { useToast } from 'components/hooks/use-toast';
import { useEffect, useState } from 'react';
import { IoCopyOutline } from 'react-icons/io5';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { deso, openfund } from 'services';
import { getErrorMsg } from 'utils/getErrorMsg';
import { centerEllipsis } from 'utils/text';
import { GetExchangeRateUpdatedResponse } from '../../services/Deso';
import { baseUnitsToTokens, formatDecimalValue } from '../../utils/currency';
import { getWrappedAsset, getWrappedAssetIcon, isDesoPublicKey } from '../../utils/deso';
import { sortHoldersByTokenValue } from '../../utils/holders';
import { DEFAULT_FOLLOW_MODAL_STATE, FollowModal, FollowModalState } from '../app-ui/FollowModal';
import { ProfileActivityFeed } from '../app-ui/ProfileActivityFeed';
import { LuActivity, LuExternalLink, LuUser2, LuUsers2, LuWallet } from 'react-icons/lu';
import { DESO_EXPLORER_URL, FOCUS_URL } from 'constants/AppConstants';
import { NakedPublicKeyProfile } from '../app-ui/NakedPublicKeyProfile';
import { getMarkdownBody } from 'utils/markdown';
import { AccountStats } from 'components/app-ui/AccountStats';
import { useAccountDetails } from 'hooks/useAccountDetails';
import { FOCUS_TOKEN_USERNAME } from 'utils/constants';

export function GetTokensValue(balanceEntry: BalanceEntryResponse): number {
  return (
    Number(balanceEntry.BalanceNanosUint256.toString()) *
    (balanceEntry.ProfileEntryResponse as any).BestExchangeRateDESOPerDAOCoin
  );
}

export function ProfileDetail() {
  const { username = '' } = useParams();

  // We match wrapped asset by both names like dBTC_ or BTC
  // If the route is dBTC_ we redict user to the formatted route 'BTC'
  // The same is applied for the rest of wrapped assets
  const wrappedAsset =
    getWrappedAsset(username, 'heroswapName') ||
    getWrappedAsset(username, 'name') ||
    getWrappedAsset(username, 'displayName');
  const isWrapped = !!wrappedAsset;

  useDocumentTitle(`Profile ${username}`);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<any>();
  const [profile, setProfile] = useState<ProfileEntryResponse | null>(null);
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = location.search;
  const [exchangeRates, setExchangeRates] = useState<GetExchangeRateUpdatedResponse>();
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [projectHolders, setProjectHolders] = useState<BalanceEntryResponse[]>([]);
  const [projectHoldings, setProjectHoldings] = useState<BalanceEntryResponse[]>([]);
  const [followModalState, setFollowModalState] = useState<FollowModalState>(DEFAULT_FOLLOW_MODAL_STATE);
  const isMounted = useIsMounted();
  const [isBlacklisted, setIsBlacklisted] = useState(false);

  const {
    account,
    accountFirstTransaction,
    loading: loadingAccount,
  } = useAccountDetails(profile?.PublicKeyBase58Check);

  const getHoldingsForPublicKey = (publicKey: string) => {
    return deso.getProjectHoldingsByPublicKey(publicKey, HodlersSortType.wealth, 0, true).then((holdingsRes) => {
      const hodlers = sortHoldersByTokenValue(holdingsRes.Hodlers);
      setProjectHoldings(hodlers);
    });
  };

  useEffect(() => {
    if (username) {
      if (wrappedAsset) {
        // Replace the route to the formatted one, like BTC, ETH etc.
        navigate(`/profile/${wrappedAsset.displayName}${queryParams}`, { replace: true });
      }

      const profileName = wrappedAsset?.name || username;

      setIsLoading(true);

      Promise.all([
        deso.getProfileByUsername(profileName),
        getExchangeRates().then((exchangeRatesRes) => {
          setExchangeRates(exchangeRatesRes);
        }),
      ])
        .then(([profileRes]) => {
          if (isMounted.current) {
            if (!profileRes.Profile && !isDesoPublicKey(username)) {
              setLoadingError(new Error('Profile not found'));
              setIsLoading(false);
              return;
            } else if (isDesoPublicKey(username)) {
              getHoldingsForPublicKey(username);
              return;
            }

            setProfile(profileRes.Profile);
            setIsBlacklisted(profileRes.IsBlacklisted);

            return Promise.all([
              deso.getProjectHoldings(profileName, HodlersSortType.wealth, 0, true).then((holdingsRes) => {
                const hodlers = sortHoldersByTokenValue(holdingsRes.Hodlers);
                setProjectHoldings(hodlers);
              }),
              profileRes.Profile?.PublicKeyBase58Check
                ? deso.getProjectHolders(profileRes.Profile?.PublicKeyBase58Check).then((holdersRes) => {
                    if (isMounted.current) {
                      setProjectHolders(holdersRes.Hodlers || []);
                    }
                  })
                : Promise.resolve(),
              deso.getFollowerCountByUsername(profileName).then((followerCountRes) => {
                if (isMounted.current) {
                  setFollowerCount(followerCountRes);
                }
              }),
              deso.getFollowingCountByUsername(profileName).then((followingCountRes) => {
                if (isMounted.current) {
                  setFollowingCount(followingCountRes);
                }
              }),
              openfund.getFundingRoundsByUsername(profileName, true).then((fundingRoundsRes) => {
                if (fundingRoundsRes.length > 0 && !wrappedAsset) {
                  navigate(`/d/${username}`, { replace: true });
                  return;
                }
              }),
            ]);
          }
        })
        .catch((e) => {
          if (isMounted.current && e?.status !== 404) {
            setLoadingError(e);
            throw e;
          }

          if (isDesoPublicKey(username)) {
            getHoldingsForPublicKey(username);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setIsLoading(false);
          }
        });
    }
  }, [username, isMounted, wrappedAsset, navigate]);

  if (isLoading || loadingAccount) {
    return (
      <div className="text-center py-8">
        <Spinner />
      </div>
    );
  }

  if (loadingError) {
    return loadingError.response?.status === 404 ? <NotFound /> : <FullPageError error={loadingError} />;
  }

  if (isBlacklisted) {
    return <NotFound />;
  }

  if (!profile?.Username) {
    return <NakedPublicKeyProfile publicKey={username} holdings={projectHoldings} exchangeRates={exchangeRates!} />;
  }

  const avatarUrl = wrappedAsset ? getWrappedAssetIcon(wrappedAsset) : deso.profilePicUrl(profile.PublicKeyBase58Check);

  const totalTokenSupply = baseUnitsToTokens((profile as any).DAOCoinEntry.CoinsInCirculationNanos.toString());
  const displayName = wrappedAsset?.displayName || profile.ExtraData?.DisplayName || profile.Username;
  const userName = profile.Username;

  return (
    <div className="flex mt-6 md:max-w-[720px] flex-col md:mx-auto items-start md:w-full px-6 md:px-0">
      <aside className="w-full">
        <div>
          <div className="border rounded-2xl">
            <div className="overflow-y-hidden h-[200px]">
              <FeaturedImage
                src={profile.ExtraData?.FeaturedImageURL}
                style={{ border: 'none', borderRadius: '1rem 1rem 0 0' }}
              />
            </div>
            <div className="mb-2 border-t border-t-gray-333 p-4">
              <div className="flex items-center">
                <Avatar
                  src={avatarUrl}
                  className="border-2 border-background -mt-12 z-10 bg-background p-1 rounded-full min-w-24 min-h-24"
                />
                <div className="flex items-center justify-end w-full gap-2">
                  <div>
                    <FollowButton
                      kind="btn-secondary"
                      size="sm"
                      shape="rounded"
                      followeePublicKey={profile.PublicKeyBase58Check}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="p-6 pt-0 pb-4">
              <div className="m-0 flex flex-col sm:flex-row sm:items-center sm:gap-2 mb-4 sm:mb-0">
                <Text className="text-lg font-bold text-muted-foreground flex items-center">
                  {displayName}
                  {isWrapped && (
                    <span className="ml-2 h-[22px]">
                      <img src="/images/icon-verified.gif" alt="Verified" className="relative top-[1px] w-5 h-5" />
                      <Tooltip id="verified-tooltip" opacity={1}>
                        <div className="font-normal text-sm">Verified Token</div>
                      </Tooltip>
                    </span>
                  )}
                </Text>
                <Text tag="span" className="text-sm text-muted">
                  @{userName}
                </Text>
              </div>
              <div className="flex gap-4">
                <div>
                  <Button
                    size="sm"
                    variant="ghost"
                    aria-label={`${profile.PublicKeyBase58Check}'s followers`}
                    title={`${profile.PublicKeyBase58Check}'s followers`}
                    className="p-0"
                    onClick={() => {
                      setFollowModalState({
                        isOpen: true,
                        followState: 'FOLLOWERS',
                      });
                    }}
                  >
                    <LuUser2 className="inline mr-2 text-lg" />
                    <div className="flex items-center gap-2">
                      <span className="font-semibold text-muted-foreground">
                        {followerCount.toLocaleString('en-US')}
                      </span>
                      <span className="text-muted">Follower{followerCount !== 1 && 's'}</span>
                    </div>
                  </Button>
                </div>
                <div>
                  <Button
                    size="sm"
                    variant="ghost"
                    aria-label={`${profile.PublicKeyBase58Check}'s following`}
                    title={`${profile.PublicKeyBase58Check}'s following`}
                    className="p-0"
                    onClick={() => {
                      setFollowModalState({
                        isOpen: true,
                        followState: 'FOLLOWING',
                      });
                    }}
                  >
                    <LuUsers2 className="inline mr-2 text-xl" />
                    <div className="flex items-center gap-2">
                      <span className="font-semibold text-muted-foreground">
                        {followingCount.toLocaleString('en-US')}
                      </span>
                      <span className="text-muted">Following</span>
                    </div>
                  </Button>
                </div>
              </div>

              <div className="block break-words text-sm mt-4">{getMarkdownBody(profile.Description ?? '')}</div>
            </div>
            <div className="flex flex-wrap items-center gap-4 border-t p-6 py-4 mt-2">
              <Button variant="outline" size="xs">
                <ExternalLink
                  target="_blank"
                  kind="text-only"
                  size="sm"
                  href={`${DESO_EXPLORER_URL}/u/${profile.Username}`}
                >
                  <LuActivity className="inline mr-2 text-foreground" />
                  <span className="text-foreground">Explorer</span>
                </ExternalLink>
              </Button>
              <Button variant="outline" size="xs">
                <ExternalLink
                  target="_blank"
                  kind="text-only"
                  size="sm"
                  href={`${DESO_EXPLORER_URL}/u/${profile.Username}`}
                >
                  <LuWallet className="inline mr-2 text-foreground" />
                  <span className="text-foreground">Wallet</span>
                </ExternalLink>
              </Button>
              <Button variant="outline" size="xs">
                <ExternalLink
                  target="_blank"
                  kind="text-only"
                  size="sm"
                  href={`https://diamondapp.com/u/${profile.Username}`}
                >
                  <DiamondLogo className="inline mr-2 text-foreground" />
                  <span className="text-foreground">Diamond</span>
                </ExternalLink>
              </Button>
              <Button variant="outline" size="xs">
                <ExternalLink target="_blank" kind="text-only" size="sm" href={`${FOCUS_URL}/${profile.Username}`}>
                  <LuExternalLink className="inline mr-2 text-foreground" />
                  <span className="text-foreground">{FOCUS_TOKEN_USERNAME}</span>
                </ExternalLink>
              </Button>
            </div>

            {account && <AccountStats account={account} accountFirstTransaction={accountFirstTransaction} />}

            <div className="border-border border-t justify-between flex flex-col sm:flex-row items-center pt-4 sm:pt-0 px-6">
              <div className="flex items-center">
                <Button
                  size="sm"
                  aria-label={`Copy public key ${profile.PublicKeyBase58Check}`}
                  title={profile.PublicKeyBase58Check}
                  className="p-0 font-mono text-xs h-auto"
                  variant="ghost"
                  onClick={() => {
                    window.navigator.clipboard
                      .writeText(profile.PublicKeyBase58Check)
                      .then(() => {
                        toast.toast({ description: `Copied public key to clipboard.`, variant: 'success' });
                      })
                      .catch((e) => {
                        toast.toast({ description: getErrorMsg(e), variant: 'error' });
                      });
                  }}
                >
                  {centerEllipsis(profile.PublicKeyBase58Check, 12)}
                  <IoCopyOutline className="inline ml-1" />
                </Button>
              </div>

              <div className="text-sm hover:text-blue">
                <ExternalLink
                  href={`${DESO_EXPLORER_URL}/txn?transactors=${profile.PublicKeyBase58Check}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-1 justify-center ml-auto no-underline"
                >
                  <Button variant="ghost" className="text-xs px-0">
                    Transaction History
                  </Button>
                  <LuExternalLink className="text-base text-muted" />
                </ExternalLink>{' '}
              </div>
            </div>
          </div>
        </div>
      </aside>

      <div className="w-full mt-4">
        {wrappedAsset && (
          <header className="mb-4 flex items-center">
            <div className="ml-auto text-right">
              <div className="text-sm">
                <span className="pr-2">{formatDecimalValue(totalTokenSupply, 2)} </span>
                <span className="text-gray pt-0">Total Token Supply</span>
              </div>
            </div>
          </header>
        )}
        <div className="mb-12">
          <ProfileActivityFeed
            profile={profile}
            exchangeRates={exchangeRates as GetExchangeRateUpdatedResponse}
            holdings={projectHoldings}
            holders={projectHolders}
          />
        </div>
      </div>
      <FollowModal
        profile={profile}
        exchangeRates={exchangeRates as GetExchangeRateUpdatedResponse}
        followModalState={followModalState}
        onClose={() => setFollowModalState(DEFAULT_FOLLOW_MODAL_STATE)}
      />
    </div>
  );
}

export default ProfileDetail;
