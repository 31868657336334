import { ActivityFeedItem } from 'components/app-ui/ActivityFeedItem';
import { FullPageError } from 'components/app-ui/FullPageError';
import { ProjectHodlersLeaderBoard } from 'components/app-ui/ProjectHodlersLeaderBoard';
import { Spinner } from 'components/core/Spinner';
import { BalanceEntryResponse, PostEntryResponse, ProfileEntryResponse } from 'deso-protocol';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { deso } from 'services';
import { GetExchangeRateUpdatedResponse } from '../../services/Deso';
import { TabGroup, Tabs } from '../core/Tabs';
import { ExternalLink } from 'components/core/ExternalLink';
import { LuExternalLink } from 'react-icons/lu';
import { TradeActivityTableWrapper } from './ActivityTableWrapper';
import MyOrders from '../pages/MyOrders';
import { getWrappedAsset } from '../../utils/deso';

interface ProfileActivityFeedProps {
  profile: ProfileEntryResponse;
  holdings: BalanceEntryResponse[];
  holders: BalanceEntryResponse[];
  exchangeRates: GetExchangeRateUpdatedResponse;
  selectedTab?: number;
}

const profileActivityFeedTabLocalStorageKey = 'profile-activity-feed-tab';

export function ProfileActivityFeed({
  profile,
  holdings = [],
  holders = [],
  exchangeRates,
  selectedTab = 0,
}: ProfileActivityFeedProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<any>();
  const [topHodlings, setTopHodlings] = useState<BalanceEntryResponse[]>(holdings);
  const [posts, setPosts] = useState<PostEntryResponse[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const getFeed: () => Promise<void> = async () => {
    const postRes = await deso.getPostsForPublicKey('', '', 20, profile.Username);
    const newPosts = (postRes.Posts || []).map((post) => {
      post.ProfileEntryResponse = {
        ...post.ProfileEntryResponse,
        ...profile,
      } as ProfileEntryResponse;
      return post;
    });
    setPosts(newPosts);
  };

  useEffectOnce(() => {
    setIsLoading(true);
    Promise.all([getFeed()])
      .catch((e) => setLoadingError(e))
      .finally(() => setIsLoading(false));
  });

  if (loadingError) {
    return <FullPageError error={loadingError} />;
  }

  const wrappedAsset = getWrappedAsset(profile.Username);

  const tabs = [
    {
      tab: 'Activity',
      panel: <TradeActivityTableWrapper traderPublicKey={profile.PublicKeyBase58Check} tokenPublicKey={''} />,
    },
    {
      tab: 'Feed',
      panel: (
        <div className="border rounded-2xl">
          {isLoading ? (
            <div className="text-center py-8">
              <Spinner />
            </div>
          ) : posts.filter(({ IsHidden }) => !IsHidden).length ? (
            posts
              .filter(({ IsHidden }) => !IsHidden)
              .map((post, i) => (
                <>
                  <ActivityFeedItem
                    post={post}
                    key={post.PostHashHex}
                    className={i < posts.length - 1 ? 'border-b border-gray-faint' : ''}
                  />
                </>
              ))
          ) : (
            <div className="flex justify-center text-muted center p-12">No posts found</div>
          )}
          {posts.filter(({ IsHidden }) => !IsHidden).length > 0 && (
            <div className="flex justify-center text-muted center pb-4 text-sm border-t pt-4 bg-accent rounded-br-2xl rounded-bl-2xl">
              <ExternalLink href={`https://diamondapp.com/u/${profile.Username}`} target="_blank">
                View more posts on Diamond <LuExternalLink className="inline ml-1" />
              </ExternalLink>
            </div>
          )}
        </div>
      ),
    },
    {
      tab: 'Holdings',
      panel: (
        <div className="flex flex-col gap-4">
          <ProjectHodlersLeaderBoard
            hodlers={topHodlings}
            exchangeRates={exchangeRates}
            project={profile}
            holdingsView={true}
          />
        </div>
      ),
    },
    holders.length > 0
      ? {
          tab: 'Holders',
          panel: (
            <>
              <ProjectHodlersLeaderBoard hodlers={holders} exchangeRates={exchangeRates} project={profile} />
            </>
          ),
        }
      : null,
    {
      tab: 'Open Orders',
      panel: (
        <MyOrders
          publicKey={profile.PublicKeyBase58Check}
          className="text-sm"
          overflow={false}
          heading={
            <h2 className="flex gap-2 items-center text-lg font-sans font-bold text-muted-foreground">
              @
              {profile.Username
                ? `${wrappedAsset ? wrappedAsset.displayName : profile.Username}'s Orders`
                : 'Open Orders'}
            </h2>
          }
        />
      ),
    },
  ];

  const visibleTabs = tabs.filter((e) => e !== null) as TabGroup[];

  const tabInQuery = searchParams.get('tab');
  const localStorageTab = localStorage.getItem(profileActivityFeedTabLocalStorageKey);
  const preselectedTab = tabInQuery || localStorageTab;

  if (preselectedTab !== null) {
    selectedTab = visibleTabs.findIndex((e) => e.tab === preselectedTab);
    if (selectedTab < 0) {
      selectedTab = 0;
    }
  }

  return (
    <Tabs
      selectedIndex={selectedTab}
      showSelect={false}
      onTabSelected={(tabGroup) => {
        const tab = tabGroup.tab;

        if (tab) {
          localStorage.setItem(profileActivityFeedTabLocalStorageKey, tab.toString());
          setSearchParams(`tab=${tab.toString()}`);
        }
      }}
      tabs={visibleTabs}
    ></Tabs>
  );
}
