import type { ReactNode } from 'react';
import { abbreviateNumber } from './USDValueWithDESO';
import { Skeleton } from '../shadcn/ui/skeleton';

interface ProfileStatProps {
  count: number;
  label: string;
  icon: ReactNode;
  loading?: boolean;
  className?: string;
}
export const ProfileStat = ({ count, label, icon, loading = false, className }: ProfileStatProps) => {
  return (
    <div className={`flex items-center gap-2 text-sm text-muted hover:text-foreground ${className}`}>
      <span className="hidden md:flex">{icon}</span>
      <span className="font-medium text-foreground">
        {loading ? <Skeleton className="h-4 w-6" /> : abbreviateNumber(count)}
      </span>{' '}
      {label}
    </div>
  );
};
