import axios from 'axios';
import { Button as ButtonNew } from 'components/shadcn/ui/button';
import {
  ASK,
  BID,
  DESO_PROJECT_NAME,
  DESO_TICKER,
  DESO_TOKEN_PUBLIC_KEY,
  DESO_USDC_TICKER,
  FILL_OR_KILL,
  FOCUS_TICKER,
  IMMEDIATE_OR_CANCEL,
  RESERVED_DESO_NANOS_FOR_FEES,
  USD_TICKER,
} from 'constants/TradeConstants';
import {
  CurrencyType,
  DeSoTokenMarketOrderWithFeeResponse,
  FillTypeWithFee,
  ProfileEntryResponse,
} from 'deso-protocol';
import React, { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { confetti, deso } from 'services';
import { OperationTypeWithFee } from 'services/Deso';
import { OpenfundUser } from 'services/Openfund';
import {
  baseUnitsToTokens,
  desoNanosToDeso,
  desoNanosToUSD,
  desoToDesoNanos,
  desoToUSD,
  focusToUSD,
  formatDecimalValue,
  formatUSD,
  parseFloatWithCommas,
  stripCommas,
} from 'utils/currency';
import {
  decimalStringToBigInt,
  desoBigIntToFloat,
  oneE18,
  oneE9,
  priceDecimalStringToBigInt,
  quantityBigIntToDecmialString,
  quantityBigIntToFloat,
  quantityDecimalStringToBigInt,
  sumBaseCurrencySellQuantityForTransactor,
  sumQuoteCurrencyCostForTransactor,
  zero,
} from 'utils/orderbook';
import { formatTickerWithTooltip, Ticker } from 'utils/tickers';
import { TradeContext } from '../../contexts/TradeContext';
import { getWrappedAsset, getWrappedAssetIcon } from '../../utils/deso';
import { CurrencyInputWithTickers } from './CurrencyInputWithTickers';

import { CurrencyInputWithTickerToggle } from 'components/app-ui/CurrencyInputWithTickerToggle';
import { NumberInputWithMaxToggle } from 'components/app-ui/NumberInputWithMaxToggle';
import { Spinner } from 'components/core/Spinner';
import { Tabs } from 'components/core/Tabs';
import {
  Select as SelectNew,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/ui/select';
import { useToast } from 'components/hooks/use-toast';
import debounce from 'lodash/debounce';
import { getErrorMsg } from 'utils/getErrorMsg';
import { trackingLogEvent } from '../../utils/tracking';
import { InfoTooltip } from '../core/InfoTooltip';
import { Link } from 'react-router-dom';
import PricePerToken from './PricePerToken';
import { Text } from 'components/core/Text';
import { QuoteCurrencyContext } from 'contexts/QuoteCurrencyContext';
import { DESO_USDC_PUBLIC_KEY, DESO_ZERO_PUBLIC_KEY, FOCUS_TOKEN_PUBLIC_KEY } from '../../constants/AppConstants';
import { Avatar, SIZES } from 'components/core/Avatar';
import { LuBitcoin, LuInfo, LuWallet } from 'react-icons/lu';
import LowNumFormatter from './LowNumFormatter';
import { ViewTransactionLink } from 'components/core/Toast';
import { Switch } from 'components/shadcn/ui/switch';
import { cn } from '../../utils/shadcn';

const SUMMARY_DECIMAL_PLACES = 5;
const DESO_INPUT_DECIMAL_PLACES = Infinity;
const QUANTITY_INPUT_DECIMAL_PLACES = Infinity;

const formatProjectTokensLabel = (username: string) => {
  const wrappedAsset = getWrappedAsset(username);

  if (username.toLowerCase() === DESO_TICKER.toLowerCase()) {
    return DESO_TICKER;
  }
  if (wrappedAsset) {
    return `$${wrappedAsset.displayName}`;
  }

  return `$${username}`;
};

const YouWillReceiveTooltip = (
  <InfoTooltip
    iconSize={16}
    text='This is the amount you will receive if the order were to execute fully at your Limit Price. Note that fees are not considered in calculating this value. Fees will be charged if your order executes immediately, as Openfund charges "taker-only" fees (maker fees are zero).'
  ></InfoTooltip>
);

const PricePerTokenSection = ({
  orderPreview,
  projectPublicKey,
  projectUsername,
}: {
  orderPreview: DeSoTokenMarketOrderWithFeeResponse | null;
  projectPublicKey: string;
  projectUsername: string;
}) => {
  const { quoteCurrencyPublicKey, tickerByQuoteCurrencyPublicKey } = useContext(QuoteCurrencyContext);

  return (
    <>
      <Text className="text-xs text-muted flex items-center gap-1" tag="div">
        Price per token
        <InfoTooltip iconSize={16} text={<PricePerToken publicKey={projectPublicKey} username={projectUsername} />} />
      </Text>

      <Text className="text-xs text-muted-foreground font-mono" tag="div">
        {quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY && (
          <>
            <Text tag="span">
              {formatUSD(parseFloatWithCommas(orderPreview?.ExecutionPriceInUsd ?? '0'), true, SUMMARY_DECIMAL_PLACES)}{' '}
              <span className="text-muted">{USD_TICKER}</span>
            </Text>
          </>
        )}

        {(quoteCurrencyPublicKey === DESO_ZERO_PUBLIC_KEY || quoteCurrencyPublicKey === FOCUS_TOKEN_PUBLIC_KEY) && (
          <div className="text-right">
            <Text tag="div" className="text-xs text-muted-foreground">
              ~{formatUSD(parseFloatWithCommas(orderPreview?.ExecutionPriceInUsd ?? '0'), true, SUMMARY_DECIMAL_PLACES)}{' '}
              {USD_TICKER}{' '}
            </Text>
            <Text tag="div" className="font-medium text-muted text-xs">
              {formatDecimalValue(
                parseFloatWithCommas(orderPreview?.ExecutionPriceInQuoteCurrency || '0'),
                SUMMARY_DECIMAL_PLACES,
                SUMMARY_DECIMAL_PLACES,
              )}{' '}
              {tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}{' '}
            </Text>
          </div>
        )}
      </Text>
    </>
  );
};

const DesoNetworkFeeSection = ({ orderPreview }: { orderPreview: DeSoTokenMarketOrderWithFeeResponse | null }) => {
  const { exchangeRates } = useContext(QuoteCurrencyContext);

  const feeNanos = orderPreview?.FeeNanos ?? 0;

  return (
    <>
      <Text className="text-xs text-muted flex items-center gap-1" tag="div">
        DeSo network fee
        <InfoTooltip
          iconSize={16}
          text={
            <>
              All trades are executed by the DeSo DEX, which runs on the DeSo blockchain. This means we need to include
              a small network fee to process the transaction, just like we would on a blockchain like Ethereum or
              Bitcoin. Learn more about DeSo and why it's more efficient than other blockchains{' '}
              <Link to="https://deso.com" target="_blank" className="underline underline-offset-4">
                here
              </Link>
              .
            </>
          }
        />
      </Text>
      <Text tag="div" className="text-right">
        <div className="text-xs text-muted-foreground font-mono">
          <Text tag="div">
            ~{formatUSD(desoToUSD(desoNanosToDeso(feeNanos), exchangeRates[DESO_ZERO_PUBLIC_KEY]), true, 8)}{' '}
            {USD_TICKER}{' '}
          </Text>
          <Text tag="div" className="text-xs text-muted text-right">
            {formatDecimalValue(desoNanosToDeso(feeNanos), 8, 8)} {DESO_TICKER}
          </Text>
        </div>
      </Text>
    </>
  );
};

export function TradeCard(props: {
  project: ProfileEntryResponse;
  currentUser: OpenfundUser | null;
  refreshing: boolean;
  setRefreshing: (refreshing: boolean) => void;
  userTokenBalanceBaseUnits: bigint;
  refreshOrderBookCallback: (skipOrderbookUpdate?: boolean) => void;
  usdBalanceBaseUnits: bigint;
  focusBalanceBaseUnits: bigint;
  limitOrderPriceFromOrderBook: { price: number; usdPrice: number } | null;
  onMarketOrderTabSelected: () => void;
}) {
  const { transactorOrders } = useContext(TradeContext);
  const { quoteCurrencyPublicKey, exchangeRates, usdTickerByQuoteCurrencyPublicKey } = useContext(QuoteCurrencyContext);

  const toast = useToast();
  const [side, setSide] = useState(() => {
    const savedSide = sessionStorage.getItem('side');
    return savedSide ? JSON.parse(savedSide) : BID;
  });
  const [ticker, setTicker] = useState<string>(usdTickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]);

  const usdBalance = baseUnitsToTokens(props.usdBalanceBaseUnits);
  const focusBalance = baseUnitsToTokens(props.focusBalanceBaseUnits);

  const onOrderSubmitSuccess = (txnHashHex: string) => {
    toast.toast({
      description: <>Your order has been submitted successfully to the DeSo Blockchain.</>,
      action: <ViewTransactionLink txnHashHex={txnHashHex} />,
      variant: 'success',
    });
    props.refreshOrderBookCallback();
    confetti.celebrate();
  };

  const onOrderSubmitFailure = (e: any) => {
    toast.toast({ description: getErrorMsg(e), variant: 'error' });
  };

  let availableTokenBaseUnits =
    props.userTokenBalanceBaseUnits -
    sumBaseCurrencySellQuantityForTransactor(
      transactorOrders,
      props.project.PublicKeyBase58Check,
      props.currentUser?.PublicKeyBase58Check ?? '',
    );

  if (availableTokenBaseUnits < zero) {
    availableTokenBaseUnits = zero;
  }

  let availableUSDCBaseUnits =
    BigInt(Math.floor(usdBalance * 1e18)) -
    sumQuoteCurrencyCostForTransactor(
      transactorOrders,
      DESO_USDC_PUBLIC_KEY,
      props.currentUser?.PublicKeyBase58Check ?? '',
    );
  if (availableUSDCBaseUnits < zero) {
    availableUSDCBaseUnits = zero;
  }

  let availableFocusBaseUnits =
    BigInt(Math.floor(focusBalance * 1e18)) -
    sumQuoteCurrencyCostForTransactor(
      transactorOrders,
      FOCUS_TOKEN_PUBLIC_KEY,
      props.currentUser?.PublicKeyBase58Check ?? '',
    );
  if (availableFocusBaseUnits < zero) {
    availableFocusBaseUnits = zero;
  }

  let availableDESONanos =
    BigInt(props.currentUser?.BalanceNanos ?? 0) -
    sumQuoteCurrencyCostForTransactor(
      transactorOrders,
      DESO_ZERO_PUBLIC_KEY,
      props.currentUser?.PublicKeyBase58Check ?? '',
    );
  if (availableDESONanos < zero) {
    availableDESONanos = zero;
  }

  useEffect(() => {
    sessionStorage.setItem('side', JSON.stringify(side));
  }, [side]);

  const tabs = [
    {
      tab: 'Market Order',
      panel: (
        <div className="p-4 border border-border rounded-2xl">
          {side === BID ? (
            <MarketOrderBidCreator
              project={props.project}
              refreshing={props.refreshing}
              setRefreshing={props.setRefreshing}
              userWalletBalanceInDESONanos={availableDESONanos}
              onOrderSubmitSuccess={onOrderSubmitSuccess}
              onOrderSubmitFailure={onOrderSubmitFailure}
              usdBalanceBaseUnits={availableUSDCBaseUnits}
              focusBalanceBaseUnits={availableFocusBaseUnits}
              ticker={ticker}
              onTickerChange={setTicker}
            />
          ) : (
            <MarketOrderAskCreator
              project={props.project}
              refreshing={props.refreshing}
              setRefreshing={props.setRefreshing}
              userTokenBalanceBaseUnits={availableTokenBaseUnits}
              userWalletBalanceInDESONanos={availableDESONanos}
              usdBalanceBaseUnits={availableUSDCBaseUnits}
              focusBalanceBaseUnits={availableFocusBaseUnits}
              onOrderSubmitSuccess={onOrderSubmitSuccess}
              onOrderSubmitFailure={onOrderSubmitFailure}
              ticker={ticker}
              onTickerChange={setTicker}
            />
          )}
        </div>
      ),
    },
    {
      tab: 'Limit Order',
      panel: (
        <div>
          {side === BID ? (
            <LimitOrderBidCreator
              project={props.project}
              refreshing={props.refreshing}
              setRefreshing={props.setRefreshing}
              userWalletBalanceInDESONanos={availableDESONanos}
              userTokenBalanceBaseUnits={availableTokenBaseUnits}
              usdBalanceBaseUnits={availableUSDCBaseUnits}
              focusBalanceBaseUnits={availableFocusBaseUnits}
              limitOrderPriceFromOrderBook={props.limitOrderPriceFromOrderBook}
              onOrderSubmitSuccess={onOrderSubmitSuccess}
              onOrderSubmitFailure={onOrderSubmitFailure}
            />
          ) : (
            <LimitOrderAskCreator
              project={props.project}
              refreshing={props.refreshing}
              setRefreshing={props.setRefreshing}
              limitOrderPriceFromOrderBook={props.limitOrderPriceFromOrderBook}
              userTokenBalanceBaseUnits={availableTokenBaseUnits}
              userWalletBalanceInDESONanos={availableDESONanos}
              onOrderSubmitSuccess={onOrderSubmitSuccess}
              onOrderSubmitFailure={onOrderSubmitFailure}
            />
          )}
        </div>
      ),
    },
  ];

  const isDesoProfile = props.project.PublicKeyBase58Check === DESO_TOKEN_PUBLIC_KEY;
  const wrappedAsset = getWrappedAsset(props.project.PublicKeyBase58Check, 'publicKey');

  // Add state for showing all balances
  const [showAllBalances, setShowAllBalances] = useState(false);

  return (
    <div className="m-auto">
      {props.currentUser && (
        <div className="border-b -mx-4 mb-4">
          <div className="p-4 pt-0">
            <div className="flex items-center gap-2 sm:justify-between mb-4">
              <div className="flex flex-col sm:flex-row sm:justify-between w-full sm:items-center">
                <div className="text-muted-foreground text-sm font-semibold">Total Balance</div>
                <div className="flex items-center gap-2">
                  <label
                    htmlFor="show-all-balances"
                    className="text-sm hover:text-muted-foreground cursor-pointer text-muted flex items-center gap-1"
                  >
                    Show all balances
                    <InfoTooltip
                      iconSize={16}
                      side="top"
                      text={
                        <>
                          <p className="mb-2">
                            <span className="font-semibold text-muted-foreground">Total balance</span> does not factor
                            in the effect of resting orders.
                          </p>
                          <p className="mb-2">
                            <span className="font-semibold text-muted-foreground">Spendable balance</span> is your total
                            balance minus the amount you have already tied up in placed orders.
                          </p>
                        </>
                      }
                    />
                  </label>
                  <Switch id="show-all-balances" checked={showAllBalances} onCheckedChange={setShowAllBalances} />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-0 divide-y divide-border-light border bg-accent rounded-xl">
              {/* ---------- DESO (SPENDABLE BALANCE) ---------- */}
              {quoteCurrencyPublicKey === DESO_ZERO_PUBLIC_KEY && (
                <>
                  <BalanceRow
                    iconSrc="/images/icon-DESO.png"
                    linkTo="/profile/DESO"
                    tokenName="$DESO"
                    balanceLabel="Spendable Balance"
                    balanceInUSD={desoNanosToUSD(availableDESONanos, exchangeRates[DESO_ZERO_PUBLIC_KEY])}
                    balance={desoNanosToDeso(availableDESONanos)}
                    ticker={DESO_TICKER}
                    isSpendable={true}
                  />
                  {showAllBalances && (
                    <BalanceRow
                      iconSrc="/images/icon-DESO.png"
                      linkTo="/profile/DESO"
                      tokenName="$DESO"
                      balanceLabel="Total Balance"
                      balanceInUSD={desoNanosToUSD(props.currentUser.BalanceNanos, exchangeRates[DESO_ZERO_PUBLIC_KEY])}
                      balance={desoNanosToDeso(props.currentUser.BalanceNanos)}
                      ticker={DESO_TICKER}
                      isSpendable={false}
                    />
                  )}
                </>
              )}

              {/* ---------- USDC (TOTAL BALANCE) ---------- */}
              {quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY && (
                <>
                  <BalanceRow
                    iconSrc="/images/icon-USDC.png"
                    linkTo="/profile/USDC"
                    tokenName="USDC"
                    balanceLabel="Spendable Balance"
                    balanceInUSD={quantityBigIntToFloat(availableUSDCBaseUnits)}
                    balance={quantityBigIntToFloat(availableUSDCBaseUnits)}
                    ticker="DUSD"
                    isSpendable={true}
                    showTooltip={true}
                  />
                  {showAllBalances && (
                    <BalanceRow
                      iconSrc="/images/icon-USDC.png"
                      linkTo="/profile/USDC"
                      tokenName="USDC"
                      balanceLabel="Total Balance"
                      balanceInUSD={usdBalance}
                      balance={usdBalance}
                      ticker="DUSD"
                      isSpendable={false}
                      showTooltip={true}
                    />
                  )}
                </>
              )}

              {/* ---------- FOCUS (TOTAL BALANCE) ---------- */}
              {quoteCurrencyPublicKey === FOCUS_TOKEN_PUBLIC_KEY && (
                <>
                  <BalanceRow
                    iconSrc="/images/icon-FOCUS.png"
                    linkTo="/profile/FOCUS"
                    tokenName="FOCUS"
                    balanceLabel="Spendable Balance"
                    balanceInUSD={focusToUSD(
                      quantityBigIntToFloat(availableFocusBaseUnits),
                      exchangeRates[FOCUS_TOKEN_PUBLIC_KEY],
                    )}
                    balance={quantityBigIntToFloat(availableFocusBaseUnits)}
                    ticker={FOCUS_TICKER}
                    isSpendable={true}
                  />
                  {showAllBalances && (
                    <BalanceRow
                      iconSrc="/images/icon-FOCUS.png"
                      linkTo="/profile/FOCUS"
                      tokenName="FOCUS"
                      balanceLabel="Total Balance"
                      balanceInUSD={focusToUSD(focusBalance, exchangeRates[FOCUS_TOKEN_PUBLIC_KEY])}
                      balance={focusBalance}
                      ticker={FOCUS_TICKER}
                      isSpendable={false}
                    />
                  )}
                </>
              )}

              {/* ---------- TOKEN (TOTAL BALANCE) ---------- */}
              {props.refreshing ? (
                <div className="w-full border-t border-border-light">
                  <Spinner size={32} />
                </div>
              ) : (
                <>
                  <BalanceRow
                    iconSrc={
                      props.project.PublicKeyBase58Check === DESO_PROJECT_NAME
                        ? '/images/icon-DESO.png'
                        : !!wrappedAsset
                          ? getWrappedAssetIcon(wrappedAsset)
                          : props.project.PublicKeyBase58Check
                    }
                    linkTo={`/profile/${props.project.Username}`}
                    tokenName={formatProjectTokensLabel(props.project.Username)}
                    balanceLabel="Spendable Balance"
                    balanceInUSD={0}
                    balance={
                      quoteCurrencyPublicKey === DESO_ZERO_PUBLIC_KEY || !isDesoProfile
                        ? baseUnitsToTokens(availableTokenBaseUnits)
                        : desoNanosToDeso(availableDESONanos)
                    }
                    ticker={formatProjectTokensLabel(props.project.Username)}
                    isSpendable={true}
                    showLowNumFormatter={true}
                  />
                  {showAllBalances && (
                    <BalanceRow
                      iconSrc={
                        props.project.PublicKeyBase58Check === DESO_PROJECT_NAME
                          ? '/images/icon-DESO.png'
                          : !!wrappedAsset
                            ? getWrappedAssetIcon(wrappedAsset)
                            : props.project.PublicKeyBase58Check
                      }
                      linkTo={`/profile/${props.project.Username}`}
                      tokenName={formatProjectTokensLabel(props.project.Username)}
                      balanceLabel="Total Balance"
                      balanceInUSD={0}
                      balance={
                        quoteCurrencyPublicKey === DESO_ZERO_PUBLIC_KEY || !isDesoProfile
                          ? baseUnitsToTokens(props.userTokenBalanceBaseUnits)
                          : desoNanosToDeso(props.currentUser?.BalanceNanos)
                      }
                      ticker={formatProjectTokensLabel(props.project.Username)}
                      isSpendable={false}
                      showLowNumFormatter={true}
                    />
                  )}
                </>
              )}
            </div>
            <div className="grid grid-cols-2 gap-2 mt-4">
              <Link to="/wallet" className="w-full">
                <ButtonNew variant="outline" size="sm" className="w-full flex items-center gap-2">
                  <LuBitcoin className="text-lg text-muted" /> Top Up
                </ButtonNew>
              </Link>
              <Link to="/wallet" className="w-full">
                <ButtonNew variant="outline" size="sm" className="w-full flex items-center gap-2">
                  <LuWallet className="text-lg text-muted" /> Cash Out
                </ButtonNew>
              </Link>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col md:flex-row">
        <div className="flex">
          <BidSideSelector isSelected={side === BID} onClick={() => setSide(BID)} />
          <AskSideSelector isSelected={side === ASK} onClick={() => setSide(ASK)} />
        </div>
      </div>
      <div className="w-full text-foreground">
        <Tabs
          tabs={tabs}
          showSelect={false}
          selectedIndex={props.limitOrderPriceFromOrderBook ? 1 : 0}
          onTabSelected={(t) => {
            if (t.tab === 'Market Order') {
              props.onMarketOrderTabSelected();
            }
          }}
        />
      </div>
    </div>
  );
}

interface BalanceRowProps {
  iconSrc: string;
  linkTo: string;
  tokenName: string;
  balanceLabel?: string;
  balanceInUSD: number;
  balance: number | string;
  ticker: string;
  isSpendable?: boolean;
  showTooltip?: boolean;
  size?: 'xs' | 'sm';
  showLowNumFormatter?: boolean; // Added new prop
}

export function BalanceRow({
  iconSrc,
  linkTo,
  tokenName,
  balanceLabel,
  balanceInUSD,
  balance,
  ticker,
  isSpendable = false,
  showTooltip = false,
  size = 'sm',
  showLowNumFormatter = false,
}: BalanceRowProps) {
  return (
    <div className="flex items-center justify-between p-2">
      <div className="flex flex-row items-center gap-2">
        <div className={cn(SIZES[size], 'shrink-0')}>
          <Avatar size={size} src={iconSrc} />
        </div>
        <div className="flex items-start gap-0 flex-col">
          <Link to={linkTo} className="text-muted-foreground text-xs hover:underline underline-offset-4">
            {tokenName}
          </Link>
          {balanceLabel && <span className="text-xs text-muted">{balanceLabel}</span>}
        </div>
      </div>

      <div className="flex flex-col text-right">
        {balanceInUSD > 0 && (
          <span
            className={`text-xs font-mono ${isSpendable ? 'font-shadow-green text-green-500' : 'text-muted-foreground'}`}
          >
            {formatUSD(balanceInUSD, true, 2)} USD
          </span>
        )}
        <span className="text-xs text-muted">
          {showLowNumFormatter ? (
            <LowNumFormatter
              className={`text-xs text-muted-foreground font-mono ${isSpendable ? 'font-shadow-green text-green-500' : 'text-muted-foreground'}`}
              price={Number(balance)}
              isUsd={false}
            />
          ) : (
            formatDecimalValue(balance, 4)
          )}{' '}
          {showTooltip ? formatTickerWithTooltip(ticker as Ticker, 16, 'ml-0') : ticker}
        </span>
      </div>
    </div>
  );
}

function MarketOrderBidCreator(props: {
  project: ProfileEntryResponse;
  refreshing: boolean;
  setRefreshing: (refreshing: boolean) => void;
  userWalletBalanceInDESONanos: bigint;
  onOrderSubmitSuccess: (txnHashHex: string) => void;
  onOrderSubmitFailure: (e: any) => void;
  usdBalanceBaseUnits: bigint;
  focusBalanceBaseUnits: bigint;
  ticker: string;
  onTickerChange: (t: string) => void;
}) {
  const { quoteCurrencyPublicKey, exchangeRates, tickerByQuoteCurrencyPublicKey } = useContext(QuoteCurrencyContext);

  const [orderQuantity, setOrderQuantity] = useState('0');
  const [orderFillType, setOrderFillType] = useState(IMMEDIATE_OR_CANCEL);

  const [isLoadingOrderDetails, setIsLoadingOrderDetails] = useState(false);
  const [orderPreviewError, setOrderPreviewError] = useState('');
  const [orderPreview, setOrderPreview] = useState<null | DeSoTokenMarketOrderWithFeeResponse>(null);

  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    // Reset everything when legacy view toggle is switched

    setOrderQuantity('0');
    setOrderFillType(IMMEDIATE_OR_CANCEL);

    setOrderPreview(null);
    setOrderPreviewError('');
  }, [quoteCurrencyPublicKey]);

  const createOrderPayload = (
    quantity: string,
    fillType: string,
    selectedTicker: string,
    quoteCurrencyPublicKeyBase58Check: string,
  ) => {
    let quantityCurrencyType: CurrencyType;

    if (selectedTicker === USD_TICKER || selectedTicker === DESO_USDC_TICKER) {
      quantityCurrencyType = CurrencyType.usd;
    } else if (selectedTicker === tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKeyBase58Check]) {
      quantityCurrencyType = CurrencyType.quote;
    } else {
      quantityCurrencyType = CurrencyType.base;
    }
    return {
      OperationType: OperationTypeWithFee.BID,
      QuoteCurrencyPublicKeyBase58Check: quoteCurrencyPublicKeyBase58Check,
      BaseCurrencyPublicKeyBase58Check: props.project.PublicKeyBase58Check,
      Quantity: stripCommas(quantity),
      FillType: fillType as FillTypeWithFee,
      QuantityCurrencyType: quantityCurrencyType,
      Price: '0.000000000',
      PriceCurrencyType: CurrencyType.quote,
    };
  };

  const debouncedSimulateOrder = useRef(
    debounce(
      async (quantity: string, fillType: string, selectedTicker: string, quoteCurrencyPublicKeyBase58Check: string) => {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();

        deso
          .simulateTokenOrderWithFee(
            createOrderPayload(quantity, fillType, selectedTicker, quoteCurrencyPublicKeyBase58Check),
          )
          .then((data) => {
            setOrderPreview(data);
            setOrderPreviewError('');

            setIsLoadingOrderDetails(false);
          })
          .catch((e) => {
            if (axios.isCancel(e)) {
              return;
            }

            setOrderPreviewError(getErrorMsg(e, false));
            setIsLoadingOrderDetails(false);
          });
      },
      500,
    ),
  ).current;

  const updateOrder = (quantity: string, fillType: string, selectedTicker: string = props.ticker) => {
    setOrderQuantity(stripCommas(quantity));
    setOrderFillType(fillType);

    const quantityAsBigInt = decimalStringToBigInt(quantity);
    if (quantityAsBigInt === BigInt(0)) {
      setOrderPreviewError('');
      setOrderPreview(null);

      // We need to debounce again here to clear previously scheduled updates
      setTimeout(() => {
        setIsLoadingOrderDetails(false);
      }, 0);
      return;
    }

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    setIsLoadingOrderDetails(true);
    debouncedSimulateOrder(quantity, fillType, selectedTicker, quoteCurrencyPublicKey);
  };

  const getBalanceWithFees = (ticker: string) => {
    const desoSpendableBalance = desoBigIntToFloat(props.userWalletBalanceInDESONanos - RESERVED_DESO_NANOS_FOR_FEES);
    const focusSpendableBalance = quantityBigIntToFloat(props.focusBalanceBaseUnits);
    const usdSpendableBalance = quantityBigIntToFloat(props.usdBalanceBaseUnits);
    const exchangeRate = ticker !== USD_TICKER ? 100 : exchangeRates[quoteCurrencyPublicKey];

    if (quoteCurrencyPublicKey === DESO_ZERO_PUBLIC_KEY) {
      return desoToUSD(desoSpendableBalance, exchangeRate);
    } else if (quoteCurrencyPublicKey === FOCUS_TOKEN_PUBLIC_KEY) {
      return (
        focusToUSD(focusSpendableBalance, exchangeRate) -
        desoToUSD(desoBigIntToFloat(RESERVED_DESO_NANOS_FOR_FEES), exchangeRates[DESO_ZERO_PUBLIC_KEY])
      );
    } else if (quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY) {
      return usdSpendableBalance;
    } else {
      return 0;
    }
  };

  const wrappedAsset = getWrappedAsset(props.project.Username);
  const isWrapped = !!wrappedAsset;
  const currencyDisplayName = isWrapped ? wrappedAsset.displayName : props.project.Username;

  const isQuantityGreaterThanMax = () => {
    if (props.ticker === currencyDisplayName) {
      // If user token is selected as ticker we ignore front-end validation and keep the backend validation
      return true;
    }
    return Number(orderQuantity) <= getBalanceWithFees(props.ticker);
  };

  const availableTickers = useMemo(() => {
    switch (quoteCurrencyPublicKey) {
      case DESO_ZERO_PUBLIC_KEY:
        return [USD_TICKER, DESO_TICKER, currencyDisplayName];
      case DESO_USDC_PUBLIC_KEY:
        if (props.project.PublicKeyBase58Check === DESO_ZERO_PUBLIC_KEY) {
          return [tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey], DESO_TICKER];
        } else {
          return [tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey], currencyDisplayName];
        }
      case FOCUS_TOKEN_PUBLIC_KEY:
        return [USD_TICKER, FOCUS_TICKER, currencyDisplayName];
      default:
        return [USD_TICKER];
    }
  }, [currencyDisplayName, quoteCurrencyPublicKey, props.project.PublicKeyBase58Check]);

  return (
    <div className="pb-6 sm:p-0">
      <div>
        <CurrencyInputWithTickers
          key={quoteCurrencyPublicKey}
          containerClasses="mb-4"
          labelText="Purchase Amount"
          value={orderQuantity}
          tickers={availableTickers}
          autoSelectTickerOnMax={tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}
          allowedDecimalPlaces={DESO_INPUT_DECIMAL_PLACES}
          state={isQuantityGreaterThanMax() && !orderPreviewError ? 'default' : 'error'}
          onStateChange={(v, t) => {
            props.onTickerChange(t);
            updateOrder(v, orderFillType, t);
          }}
          onMax={(selectedTicker) => {
            const balance = getBalanceWithFees(selectedTicker);

            if (balance <= 0) {
              updateOrder('0', orderFillType, selectedTicker);
              return '0';
            }

            updateOrder(String(balance), orderFillType, selectedTicker);
            return balance;
          }}
        />
      </div>
      <div>
        <FillTypeSelector value={orderFillType} onChange={(fillType) => updateOrder(orderQuantity, fillType)} />
      </div>
      {isLoadingOrderDetails ? (
        <LoadingOrderDetails />
      ) : orderPreviewError !== '' ? (
        <ErrorOrderDetails errorMsg={orderPreviewError} />
      ) : (
        <BidSideOrderDetails
          projectUsername={props.project.Username}
          projectPublicKey={props.project.PublicKeyBase58Check}
          orderPreview={orderPreview}
          isLimitOrder={false}
        />
      )}
      <ButtonNew
        loading={props.refreshing}
        disabled={parseFloatWithCommas(orderPreview?.ExecutionReceiveAmount || '0') <= 0 || orderPreviewError !== ''}
        className="w-full bg-green-500"
        variant="success"
        onClick={async () => {
          if (orderPreviewError) {
            props.onOrderSubmitFailure(`Your wallet balance is too low`);
            return;
          }

          props.setRefreshing(true);
          const payload = createOrderPayload(orderQuantity, orderFillType, props.ticker, quoteCurrencyPublicKey);
          trackingLogEvent('market : createMarketOrder : start', { ...payload });

          deso
            .createTokenMarketOrderWithFee(payload)
            .then(({ submittedTransactionResponse }) => {
              trackingLogEvent('market : createMarketOrder : success', { ...payload });
              return deso.addProjectPurchasedToProfile(props.project.PublicKeyBase58Check).then(() => {
                props.onOrderSubmitSuccess(submittedTransactionResponse.TxnHashHex);
                updateOrder('0', orderFillType);
              });
            })
            .catch((e) => {
              trackingLogEvent('market : createMarketOrder : error', {
                ...payload,
                error: getErrorMsg(e),
              });
              props.onOrderSubmitFailure(e);
              props.setRefreshing(false);
            });
        }}
      >
        Buy {formatProjectTokensLabel(props.project.Username)}
      </ButtonNew>
    </div>
  );
}

function MarketOrderAskCreator(props: {
  project: ProfileEntryResponse;
  refreshing: boolean;
  setRefreshing: (refreshing: boolean) => void;
  userTokenBalanceBaseUnits: bigint;
  userWalletBalanceInDESONanos: bigint;
  onOrderSubmitSuccess: (txnHashHex: string) => void;
  onOrderSubmitFailure: (e: any) => void;
  usdBalanceBaseUnits: bigint;
  focusBalanceBaseUnits: bigint;
  ticker: string;
  onTickerChange: (t: string) => void;
}) {
  const { quoteCurrencyPublicKey, tickerByQuoteCurrencyPublicKey } = useContext(QuoteCurrencyContext);

  const [orderQuantity, setOrderQuantity] = useState('0');
  const [orderFillType, setOrderFillType] = useState(IMMEDIATE_OR_CANCEL);

  const [isLoadingOrderPreview, setIsLoadingOrderPreview] = useState(false);
  const [orderPreviewError, setOrderPreviewError] = useState('');
  const [orderPreview, setOrderPreview] = useState<null | DeSoTokenMarketOrderWithFeeResponse>(null);

  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    // Reset everything when legacy view toggle is switched
    setOrderQuantity('0');
    setOrderFillType(IMMEDIATE_OR_CANCEL);

    setOrderPreviewError('');
    setOrderPreview(null);
  }, [quoteCurrencyPublicKey]);

  const createOrderPayload = (
    quantity: string,
    fillType: string,
    selectedTicker: string,
    quoteCurrencyPublicKeyBase58Check: string,
  ) => {
    // CODE DUPLICATION FROM MarketOrderBidCreator
    let quantityCurrencyType: CurrencyType;

    if (selectedTicker === USD_TICKER || selectedTicker === DESO_USDC_TICKER) {
      quantityCurrencyType = CurrencyType.usd;
    } else if (selectedTicker === tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKeyBase58Check]) {
      quantityCurrencyType = CurrencyType.quote;
    } else {
      quantityCurrencyType = CurrencyType.base;
    }

    return {
      OperationType: OperationTypeWithFee.ASK,
      QuoteCurrencyPublicKeyBase58Check: quoteCurrencyPublicKeyBase58Check,
      BaseCurrencyPublicKeyBase58Check: props.project.PublicKeyBase58Check,
      Quantity: stripCommas(quantity),
      FillType: fillType as FillTypeWithFee,
      QuantityCurrencyType: quantityCurrencyType,
      Price: '0.000000000',
      PriceCurrencyType: CurrencyType.quote,
    };
  };

  // TODO: it is pretty tricky to properly validate the sell amount
  // const getBalanceWithFees = (ticker: string) => {
  //   const desoSpendableBalance = desoBigIntToFloat(props.userWalletBalanceInDESONanos - RESERVED_DESO_NANOS_FOR_FEES);
  //   const focusSpendableBalance = quantityBigIntToFloat(props.focusBalanceBaseUnits);
  //   const usdSpendableBalance = quantityBigIntToFloat(props.usdBalanceBaseUnits);
  //   const exchangeRate = ticker !== USD_TICKER ? 100 : exchangeRates[quoteCurrencyPublicKey];
  //
  //   if (quoteCurrencyPublicKey === DESO_ZERO_PUBLIC_KEY) {
  //     return desoToUSD(desoSpendableBalance, exchangeRate);
  //   } else if (quoteCurrencyPublicKey === FOCUS_TOKEN_PUBLIC_KEY) {
  //     return (
  //       focusToUSD(focusSpendableBalance, exchangeRate) -
  //       desoToUSD(desoBigIntToFloat(RESERVED_DESO_NANOS_FOR_FEES), exchangeRates[DESO_ZERO_PUBLIC_KEY])
  //     );
  //   } else if (quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY) {
  //     return usdSpendableBalance;
  //   } else {
  //     return 0;
  //   }
  // };
  //
  // const isQuantityGreaterThanMax = () => {
  //   return Number(orderQuantity) <= getBalanceWithFees(props.ticker);
  // };

  const debouncedSimulateOrder = useRef(
    debounce(
      async (quantity: string, fillType: string, selectedTicker: string, quoteCurrencyPublicKeyBase58Check: string) => {
        abortControllerRef.current = new AbortController();

        deso
          .simulateTokenOrderWithFee(
            createOrderPayload(quantity, fillType, selectedTicker, quoteCurrencyPublicKeyBase58Check),
          )
          .then((data) => {
            setOrderPreview(data);
            setOrderPreviewError('');
            setIsLoadingOrderPreview(false);
          })
          .catch((e) => {
            if (axios.isCancel(e)) {
              return;
            }

            setOrderPreviewError(getErrorMsg(e, false));
            setIsLoadingOrderPreview(false);
          });
      },
      500,
    ),
  ).current;

  const updateOrder = (quantity: string, fillType: string, selectedTicker: string = props.ticker) => {
    setOrderQuantity(stripCommas(quantity));
    setOrderFillType(fillType);

    const quantityAsBigInt = quantityDecimalStringToBigInt(quantity);
    if (quantityAsBigInt === BigInt(0)) {
      setOrderPreviewError('');
      setOrderPreview(null);

      // We need to debounce again here to clear previously scheduled updates
      setTimeout(() => {
        setIsLoadingOrderPreview(false);
      }, 0);
      return;
    }

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    setIsLoadingOrderPreview(true);
    debouncedSimulateOrder(quantity, fillType, selectedTicker, quoteCurrencyPublicKey);
  };

  const isDesoProfile = props.project.PublicKeyBase58Check === DESO_TOKEN_PUBLIC_KEY;
  const userTokenBalance = isDesoProfile ? props.userWalletBalanceInDESONanos : props.userTokenBalanceBaseUnits;
  const scalingFactor = isDesoProfile ? oneE9 : oneE18;

  const wrappedAsset = getWrappedAsset(props.project.Username);
  const isWrapped = !!wrappedAsset;
  const currencyDisplayName = isWrapped ? wrappedAsset.displayName : props.project.Username;

  const availableTickers = useMemo(() => {
    // THIS IS CODE DUPLICATION FROM MarketOrderBidCreator
    switch (quoteCurrencyPublicKey) {
      case DESO_ZERO_PUBLIC_KEY:
        return [currencyDisplayName, USD_TICKER, DESO_TICKER];
      case DESO_USDC_PUBLIC_KEY:
        if (props.project.PublicKeyBase58Check === DESO_ZERO_PUBLIC_KEY) {
          return [USD_TICKER, DESO_TICKER];
        } else {
          return [currencyDisplayName, USD_TICKER];
        }
      case FOCUS_TOKEN_PUBLIC_KEY:
        return [currencyDisplayName, USD_TICKER, FOCUS_TICKER];
      default:
        return [USD_TICKER];
    }
  }, [currencyDisplayName, quoteCurrencyPublicKey, props.project.PublicKeyBase58Check]);

  return (
    <div>
      <div className="w-full">
        <CurrencyInputWithTickers
          key={quoteCurrencyPublicKey}
          labelText="Sell Amount"
          value={orderQuantity}
          tickers={availableTickers}
          allowedDecimalPlaces={QUANTITY_INPUT_DECIMAL_PLACES}
          state={!orderPreviewError ? 'default' : 'error'}
          autoSelectTickerOnMax={currencyDisplayName}
          onMax={() => {
            const balance = isDesoProfile ? userTokenBalance - RESERVED_DESO_NANOS_FOR_FEES : userTokenBalance;
            if (balance <= BigInt(0)) {
              updateOrder('0', orderFillType, currencyDisplayName);
              return '0';
            }
            const maxDecimalString = quantityBigIntToDecmialString(balance, -1, scalingFactor);
            updateOrder(maxDecimalString, orderFillType, currencyDisplayName);
            return maxDecimalString;
          }}
          onStateChange={(v, t) => {
            props.onTickerChange(t);
            updateOrder(v, orderFillType, t);
          }}
          hint={getTokenHoldingDescription(
            props.project.Username,
            quantityBigIntToDecmialString(userTokenBalance, SUMMARY_DECIMAL_PLACES, scalingFactor),
          )}
        />
      </div>
      <div className="pt-4">
        <FillTypeSelector value={orderFillType} onChange={(fillType) => updateOrder(orderQuantity, fillType)} />
      </div>
      {isLoadingOrderPreview ? (
        <LoadingOrderDetails />
      ) : orderPreviewError !== '' ? (
        <ErrorOrderDetails errorMsg={orderPreviewError} />
      ) : (
        <AskSideOrderDetails
          projectUsername={props.project.Username}
          projectPublicKey={props.project.PublicKeyBase58Check}
          orderPreview={orderPreview}
          isLimitOrder={false}
        />
      )}
      <ButtonNew
        disabled={parseFloatWithCommas(orderPreview?.ExecutionAmount || '0') <= 0 || orderPreviewError !== ''}
        className="w-full"
        variant="destructive"
        onClick={async () => {
          if (orderPreviewError) {
            props.onOrderSubmitFailure(`Your wallet balance is too low`);
            return;
          }

          props.setRefreshing(true);
          const payload = createOrderPayload(
            stripCommas(orderQuantity),
            orderFillType,
            props.ticker,
            quoteCurrencyPublicKey,
          );
          trackingLogEvent('market : createMarketOrder : start', {
            ...payload,
          });

          deso
            .createTokenMarketOrderWithFee(payload)
            .then(({ submittedTransactionResponse }) => {
              trackingLogEvent('market : createMarketOrder : success', {
                ...payload,
              });
              props.onOrderSubmitSuccess(submittedTransactionResponse.TxnHashHex);
              updateOrder('0', orderFillType);
            })
            .catch((e) => {
              trackingLogEvent('market : createMarketOrder : error', {
                ...payload,
                error: getErrorMsg(e),
              });
              props.onOrderSubmitFailure(e);
              props.setRefreshing(false);
            });
        }}
      >
        Sell {formatProjectTokensLabel(props.project.Username)}
      </ButtonNew>
    </div>
  );
}

function LimitOrderBidCreator(props: {
  project: ProfileEntryResponse;
  refreshing: boolean;
  setRefreshing: (refreshing: boolean) => void;
  userWalletBalanceInDESONanos: bigint;
  userTokenBalanceBaseUnits: bigint;
  usdBalanceBaseUnits: bigint;
  focusBalanceBaseUnits: bigint;
  limitOrderPriceFromOrderBook: { price: number; usdPrice: number } | null;
  onOrderSubmitSuccess: (txnHashHex: string) => void;
  onOrderSubmitFailure: (e: any) => void;
}) {
  const { quoteCurrencyPublicKey, exchangeRates, tickerByQuoteCurrencyPublicKey, usdTickerByQuoteCurrencyPublicKey } =
    useContext(QuoteCurrencyContext);

  const [orderPrice, setOrderPrice] = useState('0');
  const [orderQuantity, setOrderQuantity] = useState('0');
  const [ticker, setTicker] = useState(usdTickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]);

  const [isLoadingOrderDetails, setIsLoadingOrderDetails] = useState(false);
  const [orderPreviewError, setOrderPreviewError] = useState('');
  const [orderPreview, setOrderPreview] = useState<null | DeSoTokenMarketOrderWithFeeResponse>(null);

  const abortControllerRef = useRef<AbortController | null>(null);

  const totalAmountToSpendInQuoteCurrency = parseFloatWithCommas(orderPrice) * parseFloatWithCommas(orderQuantity);

  useEffect(() => {
    // Reset everything when legacy view toggle is switched
    setOrderPrice('0');
    setOrderQuantity('0');

    setOrderPreviewError('');

    setOrderPreview(null);
  }, [quoteCurrencyPublicKey]);

  const getBalanceWithFees = (selectedTicker: string) => {
    const desoSpendableBalance = desoBigIntToFloat(props.userWalletBalanceInDESONanos - RESERVED_DESO_NANOS_FOR_FEES);
    const focusSpendableBalance = quantityBigIntToFloat(props.focusBalanceBaseUnits);
    const usdSpendableBalance = quantityBigIntToFloat(props.usdBalanceBaseUnits);
    const exchangeRate = selectedTicker !== USD_TICKER ? 100 : exchangeRates[quoteCurrencyPublicKey];

    if (quoteCurrencyPublicKey === DESO_ZERO_PUBLIC_KEY) {
      return desoToUSD(desoSpendableBalance, exchangeRate);
    } else if (quoteCurrencyPublicKey === FOCUS_TOKEN_PUBLIC_KEY) {
      return (
        focusToUSD(focusSpendableBalance, exchangeRate) -
        desoToUSD(desoBigIntToFloat(RESERVED_DESO_NANOS_FOR_FEES), exchangeRates[DESO_ZERO_PUBLIC_KEY])
      );
    } else if (quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY) {
      return usdSpendableBalance;
    } else {
      return 0;
    }
  };

  const getMaxQuantity = () => {
    if (orderPrice.length === 0 || parseFloatWithCommas(orderPrice) === 0) {
      return '0';
    }
    const balance = getBalanceWithFees(ticker);

    if (balance < BigInt(0)) {
      return '0';
    }
    return ((balance * 0.9999999) / parseFloatWithCommas(orderPrice)).toString();
  };

  const isQuantityGreaterThanMax = () => {
    if (orderQuantity.length === 0 || parseFloatWithCommas(orderQuantity) === 0) {
      return false;
    }
    if (orderPrice.length === 0 || parseFloatWithCommas(orderPrice) === 0) {
      return false;
    }
    return quantityDecimalStringToBigInt(orderQuantity) > quantityDecimalStringToBigInt(getMaxQuantity());
  };

  const createOrderPayload = (
    price: string,
    quantity: string,
    selectedTicker: string,
    quoteCurrencyPublicKeyBase58Check: string,
  ) => {
    return {
      OperationType: OperationTypeWithFee.BID,
      QuoteCurrencyPublicKeyBase58Check: quoteCurrencyPublicKeyBase58Check,
      BaseCurrencyPublicKeyBase58Check: props.project.PublicKeyBase58Check,
      Quantity: stripCommas(quantity),
      Price: price,
      PriceCurrencyType:
        selectedTicker === USD_TICKER || selectedTicker === DESO_USDC_TICKER ? CurrencyType.usd : CurrencyType.quote,
      FillType: FillTypeWithFee.GOOD_TILL_CANCELLED,
      QuantityCurrencyType: CurrencyType.base,
    };
  };

  const debouncedSimulateOrder = useRef(
    debounce(
      async (price: string, quantity: string, selectedTicker: string, quoteCurrencyPublicKeyBase58Check: string) => {
        abortControllerRef.current = new AbortController();

        deso
          .simulateTokenOrderWithFee(
            createOrderPayload(price, quantity, selectedTicker, quoteCurrencyPublicKeyBase58Check),
          )
          .then((data) => {
            setOrderPreview(data);
            setOrderPreviewError('');
            setIsLoadingOrderDetails(false);
          })
          .catch((e) => {
            if (axios.isCancel(e)) {
              return;
            }

            setOrderPreviewError(getErrorMsg(e, false));
            setIsLoadingOrderDetails(false);
          });
      },
      500,
    ),
  ).current;

  const debouncedHideLoader = debounce(() => {
    setIsLoadingOrderDetails(false);
  }, 500);

  const updateOrder = (price: string, quantity: string, selectedTicker: string) => {
    setOrderPrice(stripCommas(price));
    setOrderQuantity(stripCommas(quantity));

    const quantityAsBigInt = quantityDecimalStringToBigInt(quantity);
    if (quantityAsBigInt === BigInt(0)) {
      // We need to debounce again here to clear previously scheduled updates
      setOrderPreviewError('');
      setOrderPreview(null);
      debouncedHideLoader();

      return;
    }

    const numberPrice = Number(price);
    if (numberPrice === 0 || !Number.isFinite(numberPrice)) {
      return;
    }

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setIsLoadingOrderDetails(true);
    debouncedSimulateOrder(price, quantity, selectedTicker, quoteCurrencyPublicKey);
  };

  useEffect(() => {
    if (props.limitOrderPriceFromOrderBook) {
      const updatedPrice =
        ticker === USD_TICKER
          ? props.limitOrderPriceFromOrderBook.usdPrice * 1.0000001
          : props.limitOrderPriceFromOrderBook.price;
      setOrderPrice(stripCommas(updatedPrice.toLocaleString('en-US', { maximumFractionDigits: 38 })));
    }
  }, [props.limitOrderPriceFromOrderBook]);

  return (
    <div className="border border-border-light rounded-2xl p-4">
      <div>
        <CurrencyInputWithTickerToggle
          containerClasses="mb-4"
          labelText={
            quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY ? `Limit price (${DESO_USDC_TICKER})` : 'Limit Price'
          }
          value={
            props.limitOrderPriceFromOrderBook
              ? ticker === USD_TICKER
                ? (props.limitOrderPriceFromOrderBook.usdPrice * 1.0000001).toLocaleString('en-US', {
                    maximumFractionDigits: 38,
                  })
                : props.limitOrderPriceFromOrderBook.price.toLocaleString('en-US', { maximumFractionDigits: 38 })
              : orderPrice
          }
          defaultTicker={usdTickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}
          alternateTicker={tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}
          hideHint={quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY}
          allowedDecimalPlaces={DESO_INPUT_DECIMAL_PLACES}
          onStateChange={(v, t) => {
            setTicker(t);
            updateOrder(v, orderQuantity, t);
          }}
          hideTicker={quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY}
        />
      </div>
      <div>
        <NumberInputWithMaxToggle
          labelText="Purchase Amount"
          value={orderQuantity}
          state={isQuantityGreaterThanMax() ? 'error' : 'default'}
          allowedDecimalPlaces={QUANTITY_INPUT_DECIMAL_PLACES}
          onMax={
            priceDecimalStringToBigInt(orderPrice) === BigInt(0)
              ? undefined
              : () => {
                  const max = getMaxQuantity();
                  updateOrder(orderPrice, max, ticker);
                  return max;
                }
          }
          onChange={(e) => updateOrder(orderPrice, stripCommas(e.target.value), ticker)}
        />
      </div>
      {isLoadingOrderDetails ? (
        <LoadingOrderDetails />
      ) : orderPreviewError !== '' ? (
        <ErrorOrderDetails errorMsg={orderPreviewError} />
      ) : (
        <BidSideOrderDetails
          projectUsername={props.project.Username}
          projectPublicKey={props.project.PublicKeyBase58Check}
          orderPreview={orderPreview}
          isLimitOrder={true}
          spendLabelText="Total"
          receiveLabelText="Receive Amount"
        />
      )}
      <ButtonNew
        loading={props.refreshing}
        className="w-full"
        disabled={parseFloatWithCommas(orderPreview?.LimitReceiveAmount || '0') <= 0 || orderPreviewError !== ''}
        onClick={async () => {
          const payload = createOrderPayload(orderPrice, orderQuantity, ticker, quoteCurrencyPublicKey);
          const totalToSpendInDesoNanos = desoToDesoNanos(totalAmountToSpendInQuoteCurrency);
          trackingLogEvent('market : createLimitOrder : start', {
            ...payload,
            totalToSpendInDesoNanos,
          });

          props.setRefreshing(true);
          deso
            .createTokenMarketOrderWithFee(payload)
            .then(({ submittedTransactionResponse }) => {
              trackingLogEvent('market : createLimitOrder : success', {
                ...payload,
                totalToSpendInDesoNanos,
              });
              return deso.addProjectPurchasedToProfile(props.project.PublicKeyBase58Check).then(() => {
                props.onOrderSubmitSuccess(submittedTransactionResponse.TxnHashHex);
                updateOrder(orderPrice, '0', ticker);
              });
            })
            .catch((e) => {
              trackingLogEvent('market : createLimitOrder : error', {
                ...payload,
                totalToSpendInDesoNanos,
                error: getErrorMsg(e),
              });
              props.onOrderSubmitFailure(e);
              props.setRefreshing(false);
            });
        }}
      >
        Buy {formatProjectTokensLabel(props.project.Username)}
      </ButtonNew>
    </div>
  );
}

function LimitOrderAskCreator(props: {
  project: ProfileEntryResponse;
  refreshing: boolean;
  setRefreshing: (refreshing: boolean) => void;
  userTokenBalanceBaseUnits: bigint;
  userWalletBalanceInDESONanos: bigint;
  onOrderSubmitSuccess: (txnHashHex: string) => void;
  onOrderSubmitFailure: (e: any) => void;
  limitOrderPriceFromOrderBook: { price: number; usdPrice: number } | null;
}) {
  const { quoteCurrencyPublicKey, usdTickerByQuoteCurrencyPublicKey, tickerByQuoteCurrencyPublicKey } =
    useContext(QuoteCurrencyContext);

  const [orderPrice, setOrderPrice] = useState('0');
  const [orderQuantity, setOrderQuantity] = useState('0');

  const [ticker, setTicker] = useState<string>(usdTickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]);

  const [isLoadingOrderDetails, setIsLoadingOrderDetails] = useState(false);
  const [orderPreviewError, setOrderPreviewError] = useState('');
  const [orderPreview, setOrderPreview] = useState<null | DeSoTokenMarketOrderWithFeeResponse>(null);

  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    // Reset everything when legacy view toggle is switched
    setOrderPrice('0');
    setOrderQuantity('0');

    setOrderPreview(null);

    setOrderPreviewError('');
  }, [quoteCurrencyPublicKey]);

  const createOrderPayload = (
    price: string,
    quantity: string,
    selectedTicker: string,
    quoteCurrencyPublicKeyBase58Check: string,
  ) => {
    return {
      OperationType: OperationTypeWithFee.ASK,
      QuoteCurrencyPublicKeyBase58Check: quoteCurrencyPublicKeyBase58Check,
      BaseCurrencyPublicKeyBase58Check: props.project.PublicKeyBase58Check,
      Quantity: stripCommas(quantity),
      Price: price,
      PriceCurrencyType:
        selectedTicker === USD_TICKER || selectedTicker === DESO_USDC_TICKER ? CurrencyType.usd : CurrencyType.quote,
      FillType: FillTypeWithFee.GOOD_TILL_CANCELLED,
      QuantityCurrencyType: CurrencyType.base,
    };
  };

  const debouncedSimulateOrder = useRef(
    debounce(
      async (price: string, quantity: string, selectedTicker: string, quoteCurrencyPublicKeyBase58Check: string) => {
        abortControllerRef.current = new AbortController();

        deso
          .simulateTokenOrderWithFee(
            createOrderPayload(price, quantity, selectedTicker, quoteCurrencyPublicKeyBase58Check),
          )
          .then((data) => {
            setOrderPreview(data);
            setOrderPreviewError('');
            setIsLoadingOrderDetails(false);
          })
          .catch((e) => {
            if (axios.isCancel(e)) {
              return;
            }

            setOrderPreviewError(getErrorMsg(e, false));
            setIsLoadingOrderDetails(false);
          });
      },
    ),
  ).current;

  const updateOrder = (price: string, quantity: string, selectedTicker: string) => {
    setOrderPrice(stripCommas(price));
    setOrderQuantity(stripCommas(quantity));

    const quantityAsBigInt = quantityDecimalStringToBigInt(quantity);
    if (quantityAsBigInt === BigInt(0)) {
      // We need to debounce again here to clear previously scheduled updates
      setOrderPreviewError('');
      setOrderPreview(null);

      setTimeout(() => {
        setIsLoadingOrderDetails(false);
      }, 0);
      return;
    }

    const numberPrice = Number(price);
    if (numberPrice === 0 || !Number.isFinite(numberPrice)) {
      return;
    }

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    setIsLoadingOrderDetails(true);
    debouncedSimulateOrder(price, quantity, selectedTicker, quoteCurrencyPublicKey);
  };

  const isDesoProfile = props.project.PublicKeyBase58Check === DESO_TOKEN_PUBLIC_KEY;
  const userTokenBalance = isDesoProfile ? props.userWalletBalanceInDESONanos : props.userTokenBalanceBaseUnits;
  const scalingFactor = isDesoProfile ? oneE9 : oneE18;

  useEffect(() => {
    if (props.limitOrderPriceFromOrderBook) {
      const updatedPrice =
        ticker === USD_TICKER
          ? props.limitOrderPriceFromOrderBook.usdPrice * 0.9999999
          : props.limitOrderPriceFromOrderBook.price;
      setOrderPrice(stripCommas(updatedPrice.toString()));
    }
  }, [props.limitOrderPriceFromOrderBook]);

  return (
    <div className="border border-border-light p-4">
      <div>
        <CurrencyInputWithTickerToggle
          containerClasses="mb-4"
          labelText={
            quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY
              ? `Limit price (${tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]})`
              : 'Limit Price'
          }
          value={
            props.limitOrderPriceFromOrderBook
              ? ticker === USD_TICKER
                ? (props.limitOrderPriceFromOrderBook.usdPrice * 0.9999999).toLocaleString('en-US', {
                    maximumFractionDigits: 38,
                  })
                : props.limitOrderPriceFromOrderBook.price.toLocaleString('en-US', { maximumFractionDigits: 38 })
              : orderPrice
          }
          defaultTicker={usdTickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}
          alternateTicker={tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}
          allowedDecimalPlaces={DESO_INPUT_DECIMAL_PLACES}
          onStateChange={(v, t) => {
            setTicker(t);
            updateOrder(v, orderQuantity, t);
          }}
          hideTicker={quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY}
        />
      </div>
      <div>
        <NumberInputWithMaxToggle
          labelText="Sell Amount"
          value={orderQuantity}
          allowedDecimalPlaces={QUANTITY_INPUT_DECIMAL_PLACES}
          state={quantityDecimalStringToBigInt(orderQuantity, scalingFactor) > userTokenBalance ? 'error' : 'default'}
          onMax={() => {
            const tokenBalanceMax = isDesoProfile ? userTokenBalance - RESERVED_DESO_NANOS_FOR_FEES : userTokenBalance;
            if (tokenBalanceMax <= BigInt(0)) {
              // @stas - we should probably have a warning here?
              updateOrder(orderPrice, '0', ticker);
              return '0';
            }
            updateOrder(orderPrice, quantityBigIntToDecmialString(tokenBalanceMax, -1, scalingFactor), ticker);
            return quantityBigIntToDecmialString(tokenBalanceMax, -1, scalingFactor);
          }}
          onChange={(e) => updateOrder(orderPrice, e.target.value, ticker)}
          hint={getTokenHoldingDescription(
            props.project.Username,
            quantityBigIntToDecmialString(userTokenBalance, SUMMARY_DECIMAL_PLACES, scalingFactor),
          )}
        />
      </div>
      {isLoadingOrderDetails ? (
        <LoadingOrderDetails />
      ) : orderPreviewError !== '' ? (
        <ErrorOrderDetails errorMsg={orderPreviewError} />
      ) : (
        <AskSideOrderDetails
          projectUsername={props.project.Username}
          projectPublicKey={props.project.PublicKeyBase58Check}
          orderPreview={orderPreview}
          isLimitOrder={true}
          sellLabelText="Total"
          receiveLabelText="Receive Amount"
        />
      )}
      <ButtonNew
        loading={props.refreshing}
        disabled={parseFloatWithCommas(orderQuantity) <= 0 || orderPreviewError !== ''}
        className="w-full"
        onClick={async () => {
          const payload = createOrderPayload(orderPrice, orderQuantity, ticker, quoteCurrencyPublicKey);
          trackingLogEvent('market : createLimitOrder : start', {
            ...payload,
          });

          props.setRefreshing(true);
          deso
            .createTokenMarketOrderWithFee(payload)
            .then(({ submittedTransactionResponse }) => {
              trackingLogEvent('market : createLimitOrder : success', {
                ...payload,
              });
              props.onOrderSubmitSuccess(submittedTransactionResponse.TxnHashHex);
              updateOrder(orderPrice, '0', ticker);
            })
            .catch((e) => {
              trackingLogEvent('market : createLimitOrder : error', {
                ...payload,
                error: getErrorMsg(e),
              });
              props.onOrderSubmitFailure(e);
              props.setRefreshing(false);
            });
        }}
      >
        Sell {formatProjectTokensLabel(props.project.Username)}
      </ButtonNew>
    </div>
  );
}

function BidSideSelector(props: { isSelected: boolean; onClick: () => void }) {
  const colorClass = props.isSelected ? 'stroke-green-500' : 'text-muted';
  return (
    <SideSelector colorClass={colorClass} label="Buy" {...props}>
      <CubeIcon stroke={props.isSelected ? 'text-green-500' : 'currentColor'} />
      <div className={`pl-2 ${props.isSelected ? 'text-green-500' : 'text-foreground'}`}>Buy</div>
    </SideSelector>
  );
}

function AskSideSelector(props: { isSelected: boolean; onClick: () => void }) {
  const colorClass = props.isSelected ? 'stroke-red-500' : 'text-muted';
  return (
    <SideSelector colorClass={colorClass} label="Sell" {...props}>
      <ConcentricCirclesIcon stroke={props.isSelected ? 'text-red-500' : 'currentColor'} />
      <div className={`pl-2 ${props.isSelected ? 'text-red-500' : 'text-foreground'}`}>Sell</div>
    </SideSelector>
  );
}

function SideSelector(props: {
  children: ReactNode;
  isSelected: boolean;
  onClick: () => void;
  colorClass: string;
  label: string;
}) {
  return (
    <div
      className={`pb-4 pr-4 flex text-base items-center hover:underline underline-offset-4 ${props.colorClass}`}
      role="button"
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}

function CubeIcon(props: { stroke: string }) {
  return (
    <svg className="ionicon s-ion-icon" viewBox="0 0 512 512" width="24" height="100%">
      <path
        fillOpacity="0"
        stroke={props.stroke}
        strokeWidth="30"
        d="M448 341.37V170.61A32 32 0 00432.11 143l-152-88.46a47.94 47.94 0 00-48.24 0L79.89 143A32 32 0 0064 170.61v170.76A32 32 0 0079.89 369l152 88.46a48 48 0 0048.24 0l152-88.46A32 32 0 00448 341.37z"
      />
      <path fillOpacity="0" stroke={props.stroke} strokeWidth="30" d="M69 153.99l187 110 187-110M256 463.99v-200" />
    </svg>
  );
}

function ConcentricCirclesIcon(props: { stroke: string }) {
  return (
    <svg viewBox="0 0 512 512" width="24" height="100%">
      <circle fillOpacity="0" stroke={props.stroke} strokeWidth="30" cx="256" cy="256" r="208" strokeMiterlimit="10" />
      <circle fillOpacity="0" stroke={props.stroke} strokeWidth="30" cx="256" cy="256" r="96" strokeMiterlimit="10" />
      <circle fillOpacity="0" stroke={props.stroke} strokeWidth="30" cx="256" cy="256" r="32" />
    </svg>
  );
}

function FillTypeSelector(props: { value: string; onChange: (v: string) => void }) {
  return (
    <>
      <div className="pb-2 text-sm font-medium">Fill Type</div>
      <SelectNew
        onValueChange={(value) => {
          props.onChange(value);
        }}
        defaultValue={props.value}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select fill type" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value={IMMEDIATE_OR_CANCEL}>Immediate Or Cancel</SelectItem>
          <SelectItem value={FILL_OR_KILL}>Fill Or Kill</SelectItem>
        </SelectContent>
      </SelectNew>

      <div className="text-muted leading-5 mt-2 text-xs">
        {props.value === IMMEDIATE_OR_CANCEL
          ? 'This will fill as much of the order amount as possible, and cancel the remainder.'
          : props.value === FILL_OR_KILL
            ? 'This will try to fill the entire order amount immediately. If this is not possible, the entire order will be cancelled.'
            : ''}
      </div>
    </>
  );
}

function LoadingOrderDetails() {
  return (
    <>
      <div className="pt-4 block font-medium">Order Preview</div>
      <div>
        <div className="bg-gray-eee pt-1 rounded-xl mb-4 mt-2 text-center">
          <Spinner />
        </div>
      </div>
    </>
  );
}

function ErrorOrderDetails(props: { errorMsg: string }) {
  return (
    <>
      <div className="pt-4 block font-medium">Order Preview</div>
      <div>
        <div className="bg-gray-eee rounded-xl p-4 text-start">
          <b>Invalid Order</b> - {props.errorMsg}
        </div>
      </div>
    </>
  );
}

function BidSideOrderDetails(props: {
  projectUsername: string;
  projectPublicKey: string;
  orderPreview: DeSoTokenMarketOrderWithFeeResponse | null;
  isLimitOrder: boolean;
  spendLabelText?: string;
  receiveLabelText?: string;
}) {
  const YOU_WILL_SPEND_LABEL = props.spendLabelText ?? 'You will spend';
  const YOU_WILL_RECEIVE_LABEL = props.receiveLabelText ?? 'You will receive';

  const { quoteCurrencyPublicKey, tickerByQuoteCurrencyPublicKey } = useContext(QuoteCurrencyContext);
  const tokenDisplayName = formatProjectTokensLabel(props.projectUsername);

  const numTokens = props.orderPreview
    ? parseFloatWithCommas(
        props.isLimitOrder ? props.orderPreview.LimitReceiveAmount : props.orderPreview.ExecutionReceiveAmount,
      )
    : 0;
  const amountInQuoteCurrency = props.orderPreview
    ? parseFloatWithCommas(props.isLimitOrder ? props.orderPreview.LimitAmount : props.orderPreview.ExecutionAmount)
    : 0;
  const amountInQuoteCurrencyUsd = props.orderPreview
    ? parseFloatWithCommas(
        props.isLimitOrder ? props.orderPreview.LimitAmountInUsd : props.orderPreview.ExecutionAmountUsd,
      )
    : 0;

  return (
    <>
      <div className="pt-4">
        <div className="bg-accent border border-border rounded-xl mb-2 mt-2">
          <div className="border-b border-border-light p-2 text-sm">
            <div className="flex items-center justify-between">
              <Text className="text-xs text-muted">{YOU_WILL_SPEND_LABEL}</Text>
              <Text className="text-xs text-muted-foreground" tag="div">
                {quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY && (
                  <>
                    <Text tag="div">
                      {formatUSD(amountInQuoteCurrencyUsd, true, SUMMARY_DECIMAL_PLACES)} {USD_TICKER}{' '}
                    </Text>
                  </>
                )}

                {(quoteCurrencyPublicKey === DESO_ZERO_PUBLIC_KEY ||
                  quoteCurrencyPublicKey === FOCUS_TOKEN_PUBLIC_KEY) && (
                  <div className="text-right">
                    <Text tag="div" className="font-mono text-xs">
                      ~{formatUSD(amountInQuoteCurrencyUsd, true, SUMMARY_DECIMAL_PLACES)} {USD_TICKER}{' '}
                    </Text>
                    <Text tag="div" className="font-medium text-muted text-xs">
                      {formatDecimalValue(amountInQuoteCurrency, SUMMARY_DECIMAL_PLACES, SUMMARY_DECIMAL_PLACES)}{' '}
                      {tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}
                    </Text>
                  </div>
                )}
              </Text>
            </div>
          </div>
          <div className="flex justify-between p-2 text-sm border-b border-border-light">
            <Text className="text-xs text-muted flex items-center gap-1" tag="div">
              {YOU_WILL_RECEIVE_LABEL}
              {YouWillReceiveTooltip}
            </Text>
            <Text className="text-xs text-muted-foreground flex flex-col font-mono items-end">
              <Text tag="span" className="font-bold">
                {formatDecimalValue(numTokens, SUMMARY_DECIMAL_PLACES, SUMMARY_DECIMAL_PLACES)}{' '}
              </Text>
              <span className="text-muted">{tokenDisplayName}</span>
            </Text>
          </div>

          {!props.isLimitOrder && (
            <div className="flex justify-between p-2 border-b border-border-light text-sm">
              <PricePerTokenSection
                orderPreview={props.orderPreview}
                projectUsername={props.projectUsername}
                projectPublicKey={props.projectPublicKey}
              />
            </div>
          )}

          <div className="flex justify-between p-2 text-sm">
            <DesoNetworkFeeSection orderPreview={props.orderPreview} />
          </div>
        </div>

        <div className="px-2 py-2 my-2 mb-2 flex items-end justify-end ml-auto">
          <Text className="text-xs flex items-center gap-1" tag="div">
            <InfoTooltip
              text={
                <PricePerToken
                  leadingText={`Openfund allows a token's creator to set fees for their market (thanks to the DeSo DEX). These fees are already included in the price shown, and they are listed below. All fees are taker-only right now (maker fees are zero). This means a Limit Order will only incur fees on the portion that executes immediately.`}
                  publicKey={props.projectPublicKey}
                  username={props.projectUsername}
                />
              }
              iconSize={16}
            >
              <div className="flex items-center gap-1 cursor-pointer">
                <span className="text-muted">Trading Fees</span>
                <LuInfo className="w-4 h-4 text-muted" />
              </div>
            </InfoTooltip>
          </Text>
        </div>

        {props.isLimitOrder && props.orderPreview && parseFloatWithCommas(props.orderPreview.ExecutionAmount) > 0 && (
          <>
            <div className="pb-2 text-sm text-foreground font-medium text-md flex items-center gap-1">
              Immediate Execution
              <InfoTooltip
                iconSize={20}
                text={
                  'Great news! Some or all of your order will execute immediately. The amount that will execute immediately is shown below, and includes all fees that will be charged. Any amount not executed will remain resting on the book. Note that this is just an estimate, and actual execution amount can vary if the market moves quickly.'
                }
              />
            </div>

            <div className="bg-accent border border-border-light rounded-xl mb-2 mt-2">
              <div className="border-b border-border-light p-2 text-sm">
                <div className="flex justify-between">
                  <Text className="text-xs text-muted">Immediately Spend</Text>
                  <Text className="text-xs text-muted-foreground" tag="div">
                    {quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY && (
                      <>
                        <Text tag="div">
                          {formatUSD(
                            parseFloatWithCommas(props.orderPreview.ExecutionAmountUsd),
                            true,
                            SUMMARY_DECIMAL_PLACES,
                          )}{' '}
                          {USD_TICKER}{' '}
                        </Text>
                      </>
                    )}

                    {(quoteCurrencyPublicKey === DESO_ZERO_PUBLIC_KEY ||
                      quoteCurrencyPublicKey === FOCUS_TOKEN_PUBLIC_KEY) && (
                      <div className="text-right">
                        <Text tag="div">
                          ~
                          {formatUSD(
                            parseFloatWithCommas(props.orderPreview.ExecutionAmountUsd),
                            true,
                            SUMMARY_DECIMAL_PLACES,
                          )}{' '}
                          {USD_TICKER}{' '}
                        </Text>
                        <Text tag="div" className="font-medium text-muted text-xs text-md">
                          {formatDecimalValue(amountInQuoteCurrency, SUMMARY_DECIMAL_PLACES, SUMMARY_DECIMAL_PLACES)}{' '}
                          {tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}
                        </Text>
                      </div>
                    )}
                  </Text>
                </div>
              </div>

              <div className="flex justify-between p-2 text-sm border-b border-border-light">
                <Text className="text-xs text-muted">Immediately Receive</Text>
                <Text className="text-xs text-muted-foreground text-right">
                  <Text tag="span" className="font-bold">
                    {formatDecimalValue(
                      props.orderPreview.ExecutionReceiveAmount,
                      SUMMARY_DECIMAL_PLACES,
                      SUMMARY_DECIMAL_PLACES,
                    )}{' '}
                  </Text>
                  <span className="font-medium text-muted text-xs text-md">{tokenDisplayName}</span>
                </Text>
              </div>

              <div className="flex justify-between p-2 text-sm">
                <Text className="text-xs text-muted flex items-center gap-1" tag="div">
                  Execution Price
                  <InfoTooltip
                    iconSize={16}
                    text={<PricePerToken publicKey={props.projectPublicKey} username={props.projectUsername} />}
                  />
                </Text>
                <Text className="text-xs text-muted-foreground" tag="div">
                  {quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY && (
                    <>
                      <Text tag="div">
                        {formatUSD(
                          parseFloatWithCommas(props.orderPreview.ExecutionPriceInUsd),
                          true,
                          SUMMARY_DECIMAL_PLACES,
                        )}{' '}
                        {USD_TICKER}{' '}
                      </Text>
                    </>
                  )}

                  {(quoteCurrencyPublicKey === DESO_ZERO_PUBLIC_KEY ||
                    quoteCurrencyPublicKey === FOCUS_TOKEN_PUBLIC_KEY) && (
                    <div className="text-right">
                      <Text tag="div">
                        ~
                        {formatUSD(
                          parseFloatWithCommas(props.orderPreview.ExecutionPriceInUsd),
                          true,
                          SUMMARY_DECIMAL_PLACES,
                        )}{' '}
                        {USD_TICKER}{' '}
                      </Text>
                      <Text tag="div" className="font-medium text-muted text-xs text-md">
                        {formatDecimalValue(
                          props.orderPreview.ExecutionPriceInQuoteCurrency,
                          SUMMARY_DECIMAL_PLACES,
                          SUMMARY_DECIMAL_PLACES,
                        )}{' '}
                        {tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}
                      </Text>
                    </div>
                  )}
                </Text>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

function AskSideOrderDetails(props: {
  projectUsername: string;
  projectPublicKey: string;
  isLimitOrder: boolean;
  orderPreview: DeSoTokenMarketOrderWithFeeResponse | null;
  sellLabelText?: string;
  receiveLabelText?: string;
}) {
  const YOU_WILL_SELL_LABEL = props.sellLabelText ?? 'You will sell';
  const YOU_WILL_RECEIVE_LABEL = props.receiveLabelText ?? 'You will receive';

  const { quoteCurrencyPublicKey, tickerByQuoteCurrencyPublicKey } = useContext(QuoteCurrencyContext);
  const tokenDisplayName = formatProjectTokensLabel(props.projectUsername);

  const numTokens = props.orderPreview
    ? parseFloatWithCommas(props.isLimitOrder ? props.orderPreview.LimitAmount : props.orderPreview.ExecutionAmount)
    : 0;
  const amountInQuoteCurrency = props.orderPreview
    ? parseFloatWithCommas(
        props.isLimitOrder ? props.orderPreview.LimitReceiveAmount : props.orderPreview.ExecutionReceiveAmount,
      )
    : 0;
  const amountInQuoteCurrencyUsd = props.orderPreview
    ? parseFloatWithCommas(
        props.isLimitOrder ? props.orderPreview.LimitReceiveAmountInUsd : props.orderPreview.ExecutionReceiveAmountUsd,
      )
    : 0;

  return (
    <>
      <div>
        <div className="bg-accent border border-border rounded-2xl mb-2 mt-2">
          <div className="border-b border-border-light">
            <div className="flex justify-between p-2">
              <Text className="text-xs text-muted">{YOU_WILL_SELL_LABEL}</Text>
              <Text className="text-xs text-muted-foreground">
                <Text tag="span" className="font-bold">
                  {formatDecimalValue(numTokens, SUMMARY_DECIMAL_PLACES, SUMMARY_DECIMAL_PLACES)} {tokenDisplayName}
                </Text>
              </Text>
            </div>
          </div>
          <div className="border-b border-border-light">
            <div className="flex justify-between p-2">
              <Text className="text-xs text-muted flex items-center gap-1" tag="div">
                {YOU_WILL_RECEIVE_LABEL}
                {YouWillReceiveTooltip}
              </Text>
              <Text tag="div">
                <div className="text-right text-xs text-muted-foreground">
                  {quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY && (
                    <Text tag="div">
                      {formatUSD(amountInQuoteCurrencyUsd, true, SUMMARY_DECIMAL_PLACES)} {USD_TICKER}{' '}
                    </Text>
                  )}

                  {(quoteCurrencyPublicKey === DESO_ZERO_PUBLIC_KEY ||
                    quoteCurrencyPublicKey === FOCUS_TOKEN_PUBLIC_KEY) && (
                    <>
                      <Text tag="div">
                        ~{formatUSD(amountInQuoteCurrencyUsd, true, SUMMARY_DECIMAL_PLACES)} {USD_TICKER}{' '}
                      </Text>
                      <Text tag="div" className="font-medium text-muted text-xs text-md">
                        {formatDecimalValue(amountInQuoteCurrency, SUMMARY_DECIMAL_PLACES, SUMMARY_DECIMAL_PLACES)}{' '}
                        {tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}
                      </Text>
                    </>
                  )}
                </div>
              </Text>
            </div>
          </div>

          {!props.isLimitOrder && (
            <div className="flex justify-between p-2 border-b border-border-light text-sm">
              <PricePerTokenSection
                orderPreview={props.orderPreview}
                projectUsername={props.projectUsername}
                projectPublicKey={props.projectPublicKey}
              />
            </div>
          )}

          <div className="flex justify-between p-2 text-sm">
            <DesoNetworkFeeSection orderPreview={props.orderPreview} />
          </div>
        </div>

        <div className="px-2 py-2 my-2 mb-2 flex items-end justify-end ml-auto">
          <Text className="text-xs flex items-center gap-1" tag="div">
            <InfoTooltip
              text={
                <PricePerToken
                  leadingText={`Openfund allows a token's creator to set fees for their market (thanks to the DeSo DEX). These fees are already included in the price shown, and they are listed below. All fees are taker-only right now (maker fees are zero). This means a Limit Order will only incur fees on the portion that executes immediately.`}
                  publicKey={props.projectPublicKey}
                  username={props.projectUsername}
                />
              }
              iconSize={16}
            >
              <div className="flex items-center gap-1 cursor-pointer">
                <span className="text-muted">Trading Fees</span>
                <LuInfo className="w-4 h-4 text-muted" />
              </div>
            </InfoTooltip>
          </Text>
        </div>

        {props.isLimitOrder && props.orderPreview && parseFloatWithCommas(props.orderPreview.ExecutionAmount) > 0 && (
          <>
            <div className="pb-2 block text-sm text-foreground font-medium text-md">
              Immediate Execution
              <InfoTooltip
                text={
                  'Great news! Some or all of your order will execute immediately. The amount that will execute immediately is shown below, and includes all fees that will be charged. Any amount not executed will remain resting on the book. Note that this is just an estimate, and actual execution amount can vary if the market moves quickly.'
                }
              />
            </div>

            <div className="bg-accent border border-border-light rounded-xl mb-2 mt-2">
              <div className="border-b border-border-light p-2 text-sm">
                <div className="flex justify-between">
                  <Text className="text-xs text-muted">Immediately Sell</Text>
                  <Text className="text-xs text-muted-foreground text-right">
                    <Text tag="span" className="font-bold">
                      {formatDecimalValue(
                        props.orderPreview.ExecutionAmount,
                        SUMMARY_DECIMAL_PLACES,
                        SUMMARY_DECIMAL_PLACES,
                      )}{' '}
                    </Text>
                    <span className="font-medium text-muted text-xs text-md">{tokenDisplayName}</span>
                  </Text>
                </div>
              </div>

              <div className="flex justify-between p-2 text-sm border-b border-border-light">
                <Text className="text-xs text-muted">Immediately Receive</Text>
                <Text className="text-xs text-muted-foreground" tag="div">
                  {quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY && (
                    <>
                      <Text tag="div">
                        {formatUSD(props.orderPreview.ExecutionReceiveAmountUsd, true, SUMMARY_DECIMAL_PLACES)}{' '}
                        {USD_TICKER}{' '}
                      </Text>
                    </>
                  )}

                  {(quoteCurrencyPublicKey === DESO_ZERO_PUBLIC_KEY ||
                    quoteCurrencyPublicKey === FOCUS_TOKEN_PUBLIC_KEY) && (
                    <div className="text-right">
                      <Text tag="div">
                        ~
                        {formatUSD(
                          parseFloatWithCommas(props.orderPreview.ExecutionReceiveAmountUsd),
                          true,
                          SUMMARY_DECIMAL_PLACES,
                        )}{' '}
                        {USD_TICKER}{' '}
                      </Text>
                      <Text tag="div" className="font-medium text-muted text-xs text-md">
                        {formatDecimalValue(
                          parseFloatWithCommas(props.orderPreview.ExecutionReceiveAmount),
                          SUMMARY_DECIMAL_PLACES,
                          SUMMARY_DECIMAL_PLACES,
                        )}{' '}
                        {tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}
                      </Text>
                    </div>
                  )}
                </Text>
              </div>

              <div className="flex justify-between p-2 text-sm">
                <Text className="text-xs text-muted flex items-center gap-1" tag="div">
                  Execution Price
                  <InfoTooltip
                    iconSize={16}
                    text={<PricePerToken publicKey={props.projectPublicKey} username={props.projectUsername} />}
                  />
                </Text>
                <Text className="text-xs text-muted-foreground" tag="div">
                  {quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY && (
                    <>
                      <Text tag="div">
                        {formatUSD(
                          parseFloatWithCommas(props.orderPreview.ExecutionPriceInUsd),
                          true,
                          SUMMARY_DECIMAL_PLACES,
                        )}{' '}
                        {USD_TICKER}{' '}
                      </Text>
                    </>
                  )}

                  {(quoteCurrencyPublicKey === DESO_ZERO_PUBLIC_KEY ||
                    quoteCurrencyPublicKey === FOCUS_TOKEN_PUBLIC_KEY) && (
                    <div className="text-right">
                      <Text tag="div">
                        ~
                        {formatUSD(
                          parseFloatWithCommas(props.orderPreview.ExecutionPriceInUsd),
                          true,
                          SUMMARY_DECIMAL_PLACES,
                        )}{' '}
                        {USD_TICKER}{' '}
                      </Text>
                      <Text tag="div" className="font-medium text-muted text-xs text-md">
                        {formatDecimalValue(
                          props.orderPreview.ExecutionPriceInQuoteCurrency,
                          SUMMARY_DECIMAL_PLACES,
                          SUMMARY_DECIMAL_PLACES,
                        )}{' '}
                        {tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}
                      </Text>
                    </div>
                  )}
                </Text>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

function getTokenHoldingDescription(projectUsername: string, userTokenBalanceDecimalString: string) {
  return (
    <>
      You have <span className="text-muted-foreground">{userTokenBalanceDecimalString}</span>{' '}
      {formatProjectTokensLabel(projectUsername)} in your wallet that are available to trade
    </>
  );
}
