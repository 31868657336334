import { USDValueWithDESO } from 'components/app-ui/USDValueWithDESO';
import { Avatar } from 'components/core/Avatar';
import { ExternalLink } from 'components/core/ExternalLink';
import { RouteLink } from 'components/core/RouteLink';
import { NFTDetails } from 'deso-protocol';
import { ReactNode, useContext } from 'react';
import { Routes } from 'RoutePaths';
import { desoNanosToDeso } from 'utils/currency';
import { linkifyText } from 'utils/text';
import { Button as ButtonNew } from 'components/shadcn/ui/button';
import { QuoteCurrencyContext } from '../../contexts/QuoteCurrencyContext';
import { DESO_ZERO_PUBLIC_KEY } from '../../constants/AppConstants';

const MAX_DESCRIPTION_CHARS = 100;
interface NFTCarouselItemProps {
  nft: NFTDetails;
}
export function NFTCard({ nft }: NFTCarouselItemProps) {
  let nftContent: ReactNode = <></>;
  const post = nft.NFTCollectionResponse?.PostEntryResponse;
  const isTextOnlyNFT = !(post?.ImageURLs?.length || post?.VideoURLs?.length);
  const nftBodyChars = Array.from(post?.Body ?? '');
  const hasSeeMore = !isTextOnlyNFT && nftBodyChars.length > MAX_DESCRIPTION_CHARS;
  const nftDescriptionText = isTextOnlyNFT ? '' : linkifyText(nftBodyChars.slice(0, MAX_DESCRIPTION_CHARS).join(''));
  const diamondHref = `https://diamondapp.com/nft/${post?.PostHashHex}`;
  let hasCopiesForSale = false;
  let lowestBidAmount: number | undefined;
  let lowestBuyNowPrice: number | undefined;
  let lowestLastAcceptedBid: number | undefined;

  nft.NFTEntryResponses?.forEach((entry) => {
    if (!entry.IsForSale) {
      if (typeof lowestLastAcceptedBid === 'undefined' || lowestLastAcceptedBid > entry.LastAcceptedBidAmountNanos) {
        if (entry.LastAcceptedBidAmountNanos > 0) {
          lowestLastAcceptedBid = entry.LastAcceptedBidAmountNanos;
        }
      }
      return;
    }

    if (entry.IsBuyNow && entry.BuyNowPriceNanos > 0) {
      if (typeof lowestBuyNowPrice === 'undefined' || lowestBuyNowPrice > entry.BuyNowPriceNanos) {
        lowestBuyNowPrice = entry.BuyNowPriceNanos;
      }
    }

    const lowestBid = Math.max(entry.MinBidAmountNanos, entry.HighestBidAmountNanos);
    if (typeof lowestBidAmount === 'undefined' || lowestBidAmount > lowestBid) {
      lowestBidAmount = lowestBid;
    }

    hasCopiesForSale = true;
  });

  let nftPriceNanos = 0;

  if (hasCopiesForSale) {
    nftPriceNanos = (lowestBidAmount || lowestBuyNowPrice) ?? 0;
  } else {
    nftPriceNanos = lowestLastAcceptedBid ?? 0;
  }

  const { exchangeRates } = useContext(QuoteCurrencyContext);

  if (post?.ImageURLs?.[0]) {
    nftContent = <img src={post.ImageURLs[0]} alt="" className="w-full h-full object-cover object-center" />;
  } else if (post?.VideoURLs?.[0]) {
    nftContent = (
      <iframe
        title="NFT Video"
        className="w-full h-full object-center object-cover"
        rel="noopener noreferrer"
        sandbox="allow-scripts allow-same-origin allow-forms"
        src={post.VideoURLs[0] + '?autoplay=true&muted=true&loop=true&controls=true'}
        allow="picture-in-picture; clipboard-write; encrypted-media; gyroscope; accelerometer;"
      />
    );
  } else if (post?.Body) {
    nftContent = (
      <div
        className="p-4"
        dangerouslySetInnerHTML={{
          __html: linkifyText(post?.Body),
        }}
      ></div>
    );
  }

  return (
    <div>
      <div
        className={`w-full overflow-auto border border-border-light rounded-tl-2xl rounded-tr-2xl flex items-center justify-center`}
        style={{ height: '260px' }}
      >
        {nftContent}
      </div>
      <div className="w-full border border-border-light text-foreground rounded-bl-2xl rounded-br-2xl">
        <p
          className="p-4 h-[5.5rem] overflow-hidden"
          dangerouslySetInnerHTML={{
            __html: nftDescriptionText,
          }}
        ></p>
        <div className="h-4">
          {hasSeeMore && (
            <ExternalLink
              className="px-4 font-bold"
              size="sm"
              kind="text-only-light"
              target="_blank"
              href={diamondHref}
            >
              ...See more
            </ExternalLink>
          )}
        </div>

        <div className="flex items-end p-4 h-12 mt-8">
          <span className="text-sm font-bold">{hasCopiesForSale ? 'Current Price' : 'Not for sale'}</span>
          <span className="ml-auto text-sm">
            {exchangeRates && hasCopiesForSale && (
              <USDValueWithDESO
                align="right"
                desoValue={desoNanosToDeso(nftPriceNanos)}
                usdCentsPerDeSoExchangeRate={exchangeRates[DESO_ZERO_PUBLIC_KEY]}
              />
            )}
          </span>
        </div>
        <div className="border-t border-border-light p-4 flex items-center">
          <div className="flex items-center gap-2">
            <Avatar src={post?.PosterPublicKeyBase58Check} />
            <RouteLink to={Routes.fund(post?.ProfileEntryResponse?.Username ?? '')}>
              <span className="text-muted text-sm">
                {post?.ProfileEntryResponse?.Username ?? post?.PosterPublicKeyBase58Check}
              </span>
            </RouteLink>
          </div>
          <ExternalLink className="ml-auto" href={diamondHref} target="_blank">
            <ButtonNew variant="outline">{hasCopiesForSale ? 'Place a Bid' : 'View NFT'}</ButtonNew>
          </ExternalLink>
        </div>
      </div>
    </div>
  );
}
