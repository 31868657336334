import { ProfileNavItem } from 'components/app-ui/ProfileNavItem';
import { Logo } from 'components/core/Logo';
import { RouteLink } from 'components/core/RouteLink';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Routes } from 'RoutePaths';
import { Button, Button as ButtonNew } from 'components/shadcn/ui/button';
import { ThemeToggle } from 'components/shadcn/ui/theme-toggle';
import { LuBarChart, LuMenu, LuScrollText, LuSettings, LuWallet } from 'react-icons/lu';
import { Sheet, SheetContent, SheetTrigger } from 'components/shadcn/ui/sheet';
import UserSearch from '../core/UserSearch';
import { IS_TESTNET } from '../../constants/AppConstants';
import SiteBanner from './SiteBanner';
import { Skeleton } from '../shadcn/ui/skeleton';
import { Spinner } from '../core/Spinner';

export function SiteHeader() {
  return (
    <div id="site-header" className="w-full z-10 relative">
      {IS_TESTNET && (
        <SiteBanner id="testnet-banner" variant="error">
          This app is running on testnet. DO NOT SEND REAL MONEY TO THIS APP!
        </SiteBanner>
      )}
      <div className="hidden lg:block">
        <DesktopHeader />
      </div>
      <div className="lg:hidden">
        <MobileHeader />
      </div>
    </div>
  );
}

type NavLinksProps = {
  isMobile?: boolean;
};

const NavLinks = ({ isMobile = false }: NavLinksProps) => {
  const { currentUser, loadingUser } = useContext(OpenfundContext);
  const location = useLocation();
  const buttonClassName = isMobile ? 'w-full justify-start p-0 text-lg text-muted-foreground' : 'p-0';

  const isActiveRoute = (route: string) => {
    if (route === Routes.trade()) {
      return location.pathname.startsWith('/trade');
    }
    return location.pathname === route;
  };

  const navItems = [
    { label: 'Trade', route: Routes.trade(), icon: <LuBarChart className="mr-2 text-muted" /> },
    ...(currentUser
      ? [
          { label: 'Wallet', route: Routes.wallet(), icon: <LuWallet className="mr-2 text-muted" /> },
          { label: 'Settings', route: Routes.settingsProfile(), icon: <LuSettings className="mr-2 text-muted" /> },
        ]
      : []),
    {
      label: 'Docs',
      route: 'https://docs.deso.org/openfund/what-is-openfund',
      icon: <LuScrollText className="mr-2 text-muted" />,
      isExternal: true,
    },
  ];

  if (loadingUser) {
    return (
      <div className="flex flex-col md:flex-row md:items-center gap-4">
        <Skeleton className="w-[150px] h-8 bg-accent" />
        <Skeleton className="w-[150px] h-8 bg-accent" />
      </div>
    );
  }

  return (
    <>
      {navItems.map((item) =>
        item.isExternal ? (
          <a key={item.label} href={item.route} target="_blank" className="w-full" rel="noopener noreferrer">
            <Button className={buttonClassName} variant="ghost">
              {isMobile && item.icon}
              {item.label}
            </Button>
          </a>
        ) : (
          <RouteLink key={item.label} kind="text-only" to={item.route} className="w-full">
            <Button className={`${buttonClassName} ${isActiveRoute(item.route) ? 'text-primary' : ''}`} variant="ghost">
              {isMobile && item.icon}
              {item.label}
            </Button>
          </RouteLink>
        ),
      )}
    </>
  );
};

export function DesktopHeader() {
  const { currentUser, loadingUser } = useContext(OpenfundContext);

  return (
    <div className="w-full z-10 relative">
      <div className="flex m-auto w-full lg:flex lg:flex-row items-center p-4 border-b border-border">
        <div className="flex items-center gap-4">
          <span className="hidden lg:inline">
            <ThemeToggle />
          </span>
          <Link to={Routes.home()}>
            <Logo />
          </Link>
        </div>

        <nav className="hidden lg:flex flex-col items-center lg:flex-row lg:ml-auto">
          <div className="mt-8 lg:mt-0 flex items-center gap-4">
            <NavLinks />
            <UserSearch />
            {!loadingUser && <ProfileNavItem />}
          </div>
          {loadingUser ? (
            <Spinner size={36} className="mx-2" />
          ) : currentUser ? (
            <span className="mt-12 lg:mt-0 ml-4">
              {!!(currentUser?.ProfileEntryResponse && currentUser?.isProjectOwner) ? (
                <RouteLink to={Routes.fund(currentUser.ProfileEntryResponse?.Username)}>
                  <ButtonNew variant="default">My Project</ButtonNew>
                </RouteLink>
              ) : (
                <RouteLink to={Routes.wallet()}>
                  <ButtonNew variant="default">My Wallet</ButtonNew>
                </RouteLink>
              )}
            </span>
          ) : (
            <div className="flex items-center gap-4 ml-4">
              <RouteLink to={Routes.trade()}>
                <ButtonNew variant="default">Start Trading</ButtonNew>
              </RouteLink>
            </div>
          )}
        </nav>
      </div>
    </div>
  );
}

const MobileHeader = () => {
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsSheetOpen(false);
  }, [location]);

  return (
    <div className="flex justify-between m-auto w-full lg:flex lg:flex-row items-center p-2 border-b border-border bg-background">
      <div className="flex items-center gap-0">
        <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
          <SheetTrigger asChild>
            <Button variant="ghost" size="icon" className="p-0">
              <LuMenu className="size-6" />
            </Button>
          </SheetTrigger>
          <SheetContent side="left">
            <nav className="flex flex-col">
              <div className="flex items-center gap-2 mb-4">
                <Logo width="100" />
                <ThemeToggle />
              </div>
              <div className="mb-4">
                <UserSearch searchWidth="100%" />
              </div>
              <NavLinks isMobile />
            </nav>
          </SheetContent>
        </Sheet>
        <Link to={Routes.home()}>
          <Logo width="100" />
        </Link>
      </div>
      <div className="flex items-center gap-4">
        <ProfileNavItem />
      </div>
    </div>
  );
};
