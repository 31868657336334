/* This example requires Tailwind CSS v2.0+ */
import { IoRadioButtonOff } from '@react-icons/all-files/io5/IoRadioButtonOff';
import { IoRadioButtonOn } from '@react-icons/all-files/io5/IoRadioButtonOn';
import { Button } from 'components/core/Button';
import { Spinner } from 'components/core/Spinner';
import { Text } from 'components/core/Text';
import { Badge } from 'components/shadcn/ui/badge';
import { DERIVED_KEY_PURPOSES } from 'constants/DerivedKeysConstants';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { PostEntryResponse, User } from 'deso-protocol';
import { filter, find } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import Chart, { Props } from 'react-apexcharts';
import { calcOwnershipPercent, formatDecimalValue } from 'utils/currency';
import { linkifyText } from 'utils/text';
import { useToast } from '../../hooks/useToast';
import { deso, openfund } from '../../services';
import { GetExchangeRateUpdatedResponse } from '../../services/Deso';
import { Proposal, ProposalTallyResult } from '../../services/Openfund';
import { Sheet, SheetContent, SheetHeader, SheetTrigger } from '../shadcn/ui/sheet';
import { ActivityFeedItem } from './ActivityFeedItem';

interface ProposalSlideOver {
  show: boolean;
  closeSlideOver: Function;
  proposal: Proposal;
  proposalUser: User;
  trigger: React.ReactNode;
  diamondLevelMap: {
    [key: number]: number;
  };
  exchangeRates: GetExchangeRateUpdatedResponse;
}

function getDefaultProposalTally(): ProposalTallyResult {
  return {
    PollOptionResults: [],
    TotalCoinsVotedHex: '',
    TotalCoinsInCirculationHex: '',
    TotalProfilesParticipating: 0,
  };
}

export default function ProposalSlideOver({
  show,
  closeSlideOver,
  proposal,
  proposalUser,
  trigger,
  diamondLevelMap,
  exchangeRates,
}: ProposalSlideOver) {
  const { currentUser } = useContext(OpenfundContext);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [proposalTally, setProposalTally] = useState<ProposalTallyResult>(getDefaultProposalTally());
  const [postComments, setPostComments] = useState<PostEntryResponse[]>([]);
  const [userSelectedIndex, setUserSelectedIndex] = useState(-1);
  const [canUserVote, setCanUserVote] = useState(false);
  const derivedKey = useRef('');
  const toast = useToast();

  useEffect(() => {
    if (show) {
      refreshData();
    }
  }, [show, currentUser?.PublicKeyBase58Check]);

  function refreshData() {
    setIsLoading(true);
    Promise.all([
      openfund.getProposalTally(proposal.PostHashHex).then((proposalTallyResult) => {
        setProposalTally(proposalTallyResult);
      }),
      deso.getSinglePost(proposal.PostHashHex, { CommentLimit: 30 }).then((postResult) => {
        if (postResult.PostFound?.Comments !== undefined && postResult.PostFound?.Comments !== null) {
          setPostComments(postResult.PostFound.Comments);
        }
      }),
      deso
        .getSinglePost(!!proposal.SummaryCommentHashHex ? proposal.SummaryCommentHashHex : proposal.PostHashHex, {
          CommentLimit: 30,
        })
        .then((postResult) => {
          if (postResult.PostFound?.Comments !== undefined && postResult.PostFound?.Comments !== null) {
            extractAndSetUserSelectedIndex(postResult.PostFound.Comments);
          }
        }),
      deso.getProjectHolders(proposal.DaoUserPkidBase58Check).then((res) => {
        setCanUserVote(
          (res.Hodlers?.filter((d) => d.HODLerPublicKeyBase58Check === currentUser?.PublicKeyBase58Check)?.length ??
            0) > 0,
        );
      }),
      currentUser &&
        openfund
          .createDerivedKey(currentUser.PublicKeyBase58Check, DERIVED_KEY_PURPOSES.SOCIAL)
          .then(({ DerivedPublicKeyBase58Check }) => {
            derivedKey.current = DerivedPublicKeyBase58Check;
          }),
    ]).finally(() => {
      setIsLoading(false);
    });
  }

  function extractAndSetUserSelectedIndex(comments: PostEntryResponse[]) {
    const filteredComments = filter(comments, (postComment) => {
      return (
        postComment.PosterPublicKeyBase58Check === currentUser?.PublicKeyBase58Check &&
        postComment.PostExtraData?.IsVote === '1'
      );
    });
    if (filteredComments.length === 1 && Number.isInteger(Number(filteredComments[0].PostExtraData?.VoteSelection))) {
      setUserSelectedIndex(Number(filteredComments[0].PostExtraData?.VoteSelection));
    } else {
      setUserSelectedIndex(-1);
    }
  }

  async function voteOnProposal(optionSelected: number) {
    if (!currentUser) {
      return;
    }
    await openfund.voteInProposal(
      proposal.PostHashHex,
      currentUser.PublicKeyBase58Check,
      optionSelected,
      proposal.Options[optionSelected].Description,
      derivedKey.current,
      userSelectedIndex !== -1,
    );
    toast.show({
      message: 'Successfully voted on proposal!',
      type: 'success',
      sticky: false,
    });
    refreshData();
  }

  async function finalizePoll(): Promise<void> {
    await openfund.finalizePoll(proposal.PostHashHex, proposal.DaoUserPkidBase58Check, derivedKey.current);
    toast.show({
      message: 'Successfully finalized proposal!',
      type: 'success',
      sticky: false,
    });
    proposal.Status = 'Closed';
  }

  function getResultsForIndex(pollIndex: number): number {
    const pollOptionsResults = proposalTally?.PollOptionResults;
    if (pollOptionsResults === undefined) {
      return 0;
    }
    const optionResults = find(pollOptionsResults, { PollOptionIndex: pollIndex });
    if (optionResults?.PercentVotersInFavorBP === undefined) {
      return 0;
    }
    return optionResults?.PercentVotersInFavorBP / 100;
  }

  const chartOptions: Props = {
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      formatter: (v: any, c: any) => `${v}%`,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories:
        proposalTally.PollOptionResults?.map((option) => option.PollOption) ??
        proposal.Options.map((p) => p.Description),
    },
    yaxis: {},
    chart: {
      toolbar: {
        tools: {
          download: false,
        },
      },
    },
  };

  const chartSeries = [
    {
      name: '% Votes',
      data:
        proposalTally.PollOptionResults?.map((option) =>
          parseFloat(formatDecimalValue(option.PercentDaoInFavorBP * 0.01, 2, 2)),
        ) ?? proposal.Options.map(() => 0),
    },
  ];

  const chart =
    isLoading || !isOpen ? (
      <Spinner />
    ) : (
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={Math.max((proposalTally.PollOptionResults?.length ?? 0) * 70, 200)}
      />
    );

  return (
    <Sheet open={show} onOpenChange={(open) => !open && closeSlideOver()}>
      <SheetTrigger>{trigger}</SheetTrigger>
      <SheetContent side="right" className="w-full lg:max-w-[600px] p-0">
        <SheetHeader className="flex flex-row-reverse items-start justify-between px-4"></SheetHeader>
        <div className="relative flex-1">
          <div className="h-full" aria-hidden="true">
            <div className="p-6 pb-0">
              <div className="flex flex-col pb-4">
                <div className="m-0 self-start w-auto mb-4">
                  {proposal.DaoUserPkidBase58Check !== currentUser?.PublicKeyBase58Check ||
                  proposal.Status !== 'CREATED' ? (
                    <Badge variant={proposal.Status === 'CREATED' ? 'success' : 'destructive'}>
                      {proposal.Status === 'CREATED' ? 'Voting is open' : 'Voting has closed'}
                    </Badge>
                  ) : (
                    <Button kind="btn-primary-red" onClick={() => finalizePoll()} className="p-2 cursor-pointer">
                      <Text size="sm">Close Voting</Text>
                    </Button>
                  )}
                </div>
                <div>
                  <Text className="text-xl font-bold">{proposal.Title}</Text>
                </div>
              </div>
              <div
                className="mb-0 break-words text-sm description-links"
                dangerouslySetInnerHTML={{
                  __html: linkifyText(proposal.Description),
                }}
              ></div>
              {/* <div>
                  <Container className="p-4 w-full">{chart}</Container>
                </div> */}
            </div>
            <div className="border-b border-border p-6">
              <Text className="text-lg font-bold mb-2">Results</Text>
              {proposal.Options &&
                proposal.Options.map((proposalOption, i) => (
                  <div
                    key={i}
                    className="border border-border-light hover:border-border rounded-xl hover:bg-accent flex p-2 items-center mb-2 h-14"
                  >
                    <div className="flex items-center">
                      <div>
                        {proposal.Status === 'CREATED' && userSelectedIndex !== proposalOption.Index && (
                          <IoRadioButtonOff
                            onClick={() => voteOnProposal(proposalOption.Index)}
                            className="h-8 w-8 pl-1 pr-2 cursor-pointer hover:border-red opacity-40 hover:opacity-100"
                          ></IoRadioButtonOff>
                        )}
                        {proposal.Status === 'CREATED' && userSelectedIndex === proposalOption.Index && (
                          <IoRadioButtonOn className="h-8 w-8 pl-1 pr-2 hover:border-red"></IoRadioButtonOn>
                        )}
                      </div>
                    </div>
                    <div>
                      {!isLoading && (
                        <div className="w-12 text-right text-muted-foreground font-mono align-baseline ml-2 mr-5 text-sm font-semibold">
                          {getResultsForIndex(proposalOption.Index)}%
                        </div>
                      )}
                    </div>
                    <div className="w-full h-full">
                      <div
                        className="h-full flex items-center bg-card rounded-lg"
                        style={{
                          position: 'relative',
                          width: `${Math.floor(getResultsForIndex(proposalOption.Index))}%`,
                        }}
                      >
                        <Text className="pl-4 break-word text-xs lg:text-sm leading-4 min-w-[230px] lg:min-w-[280px]">
                          {proposalOption.Description}
                        </Text>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="flex mt-4">
                {!isLoading && (
                  <div className="text-muted text-sm">
                    {proposalTally.TotalProfilesParticipating} votes •{' '}
                    {formatDecimalValue(
                      calcOwnershipPercent(proposalTally.TotalCoinsVotedHex, proposalTally.TotalCoinsInCirculationHex),
                      2,
                      2,
                    )}
                    % of total minted tokens voted
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <div className="px-6 py-4 border-b border-border">
                <Text className="text-lg font-bold">Comments</Text>
              </div>
              {!isLoading &&
                postComments.map((postComment, i) => {
                  return (
                    <ActivityFeedItem
                      post={postComment}
                      key={postComment.PostHashHex}
                      className={i < postComments.length - 1 ? 'border-b border-gray-faint' : ''}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
}
