import React, { useContext, useMemo, useState } from 'react';
import { ProfileEntryResponse } from 'deso-protocol';
import { Tooltip } from 'react-tooltip';
import { desoToUSD } from '../../utils/currency';
import { getWrappedAsset } from '../../utils/deso';
import {
  buildPriceLevelBookBySide,
  OPERATION_TYPE,
  OPERATION_TYPES,
  priceBigIntToDecmialString,
  priceBigIntToFloat,
  PriceLevel,
  quantityBigIntToFloat,
} from '../../utils/orderbook';
import { DESO_USDC_PUBLIC_KEY } from '../../constants/AppConstants';
import { USD_TICKER } from '../../constants/TradeConstants';
import { QuoteCurrencyContext } from '../../contexts/QuoteCurrencyContext';
import { classNames } from '../../utils/classNames';
import LowNumFormatter from './LowNumFormatter';
import { Avatar } from '../core/Avatar';
import useIsMobile from '../../hooks/useIsMobile';
import { cn } from '../../utils/shadcn';
import { AccountSocialCard } from './AccountSocialCard';
import { TokenLimitOrderEntryResponse } from '../../services/Deso';

const SCROLL_HEIGHT = 288;

interface TooltipValue {
  total: {
    usd: number;
    base: number;
    quote: number;
  };
}

export function PriceLevelBook(props: {
  tokenOrderbook: TokenLimitOrderEntryResponse[];
  loggedInPublicKey: string;
  project: ProfileEntryResponse;
  onClickPriceRow: ({ price, usdPrice }: { price: number; usdPrice: number }) => void;
}) {
  const {
    quoteCurrencyPublicKey,
    denominatingCoinByQuoteCurrencyPublicKey,
    exchangeRates,
    usdTickerByQuoteCurrencyPublicKey,
  } = useContext(QuoteCurrencyContext);

  const { tokenOrderbook: selectedTokenOrderBook } = props;

  const [hoveredRowIndex, setHoveredRowIndex] = useState<{ side: OPERATION_TYPE; index: number } | null>(null);

  const denominatingToken = denominatingCoinByQuoteCurrencyPublicKey[quoteCurrencyPublicKey];
  const rate = exchangeRates[quoteCurrencyPublicKey];

  const bidPriceLevels = useMemo(
    () =>
      buildPriceLevelBookBySide(
        selectedTokenOrderBook,
        denominatingToken,
        OPERATION_TYPES.BID,
        props.loggedInPublicKey,
      ),
    [selectedTokenOrderBook, denominatingToken, props.loggedInPublicKey],
  );
  const askPriceLevels = useMemo(
    () =>
      buildPriceLevelBookBySide(
        selectedTokenOrderBook,
        denominatingToken,
        OPERATION_TYPES.ASK,
        props.loggedInPublicKey,
      ),
    [selectedTokenOrderBook, denominatingToken, props.loggedInPublicKey],
  );

  const minAskPrice = getMinPrice(askPriceLevels);
  const maxBidPrice = getMaxPrice(bidPriceLevels);

  const midPrice = minAskPrice === 0 || maxBidPrice === 0 ? 0 : (minAskPrice + maxBidPrice) / 2;

  const wrappedAsset = getWrappedAsset(props.project.Username);
  const isWrapped = !!wrappedAsset;
  const displayName = isWrapped ? wrappedAsset.displayName : props.project.Username;

  return (
    <div className="w-full">
      <div className="w-full border border-border">
        <div className="flex flex-col text-sm text-muted">
          <table className="w-full" cellPadding={0}>
            <thead>
              <PriceLevelTableHeaderRow side="Ask" tokenDisplayName={displayName} />
            </thead>
          </table>
        </div>
        <div
          className="flex flex-col border-t border-b border-border-light openfund-scrollbar overflow-y-scroll overflow-x-auto"
          style={{ height: SCROLL_HEIGHT, flexDirection: 'column-reverse' }}
        >
          {askPriceLevels.length === 0 ? (
            <div className="flex w-full h-full items-center justify-center text-muted text-center text-sm">
              There are no orders on
              <br />
              the market to sell ${displayName} tokens
            </div>
          ) : (
            <table className="mt-auto w-full" cellPadding={0}>
              <tbody className="divide-y divide-border-light">
                {askPriceLevels.map((priceLevel, i) => (
                  <PriceLevelRow
                    index={i}
                    priceLevels={askPriceLevels}
                    key={priceBigIntToDecmialString(priceLevel.Price)}
                    side={OPERATION_TYPES.ASK}
                    priceLevel={priceLevel}
                    tokenDisplayName={displayName}
                    hoveredRowIndex={hoveredRowIndex}
                    setHoveredRowIndex={setHoveredRowIndex}
                    onClick={props.onClickPriceRow}
                  />
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className="text-center text-xs text-muted py-2">
          Mid Price:{' '}
          <span className="text-muted-foreground font-normal font-mono">
            <LowNumFormatter
              price={desoToUSD(midPrice, rate)}
              className="text-xs"
              abbreviatePriceThreshold={Number.MAX_SAFE_INTEGER}
            />{' '}
            {usdTickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}
          </span>
        </div>
        <div className="border-t border-border-light">
          <div className="overflow-y-scroll overflow-x-hidden openfund-scrollbar" style={{ height: SCROLL_HEIGHT }}>
            {bidPriceLevels.length === 0 ? (
              <div className="flex w-full h-full items-center justify-center text-muted text-center text-sm">
                There are no orders on
                <br />
                the market to buy ${displayName} tokens
              </div>
            ) : (
              <table className="mt-auto w-full" cellPadding={0}>
                <tbody className="divide-y divide-border-light">
                  {bidPriceLevels.map((priceLevel, i) => (
                    <PriceLevelRow
                      index={i}
                      priceLevels={bidPriceLevels}
                      key={priceBigIntToDecmialString(priceLevel.Price)}
                      side={OPERATION_TYPES.BID}
                      priceLevel={priceLevel}
                      tokenDisplayName={displayName}
                      hoveredRowIndex={hoveredRowIndex}
                      setHoveredRowIndex={setHoveredRowIndex}
                      onClick={props.onClickPriceRow}
                    />
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function PriceLevelTableHeaderRow(props: { side: string; tokenDisplayName: string }) {
  const { quoteCurrencyPublicKey, tickerByQuoteCurrencyPublicKey, usdTickerByQuoteCurrencyPublicKey } =
    useContext(QuoteCurrencyContext);

  const priceAndTotalColumnTickerTitle = useMemo(() => {
    switch (quoteCurrencyPublicKey) {
      case DESO_USDC_PUBLIC_KEY:
        return usdTickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey];
      default:
        return `${usdTickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}/${tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}`;
    }
  }, [quoteCurrencyPublicKey]);

  return (
    <tr className="text-right bg-accent">
      <th className="text-left p-2 py-1 text-xs text-muted-foreground">
        Price
        <br /> <span className="text-xs text-muted font-normal">{priceAndTotalColumnTickerTitle}</span>
      </th>
      <th className="text-left p-2 py-1 text-xs text-muted-foreground">
        Size
        <br /> <span className="text-xs text-muted font-normal">${props.tokenDisplayName}</span>
      </th>
      <th className="text-right p-2 py-1 text-xs text-muted-foreground">
        Total
        <br /> <span className="text-xs text-muted font-normal">{priceAndTotalColumnTickerTitle}</span>
      </th>
    </tr>
  );
}

function PriceLevelRow(props: {
  side: string;
  index: number;
  priceLevels: PriceLevel[];
  priceLevel: PriceLevel;
  tokenDisplayName: string;
  hoveredRowIndex: { side: OPERATION_TYPE; index: number } | null;
  setHoveredRowIndex: (index: { side: OPERATION_TYPE; index: number } | null) => void;
  onClick: ({ price, usdPrice }: { price: number; usdPrice: number }) => void;
}) {
  const { exchangeRates, quoteCurrencyPublicKey, tickerByQuoteCurrencyPublicKey } = useContext(QuoteCurrencyContext);

  const color = props.side === OPERATION_TYPES.ASK ? 'text-red-600' : 'text-green-600';

  const totalValue = useMemo(() => {
    return priceBigIntToFloat(props.priceLevel.Price) * quantityBigIntToFloat(props.priceLevel.Quantity);
  }, [props.priceLevel]);

  const { isMobile, screenWidth } = useIsMobile();
  const hasMobileTooltip = screenWidth <= 1024;

  const quantityInQuoteCurrency = priceBigIntToFloat(props.priceLevel.Price);
  const usdPrice = desoToUSD(quantityInQuoteCurrency, exchangeRates[quoteCurrencyPublicKey]);

  const priceColumnValue = useMemo(() => {
    if (quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY) {
      return (
        <div role="button" tabIndex={0} className="flex items-center gap-2">
          <AccountSocialCard
            publicKey={props.priceLevel.TransactorPublicKeyBase58Check}
            popoverSide={hasMobileTooltip ? 'bottom' : 'right'}
          >
            <div className="flex items-center min-w-[25px]">
              <Avatar
                size="xs"
                src={props.priceLevel.TransactorPublicKeyBase58Check}
                className="bg-black border border-border"
              />
            </div>
          </AccountSocialCard>
          <div className="flex items-center gap-2">
            <span className="truncate max-w-[100px]"></span>
            <LowNumFormatter price={usdPrice} abbreviatePriceThreshold={1000000} className="text-xs font-mono" />
          </div>
        </div>
      );
    }

    return (
      <div role="button" tabIndex={0} className="flex items-center gap-2">
        <AccountSocialCard
          publicKey={props.priceLevel.TransactorPublicKeyBase58Check}
          popoverSide={hasMobileTooltip ? 'bottom' : 'right'}
        >
          <div className="flex items-center min-w-[25px]">
            <Avatar
              size="xs"
              src={props.priceLevel.TransactorPublicKeyBase58Check}
              className="bg-black border border-border"
            />
          </div>
        </AccountSocialCard>
        <div className="flex flex-col">
          <LowNumFormatter price={usdPrice} className="text-xs font-mono" />
          <LowNumFormatter price={quantityInQuoteCurrency} isUsd={false} className="text-xs text-left opacity-50" />
        </div>
      </div>
    );
  }, [props.priceLevel.Price, props.priceLevel.TransactorPublicKeyBase58Check, usdPrice]);

  const totalColumnValue = useMemo(() => {
    const formattedTotalUsdValue = desoToUSD(totalValue, exchangeRates[quoteCurrencyPublicKey]);

    if (quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY) {
      return <LowNumFormatter price={formattedTotalUsdValue} className="text-xs font-mono" />;
    }

    return (
      <div className="flex flex-col items-end justify-end">
        <LowNumFormatter price={formattedTotalUsdValue} className="text-xs font-mono text-muted-foreground" />
        <LowNumFormatter price={totalValue} isUsd={false} className="text-xs text-muted" />
      </div>
    );
  }, [props.priceLevel.Price]);

  const getTooltipValue = useMemo(() => {
    const [startIndex, endIndex] =
      props.side === OPERATION_TYPES.ASK ? [props.index, props.priceLevels.length] : [0, props.index + 1];

    const totals = props.priceLevels.slice(startIndex, endIndex).reduce(
      (acc: TooltipValue, v: PriceLevel) => {
        return {
          total: {
            usd:
              acc.total.usd +
              desoToUSD(
                priceBigIntToFloat(v.Price) * quantityBigIntToFloat(v.Quantity),
                exchangeRates[quoteCurrencyPublicKey],
              ),
            base: acc.total.base + quantityBigIntToFloat(v.Quantity),
            quote: acc.total.quote + priceBigIntToFloat(v.Price) * quantityBigIntToFloat(v.Quantity),
          },
        };
      },
      {
        total: {
          usd: 0,
          base: 0,
          quote: 0,
        },
      } as TooltipValue,
    );

    return (
      <div className="flex flex-col flex-wrap gap-2 text-foreground">
        <div className="flex justify-between gap-x-6 items-start">
          <div className="w-1/2 font-semibold text-xs whitespace-nowrap">Total</div>
          <div className="w-1/2">
            <div className="flex gap-1 items-center justify-end">
              <LowNumFormatter className="font-medium font-mono text-xs" price={totals.total.usd} isUsd={true} />
              <span className="text-muted text-xs">{USD_TICKER}</span>
            </div>

            <div className="flex gap-1 items-center justify-end">
              <LowNumFormatter className="font-medium text-xs text-muted" price={totals.total.quote} isUsd={false} />
              <span className="text-muted text-xs">{tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}</span>
            </div>
          </div>
        </div>

        <hr className="border border-border" />

        <div className="flex gap-x-6 items-start">
          <div className="w-1/2 font-semibold text-xs whitespace-nowrap">Size</div>
          <div className="w-1/2 text-right flex flex-col items-end">
            <LowNumFormatter
              className="font-medium font-mono text-right text-foreground text-xs"
              price={totals.total.base}
              isUsd={false}
            />
            <span className="text-muted text-xs">${props.tokenDisplayName}</span>
          </div>
        </div>

        <hr className="border border-border" />

        <div className="flex gap-x-6 items-start">
          <div className="w-1/2 font-semibold text-xs whitespace-nowrap">
            Average
            <br />
            Price
          </div>
          <div className="w-1/2">
            <div className="flex gap-1 items-center justify-end">
              <LowNumFormatter
                className="font-medium font-mono text-xs"
                price={totals.total.usd / totals.total.base}
                isUsd={true}
                abbreviatePriceThreshold={1000000}
              />
              <span className="text-muted text-xs">{USD_TICKER}</span>
            </div>

            <div className="flex gap-1 items-center justify-end">
              <LowNumFormatter
                className="font-medium font-mono text-xs text-muted"
                price={totals.total.quote / totals.total.base}
                isUsd={quoteCurrencyPublicKey === DESO_USDC_PUBLIC_KEY}
                abbreviatePriceThreshold={1000000}
              />
              <span className="text-muted text-xs">{tickerByQuoteCurrencyPublicKey[quoteCurrencyPublicKey]}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  const tooltipPlace = isMobile ? 'top' : 'left-start';
  const tooltipClassName = isMobile ? 'border border-border' : '';
  const tooltipArrowClassName = isMobile ? 'border-b border-r border-border' : '';

  return (
    <>
      <tr
        onClick={() => {
          props.onClick({
            price: quantityInQuoteCurrency,
            usdPrice,
          });
        }}
        className={classNames(
          'text-sm text-right border-l-2 border-transparent cursor-pointer',
          props.index % 2 === 0 ? 'bg-background' : 'bg-background',
          props.hoveredRowIndex &&
            props.hoveredRowIndex.side === props.side &&
            ((props.hoveredRowIndex.side === OPERATION_TYPES.ASK && props.hoveredRowIndex.index <= props.index) ||
              (props.hoveredRowIndex.side === OPERATION_TYPES.BID && props.hoveredRowIndex.index >= props.index)) && [
              '!bg-card',
              'border-l-2',
              props.hoveredRowIndex.side === OPERATION_TYPES.ASK ? '!border-l-red-500' : '!border-l-green-500',
            ],
        )}
        data-tooltip-id={`new-badge-tooltip-${props.priceLevel.Price}`}
        onMouseEnter={() => props.setHoveredRowIndex({ side: props.side as OPERATION_TYPE, index: props.index })}
        onMouseLeave={() => props.setHoveredRowIndex(null)}
        onTouchStart={() =>
          isMobile && props.setHoveredRowIndex({ side: props.side as OPERATION_TYPE, index: props.index })
        }
        onTouchEnd={() => isMobile && props.setHoveredRowIndex(null)}
      >
        <td style={{ width: '30%' }} className={`p-1.5 text-left font-mono text-xs ${color}`}>
          {priceColumnValue}
        </td>
        <td style={{ width: '20%' }} className="py-1 px-3 text-left">
          <div className="flex flex-col items-start justify-start">
            <LowNumFormatter
              price={quantityBigIntToFloat(props.priceLevel.Quantity)}
              isUsd={false}
              className="text-xs font-mono text-muted-foreground"
            />
            <span className="text-xs text-muted font-normal">${props.tokenDisplayName}</span>
          </div>
        </td>
        <td style={{ width: '40%' }} className="py-1 px-3 w-30 whitespace-nowrap">
          {totalColumnValue}
        </td>
        <td style={{ width: '0%' }}>
          <Tooltip
            place={tooltipPlace}
            id={`new-badge-tooltip-${props.priceLevel.Price}`}
            className={cn(
              'max-w-[450px] text-left z-20 rounded-2xl shadow-lg !bg-background border border-border px-4 py-6',
              tooltipClassName,
            )}
            opacity={1}
            classNameArrow={tooltipArrowClassName}
          >
            {getTooltipValue}
          </Tooltip>
        </td>
      </tr>
    </>
  );
}

function getMaxPrice(priceLevels: PriceLevel[]): number {
  if (priceLevels.length === 0) {
    return 0;
  }
  return Math.max(...priceLevels.map((priceLevel) => parseFloat(priceBigIntToDecmialString(priceLevel.Price))));
}

function getMinPrice(priceLevels: PriceLevel[]): number {
  if (priceLevels.length === 0) {
    return 0;
  }
  return Math.min(...priceLevels.map((priceLevel) => parseFloat(priceBigIntToDecmialString(priceLevel.Price))));
}
