import { FiLoader } from 'react-icons/fi';
import { IoCloseCircleOutline, IoWarningOutline } from 'react-icons/io5';
import { OpenfundContext } from 'contexts/OpenfundContext';
import { useIsMounted } from 'hooks/useIsMounted';
import { useContext, useEffect, useState } from 'react';
import { heroswap, openfund } from 'services';
import { DepositEvent } from 'services/HeroSwapper';
import { classNames } from 'utils/classNames';
import { formatDecimalValue, parseFloatWithCommas } from 'utils/currency';
import { formatTicker, getExplorerLink, Ticker } from 'utils/tickers';
import { LuCheckCircle, LuExternalLink, LuRefreshCcw } from 'react-icons/lu';
import { NetworkFeesLabel } from './SwapCurrency';
import LowNumFormatter from './LowNumFormatter';
import { DESO_WRAPPED_TOKENS } from '../../constants/TradeConstants';

const POLL_TIMEOUT = 600000; // 10 minutes
let pollIntervalId: number | undefined;
const PENDING_SWAP_STATUSES = new Set([
  'DEPOSIT_PENDING',
  'DEPOSIT_CONFIRMED',
  'DESTINATION_TRANSFER_RUNNING',
  'DESTINATION_TRANSFER_PENDING',
  'DESTINATION_TRANSFER_RETRIED',
]);

const getPendingTransfers = (events: DepositEvent[]) =>
  events.filter((ev) => PENDING_SWAP_STATUSES.has(ev.DepositStatus));

const isCashOutEvent = (depositEvent: DepositEvent) =>
  !DESO_WRAPPED_TOKENS.map((e) => e.heroswapName).includes(depositEvent.DestinationTicker);

export const DepositEventsList = ({
  events,
  className,
  ticker,
  depositAddress,
  onSwapConfirmed,
  ...rest
}: {
  ticker: Ticker;
  events: DepositEvent[];
  depositAddress: string;
  onSwapConfirmed?: (event: DepositEvent) => void;
} & React.HTMLAttributes<HTMLUListElement>) => {
  const [depositEvents, setDepositEvents] = useState(events);
  const { setCurrentUser } = useContext(OpenfundContext);
  const isMounted = useIsMounted();

  useEffect(() => {
    setDepositEvents(events);
  }, [events]);

  useEffect(() => {
    if (typeof pollIntervalId !== 'undefined') {
      window.clearInterval(pollIntervalId);
    }
    let didReloadUserBalance = false;
    const pendingTransfers = getPendingTransfers(depositEvents);
    const startPollTime = Date.now();
    if (pendingTransfers.length > 0) {
      pollIntervalId = window.setInterval(() => {
        heroswap
          .getRecentDepositEventsForDepositAddress(ticker, depositAddress)
          .then((res) => {
            if (!isMounted.current) {
              window.clearInterval(pollIntervalId);
              return;
            }
            setDepositEvents(res);

            const depositEvent = res.find((ev) => pendingTransfers.find((t) => t.DepositTxId === ev.DepositTxId));
            const isCashOut = !!depositEvent && isCashOutEvent(depositEvent);

            // If this is a cash out event and the status starts with
            // DESTINATION, this means the heroswap deposit has been confirmed
            // and we should update the user's balance early since the funds are
            // no longer in their DESO wallet. This addresses an issue where
            // USDC destination transfers have failed but the UI does not
            // reflect the actual state of the user's wallet.
            if (isCashOut && !didReloadUserBalance && depositEvent?.DepositStatus.startsWith('DESTINATION')) {
              openfund.reloadCurrentUserData().then((user) => {
                if (!isMounted.current) return;
                setCurrentUser(user);
                didReloadUserBalance = true;
              });
            }

            if (depositEvent?.DepositStatus === 'DESTINATION_TRANSFER_CONFIRMED') {
              onSwapConfirmed?.(depositEvent);
              // for cash out events, we've already reloaded the user's balance.
              if (!didReloadUserBalance) {
                openfund.reloadCurrentUserData().then((user) => {
                  if (!isMounted.current) return;
                  setCurrentUser(user);
                });
              }
            }

            if (getPendingTransfers(res).length === 0 || Date.now() - startPollTime > POLL_TIMEOUT) {
              window.clearInterval(pollIntervalId);
            }
          })
          .catch(() => {
            window.clearInterval(pollIntervalId);
          });
      }, 3000);
    }

    return () => {
      if (typeof pollIntervalId !== 'undefined') {
        window.clearInterval(pollIntervalId);
        pollIntervalId = undefined;
      }
    };
  }, [depositEvents, ticker, depositAddress, isMounted, onSwapConfirmed, setCurrentUser]);

  return (
    <ul
      className={classNames(
        className,
        'text-sm border-t border-border divide-y divide-border max-h-[300px] overflow-y-auto openfund-scrollbar',
      )}
      {...rest}
    >
      {depositEvents.map((ev, i) => (
        <li className="py-3" key={i}>
          {<DepositEventTemplate event={ev} />}
        </li>
      ))}
    </ul>
  );
};

const DepositEventTemplate = ({ event }: { event: DepositEvent }) => {
  let txExplorerLink = <></>;
  const formattedDepositAmount = formatDecimalValue(event.DepositAmount, 6, 2);
  const formattedDestinationAmount = formatDecimalValue(event.DestinationAmount, 6, 2);
  let depositTxExplorerLink = <></>;

  if (event.DestinationTxId) {
    txExplorerLink = (
      <a href={getExplorerLink(event.DestinationTicker, event.DestinationTxId, 'tx')} target="_blank" rel="noreferrer">
        <LuExternalLink className="relative top-[-1px] inline-flex text-primary" />
      </a>
    );
  }

  if (event.DepositTxId) {
    depositTxExplorerLink = (
      <>
        {' '}
        <a href={getExplorerLink(event.DepositTicker, event.DepositTxId, 'tx')} target="_blank" rel="noreferrer">
          <LuExternalLink className="relative top-[-1px] inline-flex text-primary" />
        </a>
      </>
    );
  }

  const gasFee = (
    <span className="text-xs block text-muted sm:ml-4 pt-1">
      Network Fee ({NetworkFeesLabel(isCashOutEvent(event) ? event.DestinationTicker : event.DepositTicker)}) ={' '}
      <LowNumFormatter price={parseFloatWithCommas(event.NetworkFeeInDepositTicker)} maxZeroDigits={4} isUsd={false} />{' '}
      {formatTicker(event.DepositTicker)}
    </span>
  );
  switch (event.DepositStatus) {
    case 'DEPOSIT_PENDING':
      return (
        <>
          <FiLoader className="rotate inline text-gray-dark" /> Swap of {formattedDepositAmount}{' '}
          {formatTicker(event.DepositTicker)}
          {depositTxExplorerLink} is pending {gasFee}. {txExplorerLink}
        </>
      );
    case 'DEPOSIT_CONFIRMATION_FAILED':
      return (
        <>
          <IoCloseCircleOutline className="inline text-red" /> Swap of {formattedDepositAmount}{' '}
          {formatTicker(event.DepositTicker)}
          {depositTxExplorerLink} could not be confirmed {gasFee}. {txExplorerLink}
        </>
      );
    case 'DEPOSIT_CONFIRMED':
      return (
        <>
          <FiLoader className="rotate inline text-gray-dark" /> Swap of {formattedDepositAmount}{' '}
          {formatTicker(event.DepositTicker)}
          {depositTxExplorerLink} to {formattedDestinationAmount} {formatTicker(event.DestinationTicker)} is starting{' '}
          {gasFee}. {txExplorerLink}
        </>
      );
    case 'DEPOSIT_CANCELLED':
      return (
        <>
          <IoCloseCircleOutline className="inline text-red" /> Swap of {formattedDepositAmount}{' '}
          {formatTicker(event.DepositTicker)}
          {depositTxExplorerLink} was canceled {gasFee}. {txExplorerLink}
        </>
      );
    case 'DESTINATION_TRANSFER_RUNNING':
      return (
        <>
          <FiLoader className="rotate inline text-gray-dark" /> Swap of {formattedDepositAmount}{' '}
          {formatTicker(event.DepositTicker)}
          {depositTxExplorerLink} to {formattedDestinationAmount} {formatTicker(event.DestinationTicker)} is running{' '}
          {gasFee}. {txExplorerLink}
        </>
      );
    case 'DESTINATION_TRANSFER_FAILED':
      return (
        <>
          <IoWarningOutline className="inline text-yellow" /> Swap of {formattedDepositAmount}{' '}
          {formatTicker(event.DepositTicker)}
          {depositTxExplorerLink} for {formattedDestinationAmount} {formatTicker(event.DestinationTicker)} is being
          reviewed before completion {gasFee}. {txExplorerLink}
        </>
      );
    case 'DESTINATION_TRANSFER_TERMINATED':
      return (
        <>
          <IoWarningOutline className="inline text-yellow" /> Swap of {formattedDepositAmount}{' '}
          {formatTicker(event.DepositTicker)}
          {depositTxExplorerLink} to {formattedDestinationAmount} {formatTicker(event.DestinationTicker)} is being
          reviewed before completion {gasFee}. {txExplorerLink}
        </>
      );
    case 'DESTINATION_TRANSFER_CONFIRMATION_FAILED':
      return (
        <>
          <IoWarningOutline className="inline text-yellow" /> Swap of {formattedDepositAmount}{' '}
          {formatTicker(event.DepositTicker)}
          {depositTxExplorerLink} to {formattedDestinationAmount} {formatTicker(event.DestinationTicker)} is being
          reviewed before completion {gasFee}. {txExplorerLink}
        </>
      );
    case 'DESTINATION_TRANSFER_RETRIED':
      return (
        <>
          <IoWarningOutline className="inline text-yellow" /> Swap of {formattedDepositAmount}{' '}
          {formatTicker(event.DepositTicker)}
          {depositTxExplorerLink} to {formattedDestinationAmount} {formatTicker(event.DestinationTicker)} is being
          reviewed before completion {gasFee}. {txExplorerLink}
        </>
      );
    case 'DESTINATION_TRANSFER_CANCELLED':
      return (
        <>
          <IoWarningOutline className="inline text-yellow" /> Swap of {formattedDepositAmount}{' '}
          {formatTicker(event.DepositTicker)}
          {depositTxExplorerLink} to {formattedDestinationAmount} {formatTicker(event.DestinationTicker)} is being
          reviewed before completion {gasFee}. {txExplorerLink}
        </>
      );
    case 'DESTINATION_TRANSFER_PENDING':
      return (
        <>
          <LuRefreshCcw className="animate-spin inline text-yellow-400" /> Swap of{' '}
          <span className="font-mono text-green-500 font-shadow-green">
            {formattedDepositAmount} {formatTicker(event.DepositTicker)}
          </span>{' '}
          {depositTxExplorerLink} to{' '}
          <span className="font-mono text-green-400 font-shadow-green">
            {formattedDestinationAmount} {formatTicker(event.DestinationTicker)}
          </span>{' '}
          is pending confirmation
          <br />
          <span className="flex items-center gap-1">
            {gasFee} {txExplorerLink}
          </span>
        </>
      );
    case 'DESTINATION_TRANSFER_CONFIRMED':
      return (
        <>
          <LuCheckCircle className="inline text-green-500" /> Swap of{' '}
          <span className="font-mono text-green-500 font-shadow-green">
            {formattedDepositAmount} {formatTicker(event.DepositTicker)}
          </span>{' '}
          {depositTxExplorerLink} to{' '}
          <span className="font-mono text-green-500 font-shadow-green">
            {formattedDestinationAmount} {formatTicker(event.DestinationTicker)}
          </span>{' '}
          was successful <br />
          <span className="flex items-center gap-1">
            {gasFee} {txExplorerLink}
          </span>
        </>
      );
  }
};
