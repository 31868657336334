import { FiLoader } from '@react-icons/all-files/fi/FiLoader';
import { BuyModal } from 'components/app-ui/BuyModal';
import { CashOutForm } from 'components/app-ui/CashOutForm';
import { CopyToClipboard } from 'components/app-ui/CopyToClipboard';
import { CurrencyInputWithTickerToggle } from 'components/app-ui/CurrencyInputWithTickerToggle';
import { FullPageError } from 'components/app-ui/FullPageError';
import { NumberInputWithMaxToggle } from 'components/app-ui/NumberInputWithMaxToggle';
import { SearchInput, SearchMenuItem } from 'components/app-ui/SearchInput';
import { USDValueWithDESO } from 'components/app-ui/USDValueWithDESO';
import { WealthBadge } from 'components/app-ui/WealthBadge';
import { Avatar } from 'components/core/Avatar';
import { ExternalLink } from 'components/core/ExternalLink';
import { LoadingSpinner } from 'components/core/LoadingSpinner';
import { RouteLink } from 'components/core/RouteLink';
import { Spinner } from 'components/core/Spinner';
import { Tabs } from 'components/core/Tabs';
import { Text } from 'components/core/Text';
import { Button } from 'components/shadcn/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/shadcn/ui/dialog';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableScrollable,
} from 'components/shadcn/ui/table';
import {
  DESO_PROJECT_NAME,
  DESO_USDC_TICKER,
  DESO_WRAPPED_TOKENS,
  RESERVED_DESO_NANOS_FOR_FEES,
  SHOW_NEW_BADGES,
} from 'constants/TradeConstants';
import { OpenfundContext } from 'contexts/OpenfundContext';
import {
  BalanceEntryResponse,
  createUserAssociation,
  deleteUserAssociation,
  getExchangeRates,
  ProfileEntryResponse,
  User,
} from 'deso-protocol';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useToast } from 'components/hooks/use-toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { IoArrowDownOutline, IoArrowUpOutline, IoCopyOutline, IoFlame, IoKeyOutline } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import { Routes } from 'RoutePaths';
import { deso, openfund } from 'services';
import { SupportedDestinationTickers } from 'services/HeroSwapper';
import { FUNDING_ROUND_STATUSES, GetUserReferralsResponse, OpenfundUser } from 'services/Openfund';
import {
  baseUnitsToTokens,
  basisPointsToPercent,
  calcOwnershipPercent,
  creatorCoinValue,
  desoNanosToDeso,
  desoNanosToUSD,
  fetchUSDCExchangeRate,
  fetchWrappedAssetExchangeRate,
  formatDecimalValue,
  formatTokenBaseUnits,
  formatUSD,
  getWealthFromBalanceEntry,
  toHex,
  usdToDeso,
} from 'utils/currency';
import { getWrappedAsset, getWrappedAssetIcon, isDesoPublicKey, isWrappedAsset, shortenLongWord } from 'utils/deso';
import { getErrorMsg } from 'utils/getErrorMsg';
import { decimalStringToBigInt, quantityBigIntToFloat, quantityDecimalStringToBigInt } from 'utils/orderbook';
import { isDesoDollar } from 'utils/projectTreasury';
import { centerEllipsis, toTitleCase } from 'utils/text';
import { formatProjectWithTooltip } from 'utils/tickers';
import { getUSDBalance } from 'utils/user';
import { v4 as uuid } from 'uuid';
import { GetExchangeRateUpdatedResponse, ProjectPublicKeysPurchasedKey } from '../../services/Deso';
import { isMobile } from '../../utils/isMobile';
import { Logo } from '../core/Logo';
import NewBadge from '../core/NewBadge';
import { useLazyQuery } from '@apollo/client';
import { UserAssociationsDocument, UserAssociationsQuery } from '../../graphql/codegen/graphql';
import { useWaitForTransaction } from '../../hooks/useWaitForTransaction';
import { DESO_EXPLORER_URL } from '../../constants/AppConstants';
import { LuExternalLink } from 'react-icons/lu';
import { InfoTooltip } from 'components/core/InfoTooltip';
import LowNumFormatter from '../app-ui/LowNumFormatter';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/shadcn/ui/select';
import { ViewTransactionLink } from 'components/core/Toast';
import { Skeleton } from '../shadcn/ui/skeleton';

type WalletAction = 'SEND_TOKEN' | 'BURN_TOKEN' | 'SEND_DESO';
interface WalletActionModalState {
  isOpen: boolean;
  projectProfile?: ProfileEntryResponse | null;
  user?: User | null;
  balanceNanos: string;
  action: WalletAction;
}

const DEFAULT_WALLET_ACTION_MODAL_STATE: WalletActionModalState = Object.freeze({
  isOpen: false,
  projectProfile: undefined,
  user: undefined,
  balanceNanos: '0',
  action: 'SEND_DESO',
});
const ACTION_MODAL_TITLE_MAP: Record<WalletAction, string> = {
  SEND_TOKEN: 'Send Tokens',
  BURN_TOKEN: 'Burn Tokens',
  SEND_DESO: 'Send $DESO',
};
const ACTION_MODAL_INPUT_LABEL_MAP: Record<WalletAction, string> = {
  SEND_TOKEN: 'Amount to send:',
  BURN_TOKEN: 'Amount to burn:',
  SEND_DESO: 'Amount to send:',
};
const TABLE_HEADINGS = {
  HODLER: 'Hodler',
  TOKEN: 'Token',
  MEMBERS: 'Holders',
  PRICE: isMobile() ? 'Price' : 'Token Price',
  TOKENS_IN_CIRCULATION: 'Tokens in Circulation',
  TOKENS_HELD: isMobile() ? 'Held' : 'Tokens Held',
  VALUE_OF_HOLDING: isMobile() ? 'Value' : 'Value of Holding',
  OWNERSHIP: 'Ownership',
  ACTIONS: 'Actions',
};

export function MyWallet() {
  useDocumentTitle('My Wallet');
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<any>();
  const [holdings, setHoldings] = useState<BalanceEntryResponse[]>([]);
  const [holders, setHolders] = useState<BalanceEntryResponse[]>([]);
  const [walletActionModalState, setWalletActionModalState] = useState<WalletActionModalState>(
    DEFAULT_WALLET_ACTION_MODAL_STATE,
  );
  const [buyModalProps, setBuyModalProps] = useState<{
    isOpen: boolean;
    currency: 'DESO' | 'DUSD' | 'DBTC' | 'DETH' | 'DSOL';
    visibleTab?: string;
  }>({
    isOpen: false,
    currency: 'DUSD',
    visibleTab: undefined,
  });
  const [exchangeRates, setExchangeRates] = useState<GetExchangeRateUpdatedResponse>();
  const [referrals, setReferrals] = useState<GetUserReferralsResponse>();
  enum SUB_TAB {
    PURCHASED = 'My Tokens',
    RECEIVED = 'Hidden Tokens',
  }
  const subTabs = [SUB_TAB.PURCHASED, SUB_TAB.RECEIVED];
  const toast = useToast();
  const { currentUser, setCurrentUser, loadingUser } = useContext(OpenfundContext);
  const currentUserPubKey = useRef(currentUser?.PublicKeyBase58Check ?? '');
  const [cashOutModalProps, setCashOutModalProps] = useState<{
    isOpen: boolean;
    ticker: SupportedDestinationTickers;
    destinationTicker: SupportedDestinationTickers;
    amount?: number;
  }>({
    isOpen: false,
    ticker: 'DUSD',
    destinationTicker: 'USDC',
    amount: undefined,
  });
  const [usdcExchangeRate, setUSDCExchangeRate] = useState<number>(1);
  const [isPending, setIsPending] = useState<boolean>(false);

  const [getUserAssociations, { data: userAssociationsResponse, refetch: refetchUserAssociations }] = useLazyQuery(
    UserAssociationsDocument,
    {
      fetchPolicy: 'network-only',
    },
  );

  const { waitForTxn } = useWaitForTransaction();

  useEffect(() => {
    // make sure we have the latest user data loaded (latest balance, token holdings, etc).
    if (currentUser) {
      openfund.reloadCurrentUserData().then(setCurrentUser);
    }
  }, [currentUser?.PublicKeyBase58Check]);

  const login = async () => {
    if (isPending) {
      return;
    }

    try {
      const users = await openfund.login(() => setIsPending(true));
      setCurrentUser(users);
    } catch (e) {
      toast.toast({
        description: getErrorMsg(e),
        variant: 'error',
      });
    } finally {
      setIsPending(false);
    }
  };

  const isProjectOwner = !!(currentUser?.ProfileEntryResponse && currentUser.isProjectOwner);

  const refresh = () => {
    if (!currentUser) {
      return Promise.resolve();
    }

    Promise.all([
      deso.getAllProjectHoldings(currentUser.PublicKeyBase58Check).then((res) => {
        if (res.Hodlers) {
          setHoldings(
            res.Hodlers.filter((h) => h.BalanceNanos !== 0 && !isDesoDollar(h.ProfileEntryResponse)).sort((a, b) => {
              return Number(b.BalanceNanosUint256) - Number(a.BalanceNanosUint256);
            }),
          );
        }
      }),
      isProjectOwner &&
        deso.getProjectHolders(currentUser.PublicKeyBase58Check).then((res) => {
          if (res.Hodlers) {
            setHolders(
              res.Hodlers.filter((h) => h.BalanceNanos !== 0).sort((a, b) => {
                return Number(b.BalanceNanosUint256) - Number(a.BalanceNanosUint256);
              }),
            );
          }
        }),
      getExchangeRates().then((res) => {
        setExchangeRates(res);
      }),
      openfund.getUserReferrals(currentUser.PublicKeyBase58Check).then((res) => {
        setReferrals(res);
      }),
      fetchUSDCExchangeRate().then(setUSDCExchangeRate),
    ])
      .then(() => {
        setLoadingError(null);
        if (currentUserPubKey.current !== currentUser.PublicKeyBase58Check) {
          setTimeout(() => {
            (document.querySelector('[data-tab="tab-0"]') as HTMLButtonElement)?.click();
          }, 1);
          currentUserPubKey.current = currentUser.PublicKeyBase58Check;
        }
      })
      .catch((e) => {
        setLoadingError(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    setIsLoading(true);

    const associationsFilter = {
      filter: {
        transactor: {
          publicKey: {
            equalTo: currentUserPubKey.current,
          },
        },
        associationType: {
          equalTo: 'DeSoTokenWhitelistAssociationKey',
        },
        associationValue: {
          equalTo: 'DeSoTokenWhitelistAssociationKey',
        },
      },
    };

    Promise.all([getUserAssociations({ variables: associationsFilter }), refresh()]);
  }, [currentUser?.PublicKeyBase58Check, isProjectOwner]);

  if (loadingError) {
    return <FullPageError error={loadingError} />;
  }

  if (loadingUser || isLoading) {
    return (
      <div className="w-full p-6 sm:p-12">
        <div className="hidden sm:block text-left text-2xl font-semibold text-muted-foreground mb-6 font-sans">
          My Wallet
        </div>
        <div className="sm:p-6 sm:border sm:rounded-2xl border-border mb-4 flex flex-col gap-4">
          <div>
            <Skeleton className="w-1/2 h-[30px] mb-4" />
            <Skeleton className="w-full h-[150px]" />
          </div>

          <div>
            <Skeleton className="w-1/2 h-[30px] mb-4" />
            <Skeleton className="w-full h-[150px]" />
          </div>

          <div>
            <Skeleton className="w-1/2 h-[30px] mb-4" />
            <Skeleton className="w-full h-[150px]" />
          </div>
        </div>
      </div>
    );
  }

  if (!currentUser) {
    return (
      <div className="text-center mx-auto h-full flex flex-col justify-center">
        <div className="w-3/4 lg:w-1/2 mx-auto">
          <Link to={Routes.home()}>
            <Logo width="100%" />
          </Link>
        </div>
        <Text size="lg">It seems that you are currently logged out.</Text>
        <div className="w-full mx-auto">
          <Button className="my-4 inline-flex" onClick={login}>
            {isPending ? <FiLoader className="rotate" /> : 'Login with DeSo Wallet'}
          </Button>
        </div>
      </div>
    );
  }

  const holdingsWithoutWrappedAssets = holdings.filter((e) => !isWrappedAsset(e.ProfileEntryResponse?.Username));

  const tabs = [
    {
      tab: 'Holdings',
      panel: (
        <>
          <div className="border border-border rounded-2xl mb-4">
            <WrappedAssetsHodlingsTab
              currentUser={currentUser}
              holders={holdings}
              exchangeRates={exchangeRates}
              onSendTokens={(h, action) => {
                setWalletActionModalState({
                  ...walletActionModalState,
                  isOpen: true,
                  projectProfile: h.ProfileEntryResponse,
                  balanceNanos: h.BalanceNanosUint256.toString(),
                  action: action,
                });
              }}
              onAddFunds={(assetName: string, visibleTab: string) => {
                setBuyModalProps({
                  isOpen: true,
                  currency: assetName as 'DESO' | 'DUSD' | 'DBTC' | 'DETH' | 'DSOL',
                  visibleTab: `$${visibleTab}`,
                });
              }}
              onCashOut={(assetName: string, destinationTicker: string, amount: number) => {
                setCashOutModalProps({
                  isOpen: true,
                  ticker: assetName as SupportedDestinationTickers,
                  destinationTicker: destinationTicker as SupportedDestinationTickers,
                  amount,
                });
              }}
            />
          </div>

          <Tabs
            showSelect={false}
            tabs={subTabs.map((subTab) => {
              return {
                tab: subTab,
                panel: (
                  <div className="border border-border rounded-2xl">
                    <HodlingsTab
                      currentUser={currentUser}
                      holders={holdingsWithoutWrappedAssets}
                      userAssociations={userAssociationsResponse}
                      exchangeRates={exchangeRates}
                      onSendTokens={(h, action) => {
                        setWalletActionModalState({
                          ...walletActionModalState,
                          isOpen: true,
                          projectProfile: h.ProfileEntryResponse,
                          balanceNanos: h.BalanceNanosUint256.toString(),
                          action: action,
                        });
                      }}
                      onBurnTokens={(h, action) => {
                        setWalletActionModalState({
                          ...walletActionModalState,
                          isOpen: true,
                          projectProfile: h.ProfileEntryResponse,
                          balanceNanos: h.BalanceNanosUint256.toString(),
                          action: action,
                        });
                      }}
                      onMoveToPurchased={(h) => {
                        if (h && h.ProfileEntryResponse) {
                          return createUserAssociation({
                            AssociationType: 'DeSoTokenWhitelistAssociationKey',
                            AssociationValue: 'DeSoTokenWhitelistAssociationKey',
                            TransactorPublicKeyBase58Check: currentUserPubKey.current,
                            TargetUserPublicKeyBase58Check: h.ProfileEntryResponse?.PublicKeyBase58Check,
                          })
                            .then((resp) => {
                              if (!resp.submittedTransactionResponse?.TxnHashHex) {
                                throw new Error('Transaction was submitted but no transaction hash was returned.');
                              }

                              return waitForTxn(resp.submittedTransactionResponse.TxnHashHex);
                            })
                            .then(() => refetchUserAssociations());
                        }

                        return Promise.reject('No profile entry response found');
                      }}
                      onMoveToHidden={(h) => {
                        if (h && h.ProfileEntryResponse) {
                          const association = (userAssociationsResponse?.userAssociations?.nodes || []).find(
                            (e) => e?.target?.publicKey === h.ProfileEntryResponse?.PublicKeyBase58Check,
                          );

                          if (!association || !association.associationId) {
                            return Promise.reject('No association found');
                          }

                          return deleteUserAssociation({
                            AssociationID: association.associationId,
                            TransactorPublicKeyBase58Check: currentUserPubKey.current,
                          })
                            .then((resp) => {
                              if (!resp.submittedTransactionResponse?.TxnHashHex) {
                                throw new Error('Transaction was submitted but no transaction hash was returned.');
                              }

                              return waitForTxn(resp.submittedTransactionResponse.TxnHashHex);
                            })
                            .then(() => refetchUserAssociations());
                        }

                        return Promise.reject('No profile entry response found');
                      }}
                      filterToPurchased={subTab === SUB_TAB.PURCHASED}
                    />
                  </div>
                ),
              };
            })}
          />
        </>
      ),
    },
    {
      tab: 'My Referrals',
      panel:
        !referrals || !exchangeRates ? (
          <FullPageError />
        ) : (
          <div>
            <div className="text-muted-foreground border w-full bg-card text-center py-2 text-sm mb-4">
              Referrals are for project fundraising contributions only.
            </div>
            <div className="bg-background border w-full flex items-center justify-between p-4">
              <div>
                <Text className="font-medium">
                  <span className="font-bold">Total Referrals: </span>
                  {referrals.TotalReferralsCount}
                </Text>
                <div>
                  {/* NOTE: we currently show 1 aggregate total. do we want to show how much was earned via deso and usd separately */}
                  <span className="font-medium">
                    <span className="font-bold">Total Earnings from Referrals: </span>
                    <span className="font-mono text-green-600 font-shadow-green">
                      {formatUSD(
                        desoNanosToUSD(
                          referrals.TotalReferralAmountEarnedDesoNanos,
                          exchangeRates.USDCentsPerDeSoCoinbase,
                        ) + baseUnitsToTokens(referrals.TotalReferralAmountEarnedDaoCoinsHex),
                      )}{' '}
                      USD
                    </span>
                  </span>
                  {/* {' '}
                  <Text tag="span" size="sm" color="secondary">
                    (≈ {desoNanosToDeso(referrals.TotalReferralAmountEarnedDesoNanos)} DESO)
                  </Text> */}
                </div>
              </div>
              <div className="justify-end flex ml-auto items-center">
                <label htmlFor="top-hodlers-sort-select" className="font-bold mr-2">
                  Sort
                </label>
                <Select
                  onValueChange={async (value) => {
                    openfund.getUserReferrals(currentUser.PublicKeyBase58Check, value).then((res) => {
                      setReferrals(res);
                    });
                  }}
                >
                  <SelectTrigger id="top-hodlers-sort-select">
                    <SelectValue placeholder="Select sort..." />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="referral_amount_earned_deso_nanos">Amount Earned</SelectItem>
                    <SelectItem value="referral_count">Referral Count</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
            {referrals?.Referrals?.length > 0 && (
              <div className="overflow-hidden overflow-x-scroll md:overflow-x-auto border mt-4">
                <TableScrollable containerClassname="lg:overflow-x-auto overflow-x-scroll">
                  <TableHeader>
                    <TableRow>
                      <TableHead>Token</TableHead>
                      <TableHead>Round</TableHead>
                      <TableHead>Round Status</TableHead>
                      <TableHead>Invite Link</TableHead>
                      <TableHead>Referral Bonus</TableHead>
                      <TableHead>Referrals</TableHead>
                      <TableHead>Round Treasury</TableHead>
                      <TableHead>Amount Earned</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {referrals?.Referrals.map((r) => (
                      <TableRow key={r.RoundID}>
                        <TableCell className="flex items-center whitespace-nowrap">
                          <Avatar src={r.DaoPkidBase58check} className="mr-2" />
                          <span className="font-bold">${r.DaoUsername}</span>
                        </TableCell>
                        <TableCell className="whitespace-nowrap">{r.RoundName}</TableCell>
                        <TableCell
                          className={r.RoundStatus === FUNDING_ROUND_STATUSES.OPEN ? 'text-green' : 'text-red'}
                        >
                          <span className="whitespace-nowrap">{toTitleCase(r.RoundStatus)}</span>
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          <Button
                            variant="link"
                            aria-label="Copy invite link"
                            title={`${window.origin}${Routes.fund(r.DaoUsername)}?invite=${r.ReferralID}`}
                            onClick={() => {
                              window.navigator.clipboard
                                .writeText(`${window.origin}${Routes.fund(r.DaoUsername)}?invite=${r.ReferralID}`)
                                .then(() => {
                                  toast.toast({
                                    description: `Copied invite link to clipboard.`,
                                    variant: 'success',
                                  });
                                })
                                .catch((e) => {
                                  toast.toast({ description: getErrorMsg(e), variant: 'error' });
                                });
                            }}
                          >
                            {r.ReferralID} <IoCopyOutline className="inline ml-1" />
                          </Button>
                        </TableCell>
                        <TableCell>{basisPointsToPercent(r.GlobalReferralRateBasisPoints)}%</TableCell>
                        <TableCell>{formatDecimalValue(r.ReferralCount, 0)}</TableCell>
                        <TableCell className="">
                          <span className="whitespace-nowrap">
                            {r.TreasuryCurrencyUnit === 'DESO' ? '$DESO' : `$${DESO_USDC_TICKER}`}
                          </span>
                        </TableCell>
                        {r.TreasuryCurrencyUnit === 'DESO' ? (
                          <TableCell>
                            <div className="whitespace-nowrap">
                              {formatUSD(
                                desoNanosToUSD(r.ReferralAmountEarnedDesoNanos, exchangeRates.USDCentsPerDeSoCoinbase),
                              )}{' '}
                              USD
                            </div>
                            <Text size="sm" color="secondary" className="whitespace-nowrap">
                              ≈ {formatDecimalValue(desoNanosToDeso(r.ReferralAmountEarnedDesoNanos), 9)} DESO
                            </Text>
                          </TableCell>
                        ) : (
                          <TableCell>
                            <div className="whitespace-nowrap">
                              {formatUSD(baseUnitsToTokens(r.ReferralAmountEarnedDaoCoinsHex))} USD
                            </div>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </TableScrollable>
              </div>
            )}
          </div>
        ),
    },
  ];

  if (currentUser.ProfileEntryResponse && currentUser.isProjectOwner) {
    tabs.push({
      tab: 'My Hodlers',
      panel: <MyHodlersTab currentUser={currentUser} holders={holders} exchangeRates={exchangeRates} />,
    });
  }

  return (
    <div className="w-full p-6 sm:p-12">
      <div className="hidden sm:block text-left text-2xl font-semibold text-muted-foreground mb-6 font-sans">
        My Wallet
      </div>
      <div className="sm:p-6 sm:border sm:rounded-2xl border-border mb-4">
        <div className="flex flex-col md:flex-row mb-4">
          <div className="flex flex-col sm:flex-row gap-4 items-center">
            <div>
              <Avatar
                size="xl"
                src={deso.profilePicUrl(currentUser.PublicKeyBase58Check)}
                className="border border-border"
              />
            </div>
            <div className="text-center md:text-left flex flex-col gap-1">
              <div className="text-muted-foreground font-semibold font-sans text-lg">
                {currentUser.ProfileEntryResponse?.Username
                  ? `${currentUser.ProfileEntryResponse.Username}'s`
                  : `${shortenLongWord(currentUser.PublicKeyBase58Check)}'s`}{' '}
                Wallet
              </div>
              <div className="text-muted text-sm">
                <RouteLink
                  to={Routes.profile(currentUser.ProfileEntryResponse?.Username ?? currentUser.PublicKeyBase58Check)}
                >
                  View Profile
                </RouteLink>
              </div>
              {/* <div>
                {!!exchangeRates && (
                  <>
                    <span className="text-muted-foreground font-mono">
                      {formatUSD(
                        desoNanosToUSD(
                          currentUser.BalanceNanos +
                            calcCreatorCoinHodlingValue(currentUser) +
                            calcTokenHodlingValue(holdings),
                          exchangeRates.USDCentsPerDeSoCoinbase,
                        ) + baseUnitsToTokens(currentUser.usdBalanceEntry?.BalanceNanosUint256.toString() ?? '0x0'),
                      )}
                    </span>{' '}
                    <span className="font-medium text-muted">Total Portfolio Value</span>
                  </>
                )}
              </div> */}
            </div>
          </div>
          <div className="mx-auto sm:mx-0 md:ml-auto mt-8 md:mt-0 text-center md:text-left">
            <div className="flex items-center gap-3">
              <IoKeyOutline className="hidden md:inline text-muted text-xl" />
              <CopyToClipboard text={currentUser.PublicKeyBase58Check} />
            </div>
            <div className="ml-0 sm:ml-0 md:text-right mt-2">
              <div className="text-sm hover:text-blue">
                <ExternalLink
                  href={`${DESO_EXPLORER_URL}/txn?transactors=${currentUser.PublicKeyBase58Check}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-1 justify-center sm:justify-end sm:ml-auto no-underline"
                >
                  <Button variant="ghost" className="px-0">
                    View Transaction History
                  </Button>
                  <LuExternalLink className="text-muted" />
                </ExternalLink>{' '}
              </div>
            </div>
          </div>
        </div>
        {exchangeRates && (
          <section className="border rounded-2xl border-border">
            <header className="p-6 flex flex-col gap-2 border-b border-border-light">
              <div className="text-base text-foreground">
                Total Balance:{' '}
                <span className="font-mono text-green-600 font-shadow-green font-semibold">
                  {formatUSD(
                    desoNanosToUSD(currentUser.BalanceNanos, exchangeRates.USDCentsPerDeSoCoinbase) +
                      getUSDBalance(currentUser) * usdcExchangeRate,
                  )}
                </span>
              </div>
              <div className="text-muted text-sm">This is the value of your ${DESO_USDC_TICKER} + $DESO balance</div>
            </header>
            <div className="flex flex-col sm:flex-row sm:items-center p-4 border-b border-border-light">
              <div className="flex items-center">
                <div className="mr-1">
                  <Avatar
                    size="md"
                    className="mr-2 unforce-radius"
                    src={getWrappedAssetIcon(getWrappedAsset('dUSDC_')!)}
                  />{' '}
                </div>

                <div>
                  <span className="font-mono text-muted-foreground">
                    {formatDecimalValue(getUSDBalance(currentUser), 4)} USDC
                    {SHOW_NEW_BADGES && <NewBadge />}
                  </span>
                  <div className="text-muted text-sm">
                    ~{formatUSD(getUSDBalance(currentUser) * usdcExchangeRate, true, 4)} USD
                  </div>
                </div>
              </div>
              <div className="w-full md:w-auto flex gap-2 md:h-[44px] md:gap-4 mt-4 sm:mt-0 ml-auto">
                <Button
                  variant="outline"
                  disabled={!getUSDBalance(currentUser)}
                  onClick={() => {
                    setWalletActionModalState({
                      ...walletActionModalState,
                      isOpen: true,
                      action: 'SEND_TOKEN',
                      user: currentUser,
                      projectProfile: currentUser.usdBalanceEntry?.ProfileEntryResponse,
                      balanceNanos: currentUser.usdBalanceEntry?.BalanceNanosUint256.toString() ?? '0x0',
                    });
                  }}
                >
                  {isMobile() ? 'Send' : `Send ${DESO_USDC_TICKER}`}
                </Button>
                {/* TODO: add this convert to deso thing */}
                {/* <Button
                    className="mr-4"
                    kind="btn-primary"
                    shape="rounded"
                    size="sm"
                    onClick={() => {
                      setIsBuyModalOpen(true);
                    }}
                  >
                    Convert to $DESO
                  </Button> */}
                <Button
                  variant="outline"
                  disabled={!getUSDBalance(currentUser)}
                  onClick={() => {
                    setCashOutModalProps({
                      ...cashOutModalProps,
                      isOpen: true,
                      ticker: 'DUSD',
                      destinationTicker: 'USDC',
                      amount: undefined, // will be taken from the currentUser
                    });
                  }}
                >
                  Cash Out
                </Button>
                <Button
                  onClick={() => {
                    setBuyModalProps({
                      isOpen: true,
                      currency: 'DUSD',
                    });
                  }}
                >
                  {isMobile() ? 'Add' : `Add ${DESO_USDC_TICKER}`}
                </Button>
              </div>
            </div>
            <div className="flex-col sm:flex-row sm:items-center flex p-4">
              <div className="flex items-center">
                <div className="mr-1">
                  <Avatar
                    size="md"
                    className="mr-2 unforce-radius"
                    src={getWrappedAssetIcon(getWrappedAsset('DESO')!)}
                  />{' '}
                </div>

                <div>
                  <p>
                    <span className="font-mono text-muted-foreground flex flex-row items-center gap-1">
                      {formatDecimalValue(desoNanosToDeso(currentUser.BalanceNanos), 4)} DESO
                      <img src={'/images/icon-verified.gif'} alt="New" className="w-[18px] h-[18px] relative" />
                    </span>{' '}
                  </p>
                  <div className="text-muted text-sm">
                    ~{formatUSD(desoNanosToUSD(currentUser.BalanceNanos, exchangeRates.USDCentsPerDeSoCoinbase))} USD
                  </div>
                </div>
              </div>
              <div className="w-full md:w-auto flex gap-2 md:h-[44px] md:gap-4 mt-4 sm:mt-0 ml-auto">
                <Button
                  variant="outline"
                  onClick={() => {
                    setWalletActionModalState({
                      ...walletActionModalState,
                      isOpen: true,
                      action: 'SEND_DESO',
                      user: currentUser,
                      balanceNanos: currentUser.BalanceNanos.toFixed(0),
                    });
                  }}
                >
                  {isMobile() ? 'Send' : 'Send $DESO'}
                </Button>
                <Button
                  variant="outline"
                  onClick={() => {
                    setCashOutModalProps({
                      ...cashOutModalProps,
                      isOpen: true,
                      ticker: 'DESO',
                      destinationTicker: 'USDC',
                      amount: undefined, // will be taken from the currentUser
                    });
                  }}
                >
                  Cash Out
                </Button>
                <Button
                  onClick={() => {
                    setBuyModalProps({
                      isOpen: true,
                      currency: 'DESO',
                    });
                  }}
                >
                  {isMobile() ? 'Buy' : 'Buy $DESO'}
                </Button>
              </div>
            </div>
          </section>
        )}
      </div>
      <div>
        <Tabs tabs={tabs} showSelect={false} />
      </div>
      {walletActionModalState.isOpen && (
        <WalletActionModal
          action={walletActionModalState.action}
          isOpen={walletActionModalState.isOpen}
          onClose={() => setWalletActionModalState(DEFAULT_WALLET_ACTION_MODAL_STATE)}
          projectProfile={walletActionModalState.projectProfile}
          user={walletActionModalState.user}
          balanceNanos={walletActionModalState.balanceNanos}
          exchangeRates={exchangeRates}
        />
      )}
      <BuyModal
        currency={buyModalProps.currency}
        isOpen={buyModalProps.isOpen}
        visibleTab={buyModalProps.visibleTab}
        onClose={() => {
          setBuyModalProps({
            ...buyModalProps,
            isOpen: false,
          });
          openfund.reloadCurrentUserData().then((res) => setCurrentUser(res));
        }}
      />
      <Dialog
        open={cashOutModalProps.isOpen}
        onOpenChange={() => setCashOutModalProps({ ...cashOutModalProps, isOpen: false })}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Cash Out {cashOutModalProps.destinationTicker}</DialogTitle>
          </DialogHeader>
          <div className="p-6">
            <CashOutForm
              defaultCashOutTicker={cashOutModalProps.ticker}
              onTransferFailed={(e) => {
                setCashOutModalProps({ ...cashOutModalProps, isOpen: false });
                toast.toast({ description: getErrorMsg(e), variant: 'error' });
              }}
              destinationTicker={cashOutModalProps.destinationTicker}
              amount={cashOutModalProps.amount}
              onRefresh={(updatedAmoount) => {
                openfund.reloadCurrentUserData();
                refresh();

                setCashOutModalProps((prevState) => ({
                  ...prevState,
                  amount: updatedAmoount,
                }));
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

interface WalletActionModalProps {
  action: WalletAction;
  projectProfile?: ProfileEntryResponse | null;
  user?: User | null;
  balanceNanos: string;
  isOpen: boolean;
  exchangeRates?: GetExchangeRateUpdatedResponse;
  onClose: () => void;
}
function WalletActionModal({
  action,
  projectProfile,
  user,
  balanceNanos,
  isOpen,
  exchangeRates,
  onClose,
}: WalletActionModalProps) {
  const [errorMessage, setErrorMessage] = useState('');
  const [isPendingTx, setIsPendingTx] = useState(false);
  const [selectedSearchMenuItem, setSelectedSearchMenuItem] = useState<SearchMenuItem | null>(null);
  const [amountNanos, setAmountNanos] = useState(BigInt(0));
  const { currentUser, setCurrentUser } = useContext(OpenfundContext);
  const toast = useToast();
  const formId = uuid();
  const balance =
    action === 'SEND_DESO' ? formatDecimalValue(desoNanosToDeso(balanceNanos)) : formatTokenBaseUnits(balanceNanos);
  let pkid = '';
  let username = '';

  if (['SEND_TOKEN', 'BURN_TOKEN'].includes(action)) {
    if (!projectProfile) {
      throw new Error(`You must provide a project profile to perform action ${action}`);
    }
    pkid = projectProfile.PublicKeyBase58Check;
    username = projectProfile.Username;
  }

  if (action === 'SEND_DESO') {
    if (!user) {
      throw new Error(`You must provide a user to perform action ${action}`);
    }
    pkid = user.PublicKeyBase58Check;
    username = user.ProfileEntryResponse?.Username ?? pkid;
  }

  if (pkid.length === 0) {
    throw new Error(`You must provide either a projectProfile or user to perform a wallet action`);
  }

  const wrappedAsset = getWrappedAsset(username);
  const isWrapped = !!wrappedAsset;

  if (isWrapped) {
    username = wrappedAsset.displayName;
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="overflow-auto">
        <DialogHeader>
          <DialogTitle>{ACTION_MODAL_TITLE_MAP[action]}</DialogTitle>
          {action === 'SEND_DESO' ? (
            <></>
          ) : (
            <DialogDescription asChild>
              <div className="flex items-center pb-0">
                <Avatar
                  src={isWrapped ? getWrappedAssetIcon(wrappedAsset) : deso.profilePicUrl(pkid)}
                  border="none"
                  className="mr-2"
                />{' '}
                Sending &nbsp;
                <Text className="font-bold" tag="div">
                  {' '}
                  {isWrapped ? (
                    <div className="flex items-center">
                      <span>${wrappedAsset.displayName}</span>
                      {formatProjectWithTooltip(wrappedAsset.name, 18, 'ml-1')}
                    </div>
                  ) : (
                    `$${username}`
                  )}
                </Text>
              </div>
            </DialogDescription>
          )}
        </DialogHeader>
        <form
          onInput={() => setErrorMessage('')}
          id={formId}
          className="p-6"
          onSubmit={async (ev) => {
            ev.preventDefault();
            if (isPendingTx) {
              return;
            }

            if (Number(amountNanos) === 0) {
              setErrorMessage('You must enter a non-zero amount');
              return;
            }

            setIsPendingTx(true);
            let successMessage = '';
            let successTransferResponse;

            try {
              switch (action) {
                case 'SEND_TOKEN':
                  successMessage = `Tokens sent successfully`;
                  if (selectedSearchMenuItem) {
                    successTransferResponse = await deso.transferTokens(
                      pkid,
                      selectedSearchMenuItem.id,
                      toHex(amountNanos),
                    );
                  } else {
                    setErrorMessage('You must select a recipient');
                    setIsPendingTx(false);
                    return;
                  }
                  break;
                case 'BURN_TOKEN':
                  successMessage = `Tokens burned successfully`;
                  successTransferResponse = await deso.burnTokens(toHex(amountNanos), pkid);
                  break;
                case 'SEND_DESO':
                  successMessage = 'Sent deso successfully';
                  if (selectedSearchMenuItem) {
                    let amount = amountNanos;
                    if (amountNanos >= BigInt(balanceNanos) - RESERVED_DESO_NANOS_FOR_FEES) {
                      amount = BigInt(-1);
                    }
                    successTransferResponse = await deso.sendDeso(pkid, selectedSearchMenuItem?.id, Number(amount));
                    break;
                  } else {
                    setErrorMessage('You must select a recipient');
                    setIsPendingTx(false);
                    return;
                  }
                default:
                  setIsPendingTx(false);
                  throw new Error(`Unsupported action ${action}`);
              }

              setCurrentUser(await openfund.reloadCurrentUserData());
              toast.toast({
                description: successMessage,
                variant: 'success',
                action: (
                  <ViewTransactionLink
                    txnHashHex={successTransferResponse?.submittedTransactionResponse?.TxnHashHex || ''}
                  />
                ),
              });
            } catch (e) {
              toast.toast({ description: getErrorMsg(e), variant: 'error' });
            }

            setIsPendingTx(false);

            onClose();
          }}
        >
          {['SEND_TOKEN', 'SEND_DESO'].includes(action) && (
            <div className="sm:p-6 sm:border sm:border-border rounded-2xl mb-6">
              <div className="pb-1 font-bold">{`Send ${action === 'SEND_DESO' ? 'DESO' : 'tokens '} to: `}</div>
              <div className="pb-4 text-muted text-sm">Add the recipient's DeSo username or public key</div>
              <SearchInput
                size="sm"
                placeholder="Find users or enter a public key..."
                onItemSelected={(item) => setSelectedSearchMenuItem(item)}
                onQuery={async (query) => {
                  const res = await deso.getProfilesByUsername(query, currentUser?.PublicKeyBase58Check, 7);

                  if (res.length === 0 && isDesoPublicKey(query)) {
                    try {
                      const maybeUser = await deso
                        .getUsers([query], { SkipForLeaderboard: true })
                        .then(({ UserList }) => UserList?.[0]);
                      if (maybeUser && !maybeUser.IsGraylisted && !maybeUser.IsBlacklisted) {
                        return [
                          {
                            id: maybeUser.PublicKeyBase58Check,
                            inputDisplayValue:
                              maybeUser.ProfileEntryResponse?.Username ?? maybeUser.PublicKeyBase58Check,
                            menuItemContent: (
                              <div className="flex items-center cursor-pointer">
                                <Avatar
                                  src={
                                    maybeUser.ProfileEntryResponse
                                      ? deso.profilePicUrl(maybeUser.PublicKeyBase58Check)
                                      : undefined
                                  }
                                  className="flex-shrink-0"
                                />
                                <span className="ml-4">
                                  {maybeUser.ProfileEntryResponse?.Username ?? maybeUser.PublicKeyBase58Check}
                                </span>
                              </div>
                            ),
                          },
                        ];
                      }
                    } catch (e: any) {
                      if (process.env.NODE_ENV !== 'production') {
                        console.error(e?.response?.data);
                      }
                      return [];
                    }

                    return [];
                  }

                  return res.map((p) => ({
                    id: p.PublicKeyBase58Check,
                    inputDisplayValue: p.Username,
                    menuItemContent: (
                      <div className="flex items-center">
                        <Avatar src={deso.profilePicUrl(p.PublicKeyBase58Check)} />
                        <span className="ml-4">{p.Username}</span>
                      </div>
                    ),
                  }));
                }}
              />
              {!!selectedSearchMenuItem && (
                <div className="border border-border rounded-2xl p-4 mt-4">
                  <div className="mb-2 text-muted text-sm">Sending to:</div>
                  <div className="flex items-center">
                    <Avatar border="none" src={deso.profilePicUrl(selectedSearchMenuItem.id)} />
                    <span className="ml-3 truncate">{selectedSearchMenuItem.inputDisplayValue}</span>
                  </div>
                </div>
              )}
            </div>
          )}
          {!!exchangeRates && action === 'SEND_DESO' ? (
            <CurrencyInputWithTickerToggle
              containerClasses="mb-4"
              labelText={ACTION_MODAL_INPUT_LABEL_MAP[action]}
              initialValue={0}
              defaultTicker="DESO"
              alternateTicker="USD"
              state={amountNanos > BigInt(balanceNanos) ? 'error' : 'default'}
              onStateChange={(v, t) => {
                const desoAmount = t === 'DESO' ? v : usdToDeso(v, exchangeRates.USDCentsPerDeSoCoinbase).toFixed(5);
                setAmountNanos(decimalStringToBigInt(desoAmount));
              }}
              hint={`Available balance: ${balance}`}
              onMax={(selectedTicker) => {
                setAmountNanos(BigInt(-1));
                switch (selectedTicker) {
                  case 'DESO':
                    return desoNanosToDeso(balanceNanos);
                  case 'USD':
                    return desoNanosToUSD(balanceNanos, exchangeRates.USDCentsPerDeSoCoinbase);
                  default:
                    return 0;
                }
              }}
            />
          ) : (
            <NumberInputWithMaxToggle
              labelText={ACTION_MODAL_INPUT_LABEL_MAP[action]}
              initialValue={'0'}
              allowedDecimalPlaces={5}
              state={amountNanos > BigInt(balanceNanos) ? 'error' : 'default'}
              onChange={(e) => {
                setAmountNanos(quantityDecimalStringToBigInt(e.target.value));
              }}
              onMax={() => {
                setAmountNanos(BigInt(balanceNanos));
                return balance;
              }}
              hint={
                <span>
                  Available balance:{' '}
                  <span className="text-green-600 font-shadow-green font-mono">
                    {isDesoDollar(projectProfile) ? '$' : ''}
                    {balance}
                  </span>
                </span>
              }
            />
          )}
        </form>
        <DialogFooter>
          {!!errorMessage && (
            <Text className="text-right mb-2" color="error">
              {errorMessage}
            </Text>
          )}
          <div className="flex">
            <Button type="submit" form={formId} className="ml-auto">
              {isPendingTx ? <LoadingSpinner className="h-6 w-6" colorHex="#fff" /> : ACTION_MODAL_TITLE_MAP[action]}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function WrappedAssetsHodlingsTab(props: {
  currentUser: OpenfundUser | null;
  holders: BalanceEntryResponse[];
  exchangeRates: GetExchangeRateUpdatedResponse | undefined;
  onSendTokens: (h: BalanceEntryResponse, action: WalletAction) => void;
  onAddFunds: (assetName: string, visibleTab: string) => void;
  onCashOut: (assetName: string, destinationTicker: string, amount: number) => void;
}) {
  const columns = [
    TABLE_HEADINGS.TOKENS_HELD,
    TABLE_HEADINGS.PRICE,
    TABLE_HEADINGS.VALUE_OF_HOLDING,
    TABLE_HEADINGS.ACTIONS,
  ];
  const wrappedAssets = DESO_WRAPPED_TOKENS.filter((e) => e.name !== DESO_PROJECT_NAME && e.name !== 'dUSDC_');
  const [wrappedAssetExchangeRate, setWrappedAssetExchangeRate] = useState<{ [k: string]: number }>();

  const getHodlerAsset = (assetPublicKeyBase58Check: string): BalanceEntryResponse | undefined => {
    return props.holders.find((e) => e.CreatorPublicKeyBase58Check === assetPublicKeyBase58Check);
  };

  useEffectOnce(() => {
    Promise.all(['BTC', 'ETH', 'SOL'].map((e) => fetchWrappedAssetExchangeRate(e))).then(
      ([btcPrice, ethPrice, solPrice]) => {
        setWrappedAssetExchangeRate({
          BTC: btcPrice,
          ETH: ethPrice,
          SOL: solPrice,
        });
      },
    );
  });

  return (
    <Table>
      <TableHeader className="border-b border-border-light">
        <TableRow>
          <TableHead>
            <div className="inline-flex items-center gap-2">
              <span>Cross-Chain Assets</span>
              <div className="relative top-[2px]">
                <InfoTooltip
                  iconSize={16}
                  className="min-w-[300px] whitespace-normal break-words"
                  text={
                    <div className="max-w-[300px]">
                      Cross-chain assets like BTC on Openfund are actually represented as wrapped assets held fully in
                      your custody via your DeSo wallet. For example, BTC is actually DBTC, which is wrapped Bitcoin.
                      For more information on DBTC and other cross-chain assets, including their backing addresses, you
                      can visit their DeSo profiles.{' '}
                      <RouteLink
                        to={Routes.profile('BTC')}
                        kind="text-only-underline"
                        className="inline"
                        target="_blank"
                      >
                        This is the DBTC profile
                      </RouteLink>
                      , for example.
                    </div>
                  }
                />
              </div>
            </div>
          </TableHead>
          {columns.map((c, i) => {
            return (
              <TableHead
                key={i}
                className={`select-none last:text-right ${
                  i === columns.length - 1 ? 'text-left md:text-center' : 'text-right'
                }`}
              >
                {c}
              </TableHead>
            );
          })}
        </TableRow>
      </TableHeader>

      <TableBody>
        {wrappedAssets.map((e) => {
          const hodling = getHodlerAsset(e.publicKey);
          return (
            <TableRow key={e.displayName}>
              <TableCell>
                <div className="flex items-center">
                  <RouteLink kind="text-only-light" to={Routes.profile(e.displayName)} className="flex items-center">
                    <Avatar className="mr-2 unforce-radius" src={getWrappedAssetIcon(e)} />{' '}
                    <span className="text-muted-foreground">${e.displayName}</span>
                  </RouteLink>
                  {SHOW_NEW_BADGES && <NewBadge />}
                </div>
              </TableCell>
              <TableCell className="text-right font-mono text-muted-foreground">
                {hodling ? (
                  <LowNumFormatter isUsd={false} price={baseUnitsToTokens(hodling.BalanceNanosUint256.toString())} />
                ) : (
                  0
                )}
              </TableCell>
              <TableCell className="text-right font-mono text-muted-foreground">
                {wrappedAssetExchangeRate && wrappedAssetExchangeRate[e.displayName] ? (
                  <Text>{formatUSD(wrappedAssetExchangeRate[e.displayName])}</Text>
                ) : (
                  'N/A'
                )}
              </TableCell>
              <TableCell className="text-right font-mono text-muted-foreground">
                {wrappedAssetExchangeRate && wrappedAssetExchangeRate[e.displayName] ? (
                  <Text className="font-shadow-green text-green-500">
                    {formatUSD(
                      wrappedAssetExchangeRate[e.displayName] *
                        baseUnitsToTokens((hodling?.BalanceNanosUint256 || 0).toString()),
                    )}
                  </Text>
                ) : (
                  'N/A'
                )}
              </TableCell>
              <TableCell className="text-right flex items-center gap-2 justify-end ml-auto">
                <Button
                  variant="outline"
                  size="sm"
                  disabled={!hodling}
                  onClick={() => {
                    if (hodling) {
                      props.onSendTokens(hodling, 'SEND_TOKEN');
                    }
                  }}
                >
                  Send
                </Button>

                <Button
                  variant="outline"
                  size="sm"
                  disabled={!hodling?.BalanceNanos}
                  onClick={() => {
                    props.onCashOut(
                      e.heroswapName,
                      e.displayName,
                      quantityBigIntToFloat(BigInt(hodling?.BalanceNanosUint256.toString() || '0x0')),
                    );
                  }}
                >
                  Cash Out
                </Button>
                <Button variant="default" size="sm" onClick={() => props.onAddFunds(e.heroswapName, e.displayName)}>
                  Add {e.displayName}
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

function HodlingsTab(props: {
  currentUser: OpenfundUser | null;
  holders: BalanceEntryResponse[];
  userAssociations: UserAssociationsQuery | undefined;
  exchangeRates: GetExchangeRateUpdatedResponse | undefined;
  onSendTokens: (h: BalanceEntryResponse, action: WalletAction) => void;
  onBurnTokens: (h: BalanceEntryResponse, action: WalletAction) => void;
  onMoveToPurchased: (h: BalanceEntryResponse) => Promise<any>;
  onMoveToHidden: (h: BalanceEntryResponse) => Promise<any>;
  filterToPurchased: boolean;
}) {
  const columns = [
    TABLE_HEADINGS.TOKEN,
    TABLE_HEADINGS.MEMBERS,
    TABLE_HEADINGS.PRICE,
    TABLE_HEADINGS.TOKENS_IN_CIRCULATION,
    TABLE_HEADINGS.TOKENS_HELD,
    TABLE_HEADINGS.VALUE_OF_HOLDING,
    TABLE_HEADINGS.OWNERSHIP,
    TABLE_HEADINGS.ACTIONS,
  ];
  const [sortColumn, setSortColumn] = useState(TABLE_HEADINGS.VALUE_OF_HOLDING);
  const [isDescendingSort, setIsDescendingSort] = useState(true);
  const [movingTokenPublicKey, setMovingTokenPublicKey] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  const whitelistedProjectPublicKeys = (props.userAssociations?.userAssociations?.nodes || [])
    ?.map((e) => e?.target?.publicKey || '')
    .filter((e) => !!e);

  const purchasedProjects = new Set([
    ...((props.currentUser?.ProfileEntryResponse?.ExtraData || {})[ProjectPublicKeysPurchasedKey] || '').split(','),
    ...whitelistedProjectPublicKeys,
  ]);

  function getMembersCount(balanceEntry: BalanceEntryResponse): number {
    return balanceEntry.ProfileEntryResponse?.DAOCoinEntry?.NumberOfHolders ?? 0;
  }
  const holdings = props.holders
    .filter((holder) => {
      if (holder.CreatorPublicKeyBase58Check === holder.HODLerPublicKeyBase58Check) {
        return props.filterToPurchased;
      }
      return purchasedProjects.has(holder.CreatorPublicKeyBase58Check) === props.filterToPurchased;
    })
    .sort((a, b) => {
      let difference = (a.ProfileEntryResponse?.Username ?? '') > (b.ProfileEntryResponse?.Username ?? '') ? 1 : -1;
      if (sortColumn === TABLE_HEADINGS.MEMBERS) {
        difference = getMembersCount(a) - getMembersCount(b);
      } else if (sortColumn === TABLE_HEADINGS.PRICE) {
        difference =
          (a.ProfileEntryResponse as any)?.BestExchangeRateDESOPerDAOCoin -
          (b.ProfileEntryResponse as any)?.BestExchangeRateDESOPerDAOCoin;
      } else if (sortColumn === TABLE_HEADINGS.TOKENS_IN_CIRCULATION) {
        difference =
          baseUnitsToTokens(a.ProfileEntryResponse?.DAOCoinEntry?.CoinsInCirculationNanos.toString() ?? '0') -
          baseUnitsToTokens(b.ProfileEntryResponse?.DAOCoinEntry?.CoinsInCirculationNanos.toString() ?? '0');
      } else if (sortColumn === TABLE_HEADINGS.VALUE_OF_HOLDING) {
        difference =
          baseUnitsToTokens(a.BalanceNanosUint256.toString()) *
            (a.ProfileEntryResponse as any)?.BestExchangeRateDESOPerDAOCoin -
          baseUnitsToTokens(b.BalanceNanosUint256.toString()) *
            (b.ProfileEntryResponse as any)?.BestExchangeRateDESOPerDAOCoin;
      } else if (sortColumn === TABLE_HEADINGS.TOKENS_HELD || sortColumn === TABLE_HEADINGS.OWNERSHIP) {
        difference =
          baseUnitsToTokens(a.BalanceNanosUint256.toString()) - baseUnitsToTokens(b.BalanceNanosUint256.toString());
      }
      return isDescendingSort ? -difference : difference;
    });

  return (
    <Table>
      {holdings.length > 0 ? (
        <>
          <TableHeader className="border-b border-border-light">
            <TableRow>
              {columns.map((c, i) => {
                const className =
                  i === 0 ? 'cursor-pointer' : i === columns.length - 1 ? 'text-center' : 'cursor-pointer text-right';
                const onClick = () => {
                  if (i === columns.length - 1) {
                    return;
                  }
                  if (c === sortColumn) {
                    setIsDescendingSort(!isDescendingSort);
                    return;
                  }
                  setIsDescendingSort(true);
                  setSortColumn(c);
                };
                return (
                  <TableHead key={i} className={'last:text-right select-none ' + className}>
                    <button className="font-bold whitespace-nowrap" onClick={onClick}>
                      {sortColumn !== c ? (
                        <>&nbsp;&nbsp;</>
                      ) : isDescendingSort ? (
                        <span className="inline-block top-0.5 relative">
                          <IoArrowDownOutline />
                        </span>
                      ) : (
                        <span className="inline-block top-0.5 relative">
                          <IoArrowUpOutline />
                        </span>
                      )}
                      &nbsp;{c}
                    </button>
                  </TableHead>
                );
              })}
            </TableRow>
          </TableHeader>
          <TableBody>
            {holdings.map((h, i) => (
              <TableRow key={i} className="border-t border-border-light">
                <TableCell>
                  <RouteLink
                    kind="text-only-light"
                    to={Routes.fund(h.ProfileEntryResponse?.Username!)}
                    className="flex items-center"
                  >
                    <Avatar className="mr-2 unforce-radius" src={deso.profilePicUrl(h.CreatorPublicKeyBase58Check)} />{' '}
                    <span className="text-muted-foreground">${h.ProfileEntryResponse?.Username!}</span>
                  </RouteLink>
                </TableCell>
                <TableCell className="text-right font-mono">{formatDecimalValue(getMembersCount(h), 0, 0)}</TableCell>
                <TableCell className="text-right font-mono text-muted-foreground">
                  {!props.exchangeRates ? (
                    'N/A'
                  ) : (
                    <USDValueWithDESO
                      desoValue={(h.ProfileEntryResponse as any)?.BestExchangeRateDESOPerDAOCoin}
                      usdCentsPerDeSoExchangeRate={props.exchangeRates.USDCentsPerDeSoCoinbase}
                      align="right"
                    />
                  )}
                </TableCell>
                <TableCell className="text-right font-mono text-muted-foreground">
                  {formatTokenBaseUnits(h.ProfileEntryResponse?.DAOCoinEntry?.CoinsInCirculationNanos.toString() ?? 0)}
                </TableCell>
                <TableCell className="text-right font-mono text-muted-foreground">
                  {formatTokenBaseUnits(h.BalanceNanosUint256.toString())}
                </TableCell>
                <TableCell className="text-right font-mono text-muted-foreground">
                  {!props.exchangeRates ? (
                    'N/A'
                  ) : (
                    <USDValueWithDESO
                      desoValue={
                        baseUnitsToTokens(h.BalanceNanosUint256.toString()) *
                        (h.ProfileEntryResponse as any)?.BestExchangeRateDESOPerDAOCoin
                      }
                      usdCentsPerDeSoExchangeRate={props.exchangeRates.USDCentsPerDeSoCoinbase}
                      align="right"
                    />
                  )}
                </TableCell>
                <TableCell className="text-right font-mono text-muted-foreground">
                  {formatDecimalValue(
                    (Number(h.BalanceNanosUint256) /
                      Number(h.ProfileEntryResponse?.DAOCoinEntry?.CoinsInCirculationNanos)) *
                      100,
                    2,
                    2,
                  )}
                  %
                </TableCell>
                <TableCell className="text-right">
                  <div className="flex items-center gap-2 justify-end">
                    <Button
                      variant="link"
                      size="sm"
                      className="lg:ml-4"
                      disabled={movingTokenPublicKey === h.ProfileEntryResponse?.PublicKeyBase58Check}
                      onClick={async () => {
                        setMovingTokenPublicKey(h.ProfileEntryResponse?.PublicKeyBase58Check || '');

                        try {
                          await (props.filterToPurchased ? props.onMoveToHidden(h) : props.onMoveToPurchased(h));
                          toast.toast({
                            description: `${h.ProfileEntryResponse?.Username} tokens will now appear on your ${props.filterToPurchased ? 'Hidden' : 'My Tokens'} tab.`,
                            variant: 'success',
                          });
                        } catch (e) {
                          toast.toast({ description: getErrorMsg(e), variant: 'error' });
                        } finally {
                          setMovingTokenPublicKey('');
                        }
                      }}
                    >
                      <div className="w-5 flex items-center">
                        {movingTokenPublicKey === h.ProfileEntryResponse?.PublicKeyBase58Check && <Spinner size={20} />}
                      </div>
                      <span className="whitespace-nowrap text-blue hover:text-blue hover:underline">
                        {props.filterToPurchased ? 'Move to Hidden' : 'Move to My Tokens'}
                      </span>
                    </Button>
                    <div>
                      <Button
                        variant="link"
                        size="sm"
                        className="text-muted hover:text-red-600 flex items-center"
                        onClick={() => {
                          console.log(h);
                          props.onBurnTokens(h, 'BURN_TOKEN');
                        }}
                      >
                        <IoFlame className="relative inline text-red -top-[1px] mr-1" />
                        <span className="text-red hover:text-red hover:underline">Burn</span>
                      </Button>
                    </div>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        props.onSendTokens(h, 'SEND_TOKEN');
                      }}
                    >
                      Send
                    </Button>
                    <Button
                      size="sm"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(Routes.tradeToken(h.ProfileEntryResponse?.Username!));
                      }}
                    >
                      Trade
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </>
      ) : (
        <div className="text-center p-8">
          <div className="mb-4 font-semibold text-muted-foreground text-lg">
            You haven't {props.filterToPurchased ? 'purchased' : 'received'} any tokens yet.
          </div>
          <div className="text-muted">
            Visit the{' '}
            <RouteLink kind="text-only-underline-light" to={Routes.trade()} className="underline underline-offset-4">
              Trade page
            </RouteLink>{' '}
            to find projects
          </div>
        </div>
      )}
    </Table>
  );
}

function MyHodlersTab(props: {
  currentUser: OpenfundUser | null;
  holders: BalanceEntryResponse[];
  exchangeRates: GetExchangeRateUpdatedResponse | undefined;
}) {
  const columns = [TABLE_HEADINGS.HODLER, TABLE_HEADINGS.TOKENS_HELD, TABLE_HEADINGS.OWNERSHIP];
  const [sortColumn, setSortColumn] = useState(TABLE_HEADINGS.HODLER);
  const [isDescendingSort, setIsDescendingSort] = useState(true);

  const holders = props.holders
    .map((h) => {
      return {
        Wealth: desoNanosToUSD(getWealthFromBalanceEntry(h, true), props.exchangeRates?.USDCentsPerDeSoCoinbase ?? 0),
        DAOBaseUnits: baseUnitsToTokens(h.BalanceNanosUint256.toString()),
        ...h,
      };
    })
    .sort((a, b) => {
      let difference = a.Wealth - b.Wealth;
      if (sortColumn === TABLE_HEADINGS.TOKENS_HELD || sortColumn === TABLE_HEADINGS.OWNERSHIP) {
        difference = a.DAOBaseUnits - b.DAOBaseUnits;
      }
      return isDescendingSort ? -difference : difference;
    });

  return (
    <TableScrollable containerClassname="lg:overflow-x-auto overflow-x-scroll">
      {holders.length > 0 ? (
        <>
          <TableHeader>
            <TableRow>
              {columns.map((c, i) => {
                return (
                  <TableHead key={i} className={'select-none' + (i > 0 ? ' text-right' : '')}>
                    <button
                      className="whitespace-nowrap font-bold"
                      onClick={() => {
                        if (c === sortColumn) {
                          setIsDescendingSort(!isDescendingSort);
                          return;
                        }
                        setIsDescendingSort(true);
                        setSortColumn(c);
                      }}
                    >
                      {sortColumn !== c ? (
                        <>&nbsp;&nbsp;</>
                      ) : isDescendingSort ? (
                        <span className="inline-block top-0.5 relative">
                          <IoArrowDownOutline />
                        </span>
                      ) : (
                        <span className="inline-block top-0.5 relative">
                          <IoArrowUpOutline />
                        </span>
                      )}
                      &nbsp;{c}
                    </button>
                  </TableHead>
                );
              })}
            </TableRow>
          </TableHeader>
          <TableBody>
            {holders.map((h) => (
              <TableRow key={h.HODLerPublicKeyBase58Check} className="border-t">
                <TableCell>
                  {h.ProfileEntryResponse ? (
                    <RouteLink
                      kind="text-only-light"
                      to={Routes.fund(h.ProfileEntryResponse.Username)}
                      className="flex items-center"
                    >
                      <Avatar className="mr-2" src={deso.profilePicUrl(h.HODLerPublicKeyBase58Check)} />{' '}
                      <div>
                        <div>
                          <span className="font-bold">@{h.ProfileEntryResponse.Username}</span>
                        </div>
                        <WealthBadge wealthUSD={h.Wealth} />
                      </div>
                    </RouteLink>
                  ) : (
                    <div className="flex items-center">
                      <Avatar className="mr-2" />{' '}
                      <span className="font-bold">{centerEllipsis(h.HODLerPublicKeyBase58Check, 8)}</span>
                    </div>
                  )}
                </TableCell>
                <TableCell className="text-right">{formatTokenBaseUnits(h.BalanceNanosUint256.toString())}</TableCell>
                <TableCell className="text-right">
                  {formatDecimalValue(
                    calcOwnershipPercent(
                      h.BalanceNanosUint256.toString(),
                      props.currentUser?.ProfileEntryResponse?.DAOCoinEntry?.CoinsInCirculationNanos.toString(),
                    ),
                    2,
                    2,
                  )}
                  %
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </>
      ) : (
        <div className="text-center p-8">
          <Text size="lg" className="mb-4">
            No one is holding your token yet
          </Text>
          <Text>
            Make sure you've <RouteLink to={Routes.settingsFundraising()}>opened a funding round.</RouteLink> And tell
            people about it on{' '}
            <RouteLink kind="text-only-underline-light" to={Routes.activity()} className="underline">
              Activity page!
            </RouteLink>
          </Text>
        </div>
      )}
    </TableScrollable>
  );
}

export default MyWallet;
