export const APP_NAME_GENERIC = 'Openfund';
export const DESO_APP_NAME = 'DeSo';
export const DESO_TOKEN_NAME = `${DESO_APP_NAME} Token`;
export const FOCUS_TOKEN_DISPLAY_NAME = 'FOCUS';
export const FOCUS_TOKEN_USERNAME = 'Focus';

export enum THEME_OPTIONS {
  lightMode = 'light',
  darkMode = 'dark',
  paperLightMode = 'paper-light',
  paperDarkMode = 'paper-dark',
  modernLightMode = 'modern-light',
  modernDarkMode = 'modern-dark',
  radarLightMode = 'radar-light',
  radarDarkMode = 'radar-dark',
}

export const LOCAL_STORAGE_KEYS = {
  theme: 'openfund-app/theme/v1',
};

export const FOCUS_TOKEN_SUPPLY = 44_663_577_756;

export const ONE_TRILLION = 1_000_000_000_000;
