import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { CopyToClipboard } from '../components/app-ui/CopyToClipboard';
import { useTheme } from '../contexts/ThemeContext';
import { HTMLAttributes } from 'react';
// @ts-expect-error No types provided
import { Prism } from 'react-syntax-highlighter';
// @ts-expect-error No types provided
import darcula from 'react-syntax-highlighter/dist/esm/styles/prism/darcula';
// @ts-expect-error No types provided
import oneLight from 'react-syntax-highlighter/dist/esm/styles/prism/one-light';

const prismDark = structuredClone(darcula);
const prismLight = structuredClone(oneLight);

const markdownComponents = {
  a: ({ href, children }: React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
    const classList =
      'inline font-normal text-primary no-underline hover:text-primary hover:underline underline-offset-4';
    const internalLink = href?.startsWith('/');
    const finalHref = internalLink ? href?.replace('/', '/profile/') : href;

    if (internalLink && typeof children === 'string' && (children?.startsWith('@') || children?.startsWith('$'))) {
      const username = finalHref?.slice(1);

      if (username && finalHref) {
        return (
          <a href={finalHref} className={classList}>
            {children}
          </a>
        );
      }
    }

    return finalHref ? (
      <a
        href={finalHref}
        className={classList}
        target={internalLink ? undefined : '_blank'}
        rel={internalLink ? undefined : 'nofollow noopener noreferrer'}
      >
        {children}
      </a>
    ) : (
      <span className={classList}>{children}</span>
    );
  },
  img: ({ src }: React.ImgHTMLAttributes<HTMLImageElement>) => {
    return src ? (
      <div className="inline-image inline-flex align-middle">
        <img className="mx-auto mb-4 w-24 h-24 rounded-full shadow-lg" alt="error-image" src={src} />
      </div>
    ) : null;
  },
  p: ({ children }: HTMLAttributes<HTMLParagraphElement>) => (
    <div className="w-full [&:has(code.inline)]:pt-1" style={{ wordBreak: 'break-word' }}>
      {children}
    </div>
  ),
  // @ts-expect-error wrong 3rd party types
  pre: ({ children, node }: HTMLAttributes<HTMLElement>) => {
    node.children[0].properties.block = true;
    return (
      <div
        suppressHydrationWarning
        className="markdown-prism custom-scrollbar-all custom-scrollbar-hover-only max-h-[600px] w-full overflow-auto rounded-2xl border border-border-light bg-card text-foreground"
      >
        {children}
      </div>
    );
  },
  code(
    props: HTMLAttributes<HTMLElement> & {
      // eslint-disable-next-line @typescript-eslint/consistent-type-imports
      node?: import('hast').Element;
    },
  ) {
    const { children, className, node, ...rest } = props;

    if (!node?.properties?.block) {
      return (
        <code className="inline border border-border-light bg-card box-decoration-clone px-1 py-px font-mono text-sm font-normal !text-muted-foreground">
          {children}
        </code>
      );
    }

    const match = /language-(\w+)/.exec(className || '');
    const title = match?.[1] || 'Code';
    const text = String(children).replace(/\n$/, '');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { theme } = useTheme();
    const styles = theme?.includes('dark') ? prismDark : prismLight;

    return (
      <>
        <section className="sticky left-0 top-0 flex w-full items-center p-3 backdrop-blur">
          <div className="font-bold uppercase text-foreground">{title}</div>
          <div className="!ml-auto">
            <CopyToClipboard text={text}>Copy</CopyToClipboard>
          </div>
        </section>
        <Prism
          {...rest}
          PreTag="div"
          language={match?.[1]}
          style={styles}
          //showLineNumbers
        >
          {text}
        </Prism>
      </>
    );
  },
};

const USERNAME_REGEX = /(?<=^|\s)[@$][\w,\\]+/g;
const DOLLAR_VALUE_REGEX = /^\$\d+([,.]\d+)?([kKmMbB])?$/;
const HASHTAG_REGEX = /\B(#[a-zA-Z0-9]+\b)(?!;)/g;

export const processRawPostContentToMDContent = (content: string) => {
  return (
    content
      // Handle usernames
      .replace(USERNAME_REGEX, (match) => {
        // If it's a dollar value, skip it
        // We could have used a single reqexp to match both, but it
        // would require negative lookahead, which is slow on large bodies.
        if (DOLLAR_VALUE_REGEX.test(match)) {
          return match;
        }

        return `[${match}](/${match.slice(1)})`;
      })
      // Handle hashtags
      .replace(HASHTAG_REGEX, (match) => `[${match}](/search?search=${encodeURIComponent(match)})`)
      // Add extra line breaks for images to overcome of some users
      // not using 2 spaces before an image
      .replaceAll(/^!\[([^\]]*)]\(([^)]+)\)$/gm, (match) => {
        return `\n\n${match}\n\n`;
      })
      .trim()
      // Remove hash escapes
      .replaceAll('\\[#', '[#')
      // Replace shift enter with normal newlines
      .replaceAll('\\\n', '\n')
      // Only allow maximum 2 sequential newlines
      .replaceAll(/\n{3,}/g, '\n\n')
  );
};

export const getMarkdownBody = (body: string) => {
  return (
    <Markdown remarkPlugins={[remarkGfm]} components={markdownComponents}>
      {processRawPostContentToMDContent(body)}
    </Markdown>
  );
};
