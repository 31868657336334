import { Combobox } from '@headlessui/react';
import { IoSearch } from 'react-icons/io5';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { classNames } from 'utils/classNames';
import { debounce } from 'utils/debounce';
import { Avatar } from '../core/Avatar';
import { DESO_WRAPPED_TOKENS, SHOW_NEW_BADGES } from '../../constants/TradeConstants';
import { getWrappedAssetIcon, isWrappedAsset } from '../../utils/deso';
import NewBadge from 'components/core/NewBadge';
import { MdOutlineVerified } from 'react-icons/md';

export interface SearchMenuItem {
  id: string;
  inputDisplayValue: string;
  menuItemContent: ReactNode;
}
interface SearchInputProps {
  placeholder?: string;
  size?: 'sm' | 'md';
  className?: string;
  hasPersistentDisplayValue?: boolean;
  nullable?: boolean;
  initialValue?: string;
  onQuery: (query: string) => Promise<SearchMenuItem[] | undefined>;
  onItemSelected: (item: SearchMenuItem | null) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}
export function SearchInput({
  placeholder = 'Search',
  size = 'md',
  className,
  hasPersistentDisplayValue = false,
  nullable = undefined,
  initialValue = '',
  onQuery,
  onItemSelected,
  onFocus,
  onBlur,
}: SearchInputProps) {
  const [menuItems, setMenuItems] = useState<SearchMenuItem[]>();
  const [inputValue, setInputValue] = useState(initialValue);

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  return (
    <div className={classNames('relative', className)}>
      <Combobox
        // Using as `any` to avoid issue with internal combobox typings: https://github.com/tailwindlabs/headlessui/issues/2438
        nullable={nullable as any}
        value={inputValue}
        onChange={(value: string | null) => {
          if (!value) {
            setInputValue('');
            onItemSelected(null);
            return;
          }

          const menuItem = menuItems?.find(({ id }) => id === value);
          if (!menuItem) {
            return;
          }

          if (hasPersistentDisplayValue) {
            setInputValue(menuItem.inputDisplayValue);
          }
          onItemSelected(menuItem);
        }}
      >
        <div className="relative">
          <IoSearch
            className={classNames(
              'absolute dark:text-gray-light',
              size === 'md' && 'h-6 w-6 top-4 left-3',
              size === 'sm' && 'h-5 w-5 top-2.5 left-3',
            )}
          />
          <Combobox.Input
            placeholder={placeholder}
            spellCheck={false}
            className={classNames(
              'border-0 bg-input rounded-full w-full',
              size === 'md' && 'pl-10 py-4 pr-4 text-md',
              size === 'sm' && 'pl-10',
            )}
            onChange={async (ev) => {
              (function (name) {
                debounce(async () => {
                  if (!name) {
                    setInputValue('');
                    setMenuItems([]);
                    return;
                  }
                  const queryResults = (await onQuery(name)) || [];

                  // Filter out all the wrapped assets returned from the API, we will include them manually below
                  // We match both names like 'BTC' and 'dBTC_'
                  let results = queryResults.filter((e) => {
                    return (
                      !isWrappedAsset(e.inputDisplayValue, 'name') &&
                      !isWrappedAsset(e.inputDisplayValue, 'displayName')
                    );
                  });

                  // Check if the input text matches any of our wrapped assets
                  const matchedWrappedAssets = DESO_WRAPPED_TOKENS.filter((wrappedAsset) =>
                    wrappedAsset.displayName.toLowerCase().includes(name),
                  );

                  if (matchedWrappedAssets.length) {
                    // Manually include wrapped assets into the response if they are matched
                    // They will have higher rank and will be displayed at the very top
                    results = [
                      ...matchedWrappedAssets.map((e) => ({
                        id: e.publicKey,
                        inputDisplayValue: e.displayName,
                        menuItemContent: (
                          <div className="flex items-center cursor-pointer">
                            <Avatar className="bg-gray-faint" size="sm" src={getWrappedAssetIcon(e)} />
                            <span className="ml-4">{e.displayName}</span>
                            <MdOutlineVerified className="ml-1" />
                            {SHOW_NEW_BADGES && <NewBadge hideTooltip={true} />}
                          </div>
                        ),
                      })),
                      ...(results || []),
                    ];
                  }

                  if (!results || results.length === 0) {
                    results = [
                      {
                        id: '',
                        inputDisplayValue: '',
                        menuItemContent: <div className="text-sm">No results found.</div>,
                      },
                    ];
                  }
                  setMenuItems(results);
                }, 500);
              })(ev.currentTarget.value.trim());
            }}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>
        <Combobox.Options
          className={classNames(
            !menuItems?.length ? 'hidden' : '',
            'absolute z-10 w-full bg-white text-black dark:bg-gray-333 dark:text-gray-faint max-h-80 mt-1 rounded-md overflow-y-scroll openfund-scrollbar',
          )}
        >
          {menuItems?.map(({ id, menuItemContent }) => (
            <Combobox.Option key={id} value={id} as={Fragment}>
              {({ active }) => (
                <li className={classNames(active && id ? 'bg-gray-faint dark:bg-blue-deep' : '', 'p-2')}>
                  {menuItemContent}
                </li>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox>
    </div>
  );
}
