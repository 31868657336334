import type { ColumnDef, PaginationState, SortingState } from '@tanstack/react-table';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { Dispatch, SetStateAction, useContext } from 'react';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../shadcn/ui/table';
import { cn } from '../../utils/shadcn';
import { OpenfundContext } from '../../contexts/OpenfundContext';
import { QuoteCurrencyContext } from '../../contexts/QuoteCurrencyContext';
import { FOCUS_TOKEN_PUBLIC_KEY } from '../../constants/AppConstants';

interface GenericTableProps<T> {
  loading: boolean;
  columns: Array<ColumnDef<T>>;
  data: Array<T>;
  columnSorting: SortingState;
  setColumnSorting: Dispatch<SetStateAction<SortingState>>;
  pagination: PaginationState;
  pageOffset: number;
  columnsWidth: Array<number>;
  wrapperClassName?: string;
  refetchData?: () => void;
  emptyTableClassName?: string;
}

interface TableLoaderProps {
  className?: string;
}

const TableLoader = ({ className = '' }: TableLoaderProps) => {
  return (
    <div className={cn('absolute top-[-3px] h-0.5 w-full overflow-hidden bg-primary', className)}>
      <div className="absolute inset-0 h-full animate-[table-loader_1.5s_infinite_linear] bg-accent"></div>
    </div>
  );
};

const GenericTable = <T,>({
  data,
  columns,
  loading,
  columnSorting,
  setColumnSorting,
  pagination,
  pageOffset,
  columnsWidth,
  wrapperClassName = '',
  refetchData,
  emptyTableClassName = '',
}: GenericTableProps<T>) => {
  const { currentUser } = useContext(OpenfundContext);
  const { exchangeRates, quoteCurrencyPublicKey } = useContext(QuoteCurrencyContext);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setColumnSorting,
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualSorting: true,
    state: {
      sorting: columnSorting,
      pagination,
    },
  });

  return (
    <div className="w-full rounded-md border text-sm">
      <Table className={cn('min-w-[800px]', wrapperClassName)}>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              key={headerGroup.id}
              className={cn('flex w-full border-b rounded-tr-md', loading && 'pointer-events-none opacity-50')}
            >
              {headerGroup.headers.map((header, idx) => {
                return (
                  <TableHead
                    className={cn(
                      'flex h-11 items-center rounded-tr-md border-b px-2 text-left text-xs font-medium text-muted-foreground',
                      header.id === 'actions' ? 'sticky right-0 z-1 bg-background' : 'relative z-0',
                    )}
                    style={{ width: `${columnsWidth[idx]}%` }}
                    key={header.id}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>

        <TableBody className="block h-[500px] md:h-auto md:max-h-[1000px] overflow-auto openfund-scrollbar">
          <TableRow className="relative h-0 border-none">
            <TableCell colSpan={table.getHeaderGroups().length} className="p-0">
              {loading && <TableLoader />}
            </TableCell>
          </TableRow>

          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} className="flex border-b" data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell, idx) => {
                  return (
                    <TableCell
                      key={cell.id}
                      style={{ width: `${columnsWidth[idx]}%` }}
                      className={cn(
                        cell.column.id === 'actions' ? 'sticky right-0 z-1 bg-card bg-opacity-50 p-0' : 'relative z-0',
                        cell.column.id === 'circulatingSupply' && 'w-[300px]',
                      )}
                    >
                      <div className="flex size-full items-center w-fit">
                        {flexRender(cell.column.columnDef.cell, {
                          ...cell.getContext(),
                          focusExchangeRate: exchangeRates[FOCUS_TOKEN_PUBLIC_KEY],
                          quoteCurrencyPublicKey,
                          publicKey: currentUser?.PublicKeyBase58Check,
                          pageOffset,
                          refetchData,
                        })}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className={cn('h-24 text-center', emptyTableClassName)}>
                No items found.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default GenericTable;
